export const signUpFormFields = {
  type: 'type',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password',
  passwordRepeat: 'passwordRepeat',
  countryId: 'countryId',
  businessName: 'businessName',
  businessType: 'businessType',
  businessUrl: 'businessUrl',
  showProducts: 'showProducts',
  terms: 'terms',
  description: 'description',
  languageId: 'languageId',
  zipCode: 'zipCode',
  yearStarted: 'yearStarted',
  displayName: 'displayName',
};

export const accountTypes = {
  personal: 'personal',
  business: 'business',
};

export const businessTypeKeys = {
  designer: 'designer',
  retailer: 'retailer',
};

export const showProductsOptions = {
  all: 'all',
};

export const errorMessages = {
  required: 'This field is required',
  passwordInvalid: 'Password should be at least 8 characters',
  passwordWithoutNumber: 'Password must include a number',
  passwordsShouldMatch: 'Passwords should match',
  emailsShouldMatch: 'Emails should match',
  emailInvalid: 'Email must be a valid email address',
};

export const passwordInfoConfig = {
  title: 'Password Guidelines',
  rules: [
    {
      id: 1,
      rule: 'Use 8 characters or more',
    },
    {
      id: 2,
      rule: 'Include a number',
    },
    {
      id: 3,
      rule: 'Do not use your name or email',
    },
  ],
};

export const businessTypeOptions = [
  'Designer',
  'Architect',
  'Retailer',
  'Manufacturer',
  'Artisan',
  'Other',
];

export default {
  signUpFormFields,
  accountTypes,
  showProductsOptions,
  errorMessages,
  passwordInfoConfig,
};
