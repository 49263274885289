export const SHOWCASE_UPDATE_CURRENT = 'SHOWCASE_UPDATE_CURRENT';
export const SHOWCASE_UPDATE_OWN_COLOR_BANNERS =
  'SHOWCASE_UPDATE_OWN_COLOR_BANNERS';
export const SHOWCASE_UPDATE_OWN_IMAGE_BANNERS =
  'SHOWCASE_UPDATE_OWN_IMAGE_BANNERS';
export const SHOWCASE_SET_PREVIEW_URL_KEY = 'SHOWCASE_SET_PREVIEW_URL_KEY';
export const SHOWCASE_UPDATE_LOOKBOARDS_LIBRARY =
  'SHOWCASE_UPDATE_LOOKBOARDS_LIBRARY';
export const SHOWCASE_UPDATE_IMAGES_DND_STATE =
  'SHOWCASE_UPDATE_IMAGES_DND_STATE';
export const SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE =
  'SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE';
