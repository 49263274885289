import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './IconButton.module.scss';

const sizeToClass = {
  xs: classes.xs,
  sm: classes.sm,
  md: classes.md,
  lg: classes.lg,
};

const colors = {
  primary: 'primary',
  secondary: 'secondary',
  grey: 'grey',
};

export const variants = {
  default: 'default',
  invertedWhite: 'inverted-white',
  invertedGrey: 'inverted-grey',
  transparent: 'transparent',
};

const IconButton = ({
  className,
  component: Component,
  color,
  children,
  variant,
  size,
  ...rest
}) => (
  <Component
    className={clsx(classes.root, className, sizeToClass[size], {
      [classes.primary]: color === colors.primary,
      [classes.secondary]: color === colors.secondary,
      [classes.grey]: color === colors.grey,
      [classes.invertedWhite]: variant === variants.invertedWhite,
      [classes.invertedGrey]: variant === variants.invertedGrey,
      [classes.transparent]: variant === variants.transparent,
    })}
    {...rest}
  >
    {children}
  </Component>
);

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.values(variants)),
  color: PropTypes.oneOf(Object.values(colors)),
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  size: PropTypes.oneOf(Object.keys(sizeToClass)),
};

IconButton.defaultProps = {
  className: '',
  component: 'button',
  color: colors.primary,
  variant: variants.default,
  size: 'md',
};

export default IconButton;
