import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const lookBoardService = {
  getLookBoards(params, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/search`,
      params,
      config
    );
  },

  getLookBoardById(lookBoardId) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/look-board/${lookBoardId}`
    );
  },

  shareLookBoard(lookBoardId) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/look-board/share`, {
      lookBoardId,
    });
  },

  createLookBoard(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/look-board`, data);
  },

  updateLookBoardWithoutRequest(id, data) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/look-board/${id}`, data);
  },

  updateLookBoardColor(id, data) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/${id}/update-banner-color`,
      data
    );
  },
  updateSubmittedLookBoard(id, data) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/${id}/update-request`,
      data
    );
  },

  submitLookBoard(lookBoardId, data) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/${lookBoardId}/submit`,
      data
    );
  },

  deleteLookBoard(id) {
    return AuthorizedAxiosInstance.delete(`${urlPrefix}/look-board/${id}`);
  },

  toggleLike(lookBoardId, isLiked) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/${lookBoardId}/like`,
      { isLiked }
    );
  },

  voteHandler(lookBoardId, vote) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/${lookBoardId}/vote`,
      { vote }
    );
  },

  rate(lookBoardId, rate) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/${lookBoardId}/rate`,
      { rate }
    );
  },

  getLookBoardExamples() {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/look-board/examples`);
  },

  getLookBoardsById(lookBoards) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/look-board/find-ids`, {
      lookBoards,
    }).then(({ result }) => result);
  },
};

export default lookBoardService;
