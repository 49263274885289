import {
  PRODUCT_REMOVE_INSTANTLY_CREATED,
  PRODUCT_UPDATE_INSTANTLY_CREATED,
  PRODUCT_UPDATE_LIBRARY,
} from 'modules/product/store/constants';

const initialState = {
  library: {},
  createdInstantlyProductIds: [],
};

const productsReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case PRODUCT_UPDATE_LIBRARY: {
      return {
        ...state,
        library: {
          ...state.library,
          ...payload,
        },
      };
    }
    case PRODUCT_UPDATE_INSTANTLY_CREATED: {
      return {
        ...state,
        createdInstantlyProductIds: [
          payload,
          ...state.createdInstantlyProductIds,
        ],
      };
    }
    case PRODUCT_REMOVE_INSTANTLY_CREATED: {
      return {
        ...state,
        createdInstantlyProductIds: [],
      };
    }
    default:
      return state;
  }
};

export default productsReducer;
