import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import SpriteIcon from 'components/ui/SpriteIcon';
import { resetPasswordFormFields } from 'modules/auth/forgotPassword/resetPassword/constants';
import authService from 'modules/auth/authService';
import resetPasswordValidator from 'modules/auth/forgotPassword/resetPassword/resetPasswordValidator';
import PasswordInputWrapper from 'components/finalFormWrappers/PasswordInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import Button from 'components/ui/Button/Button';
import errorToastr from 'libs/toastr/errorToastr';
import classes from 'modules/auth/forgotPassword/ForgotPassword.module.scss';

const ResetPasswordModal = ({ token, email, open, onClose }) => {
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);

  const modalTitle = useMemo(() => {
    return submitted ? (
      <span>
        Password successfully <br /> changed
      </span>
    ) : (
      'Enter a new password'
    );
  }, [submitted]);

  const resetPassword = useCallback(
    async (formValues) => {
      if (!token || !email) {
        errorToastr('Error', "Required data doesn't provided");
        return;
      }
      try {
        await authService.resetPassword(token, { email, ...formValues });
        setSubmitted(true);
      } catch (e) {
        errorToastr('Error', e.message);
        // eslint-disable-next-line consistent-return
        return { ...e.validationErrors };
      }
      // eslint-disable-next-line consistent-return
      return true;
    },
    [email, token]
  );

  const goToSignIn = useCallback(() => {
    onClose();
    history.push(
      `${routesByName.home}?${routesByName.auth.key}=${routesByName.auth.signIn}`
    );
  }, [onClose, history]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      classes={{ root: classes.root }}
      fullWidth
      maxWidth="sm"
    >
      <h3 className={classes.title}>{modalTitle}</h3>
      {submitted ? (
        <div className="text-center">
          <Button inline onClick={goToSignIn}>
            Go to Sign In
          </Button>
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center">
            <div className={classes.infoIcon}>
              <SpriteIcon name="info" size="sm" />
            </div>
            <span className="text-sm">Password Info</span>
          </div>
          <ul className={`${classes.rulesList} mb-3`}>
            <li>- Password must not match with the name or email</li>
            <li>
              - Password should contain letters and numbers. Example: Password65{' '}
            </li>
            <li>- Minimum length of the password (the default value is 8).</li>
          </ul>
          <Form
            validate={resetPasswordValidator}
            onSubmit={resetPassword}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="px-4">
                  <Field
                    required
                    label="Enter New Password"
                    type="password"
                    name={resetPasswordFormFields.password}
                    component={PasswordInputWrapper}
                  />
                  <Field
                    required
                    label="Re-Enter Password"
                    type="password"
                    name={resetPasswordFormFields.confirmPassword}
                    component={PasswordInputWrapper}
                  />
                </div>
                <div className="text-center">
                  <FinalFormSubmitButton inline>Submit</FinalFormSubmitButton>
                </div>
              </form>
            )}
          />
        </>
      )}
    </BasicModal>
  );
};

ResetPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  token: PropTypes.string,
  email: PropTypes.string,
};

ResetPasswordModal.defaultProps = {
  token: null,
  email: null,
};

export default ResetPasswordModal;
