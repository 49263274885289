import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'components/modals/BasicModal/BasicModal.module.scss';

const BasicModal = ({
  component: Component,
  classes: modalClasses,
  open,
  onClose,
  closeBtn,
  children,
  ...rest
}) => (
  <Component
    open={open}
    onClose={onClose}
    classes={{
      paper: clsx(classes.root, modalClasses.root),
      container: modalClasses.container,
    }}
    {...rest}
  >
    {closeBtn && (
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={clsx(classes.closeBtn, modalClasses.closeBtn)}
      >
        <SpriteIcon name="cross" size="sm" />
      </IconButton>
    )}
    {children}
  </Component>
);

BasicModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  closeBtn: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    closeBtn: PropTypes.string,
    container: PropTypes.string,
  }),
};

BasicModal.defaultProps = {
  component: Dialog,
  closeBtn: true,
  classes: {},
};

export default BasicModal;
