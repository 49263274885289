import {
  REPORT_SET_CLAIM_IMAGE_ID,
  REPORT_SET_REASON,
  REPORT_SET_SUB_REASON,
  REPORT_TOGGLE_CLAIM_IMAGE_MODAL,
} from 'modules/report/store/constants';

export const toggleClaimImageModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: REPORT_TOGGLE_CLAIM_IMAGE_MODAL, payload: isOpen });
};

export const setClaimImageIdAction = (id) => (dispatch) => {
  dispatch({ type: REPORT_SET_CLAIM_IMAGE_ID, payload: id });
};

export const setClaimReasonAction = (reason) => (dispatch) => {
  dispatch({ type: REPORT_SET_REASON, payload: reason });
};

export const setClaimSubReasonAction = (subReason) => (dispatch) => {
  dispatch({ type: REPORT_SET_SUB_REASON, payload: subReason });
};
