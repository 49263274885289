import React, { useMemo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { routesByName } from 'constants/routes';

const AmbassadorRoute = ({ children, ...rest }) => {
  const user = useSelector((state) => state.auth.user);
  const isAmbassador = useMemo(() => Boolean(user?.ambassador), [user]);
  const authenticated = useMemo(() => Boolean(user), [user]);

  const requestId = rest.computedMatch?.params.requestId;

  return (
    <Route {...rest}>
      {isAmbassador ? (
        children
      ) : (
        <>
          {authenticated ? (
            <Redirect to={routesByName.home} />
          ) : (
            <Redirect
              to={`${routesByName.home}?ambassador-match=true&requestId=${requestId}`}
            />
          )}
        </>
      )}
    </Route>
  );
};

AmbassadorRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AmbassadorRoute;
