import {
  CTL_INSERT_PRODUCT_TO_TOP,
  CTL_RESET_CANVAS,
  CTL_RESET_IMAGE_FILTER_VALUES,
  CTL_RESET_PRODUCT_FILTER_VALUES,
  CTL_RESET_STATE,
  CTL_SET_EDIT_STATE,
  CTL_SET_FORM_VALUES,
  CTL_SET_IMAGES,
  CTL_SET_LOOK_BOARD_STATUS,
  CTL_SET_PRODUCTS,
  CTL_SET_SELECTED_TEMPLATE,
  CTL_SET_STEP,
  CTL_UPDATE_DND_STATE,
  CTL_UPDATE_II_SEARCH_PARAMS,
  CTL_UPDATE_IMAGE_FILTER_VALUES,
  CTL_UPDATE_IMAGES,
  CTL_UPDATE_LOOK_BOARD_DATA,
  CTL_UPDATE_PRODUCT_FILTER_VALUES,
  CTL_UPDATE_PRODUCT_SEARCH_PARAMS,
  CTL_UPDATE_PRODUCTS,
  CTL_UPDATE_STATE,
} from 'modules/curateTheLook/store/constants';
import {
  createLookBoardStepKeys,
  lookBoardStatusKeys,
} from 'modules/curateTheLook/constants';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { productTypeKeys } from 'modules/product/constants';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import { PRODUCT_UPDATE_INSTANTLY_CREATED } from 'modules/product/store/constants';
import { localStorageKeys } from 'modules/app/constants';
// eslint-disable-next-line import/no-cycle
// import { AUTH_LOGOUT } from 'modules/auth/store/constants';

const saveLookBoardDataToLocalStorage = (newState, rewrite) => {
  const currentState =
    JSON.parse(localStorage.getItem(localStorageKeys.lookBoardData)) ?? {};
  const updatedState = {
    ...(!rewrite && currentState),
    ...newState,
  };
  const stringifiedData = JSON.stringify(updatedState);

  localStorage.setItem(localStorageKeys.lookBoardData, stringifiedData);
};

export const initialImageFilterValues = {
  [filterTabKeys.styles]: [],
  [filterTabKeys.subStyles]: {},
  [filterTabKeys.roomTypes]: [],
  [filterTabKeys.colors]: [],
  [filterTabKeys.itemClasses]: [],
};

export const initialProductFilterValues = {
  [filterTabKeys.styles]: [],
  [filterTabKeys.subStyles]: [],
  [filterTabKeys.colors]: [],
  [filterTabKeys.itemClasses]: [],
  [filterTabKeys.materials]: [],
  [filterTabKeys.shapes]: [],
  [filterTabKeys.prices]: [],
};

const initialImageSearchParams = {
  source: imageSourceKeys.all,
  type: imageTypeKeys.room,
  search: '',
  offset: 0,
  onlyApproved: true,
};

export const initialProductSearchParams = {
  type: productTypeKeys.all,
  search: '',
  offset: 0,
  countries: [],
};

const dndInitialState = {
  productList: {
    id: 'productList',
    productIds: [],
  },
  dynamicColumns: {},
};

const initialLookBoardData = {
  title: 'Get The Look',
  color: '#cdc3af',
  products: [],
  columns: null,
  requestId: null,
  lookBoardId: null,
};

const initialEditState = {
  lookBoardId: null,
  isDraft: false,
};

const initialState = {
  currentStep: createLookBoardStepKeys.selectImage,
  inspirationImageSearchParams: initialImageSearchParams,
  imageFilterValues: initialImageFilterValues,
  productFilterValues: initialProductFilterValues,
  imageList: [],
  productSearchParams: initialProductSearchParams,
  selectedTemplate: null,
  dndState: dndInitialState,
  lookBoardData: initialLookBoardData,
  oldLookBoardData: {},
  lookBoardDetailsFormValues: null,
  lookBoardStatus: lookBoardStatusKeys.public,
  editState: initialEditState,
  requestInfo: JSON.parse(
    localStorage.getItem(localStorageKeys.requestInfo) ?? 'null'
  ),
};

const curateTheLookReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case CTL_UPDATE_DND_STATE: {
      return {
        ...state,
        dndState: {
          ...state.dndState,
          ...payload,
        },
      };
    }
    case CTL_SET_IMAGES: {
      return {
        ...state,
        imageList: payload,
      };
    }
    case CTL_UPDATE_IMAGES: {
      return {
        ...state,
        imageList: [...state.imageList, ...payload],
      };
    }
    case CTL_SET_PRODUCTS: {
      return {
        ...state,
        dndState: {
          ...state.dndState,
          productList: {
            ...state.dndState.productList,
            productIds: payload,
          },
        },
      };
    }
    case CTL_UPDATE_PRODUCTS: {
      return {
        ...state,
        dndState: {
          ...state.dndState,
          productList: {
            ...state.dndState.productList,
            productIds: [...state.dndState.productList.productIds, ...payload],
          },
        },
      };
    }
    case CTL_INSERT_PRODUCT_TO_TOP: {
      return {
        ...state,
        dndState: {
          ...state.dndState,
          productList: {
            ...state.dndState.productList,
            productIds: [...payload, ...state.dndState.productList.productIds],
          },
        },
      };
    }
    case PRODUCT_UPDATE_INSTANTLY_CREATED: {
      return {
        ...state,
        dndState: {
          ...state.dndState,
          productList: {
            ...state.dndState.productList,
            productIds: [payload, ...state.dndState.productList.productIds],
          },
        },
      };
    }
    case CTL_SET_STEP: {
      return {
        ...state,
        currentStep: payload,
      };
    }
    case CTL_SET_SELECTED_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: payload,
      };
    }
    case CTL_UPDATE_LOOK_BOARD_DATA: {
      saveLookBoardDataToLocalStorage(payload);
      const isSetOld =
        !Object.keys(state.oldLookBoardData).length &&
        state.editState.lookBoardId;
      return {
        ...state,
        lookBoardData: {
          ...state.lookBoardData,
          ...payload,
          products:
            payload.products?.filter(Boolean) || state.lookBoardData.products,
        },
        oldLookBoardData: isSetOld
          ? {
              ...state.lookBoardData,
              ...state.lookBoardDetailsFormValues,
              ...payload,
              products: payload?.products?.filter(Boolean) || [],
            }
          : { ...state.oldLookBoardData },
      };
    }
    case CTL_RESET_CANVAS: {
      const lookBoardData = {
        ...initialLookBoardData,
        columns: state.selectedTemplate?.columnCount,
      };

      saveLookBoardDataToLocalStorage(lookBoardData);

      return {
        ...state,
        lookBoardData: {
          ...state.lookBoardData,
          ...lookBoardData,
        },
        oldLookBoardData: {},
        lookBoardDetailsFormValues: null,
        editState: initialEditState,
      };
    }
    case CTL_UPDATE_II_SEARCH_PARAMS: {
      return {
        ...state,
        inspirationImageSearchParams: {
          ...state.inspirationImageSearchParams,
          ...payload,
        },
      };
    }
    case CTL_UPDATE_PRODUCT_SEARCH_PARAMS: {
      return {
        ...state,
        productSearchParams: {
          ...state.productSearchParams,
          ...payload,
        },
      };
    }
    case CTL_SET_FORM_VALUES: {
      localStorage.setItem(
        localStorageKeys.lookBoardDetailsFormValues,
        JSON.stringify(payload)
      );
      return {
        ...state,
        lookBoardDetailsFormValues: payload,
      };
    }
    case CTL_SET_LOOK_BOARD_STATUS: {
      return {
        ...state,
        lookBoardStatus: payload,
      };
    }
    case CTL_UPDATE_STATE: {
      if ('requestInfo' in payload) {
        localStorage.setItem(
          localStorageKeys.requestInfo,
          JSON.stringify(payload.requestInfo)
        );
      }
      return { ...state, ...payload };
    }
    case CTL_RESET_STATE: {
      localStorage.removeItem(localStorageKeys.lookBoardDetailsFormValues);
      localStorage.removeItem(localStorageKeys.editState);
      saveLookBoardDataToLocalStorage(initialLookBoardData, true);
      return { ...initialState };
    }
    case CTL_UPDATE_IMAGE_FILTER_VALUES: {
      return {
        ...state,
        imageFilterValues: {
          ...state.imageFilterValues,
          ...payload,
        },
      };
    }
    case CTL_RESET_IMAGE_FILTER_VALUES: {
      return {
        ...state,
        imageFilterValues: { ...initialImageFilterValues },
      };
    }
    case CTL_UPDATE_PRODUCT_FILTER_VALUES: {
      return {
        ...state,
        productFilterValues: {
          ...state.productFilterValues,
          ...payload,
        },
      };
    }
    case CTL_RESET_PRODUCT_FILTER_VALUES: {
      return {
        ...state,
        productFilterValues: { ...initialProductFilterValues },
      };
    }
    case CTL_SET_EDIT_STATE:
      localStorage.setItem(localStorageKeys.editState, JSON.stringify(payload));
      return {
        ...state,
        editState: payload,
      };
    // case AUTH_LOGOUT: {
    //   return { ...initialState };
    // }
    default:
      return state;
  }
};

export default curateTheLookReducer;
