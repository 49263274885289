import Rectangular from 'assets/img/shapes/rectangle.png';
import Square from 'assets/img/shapes/square.png';
import Circle from 'assets/img/shapes/circle.png';
import Ellipse from 'assets/img/shapes/ellipse.png';
import HalfCircle from 'assets/img/shapes/halfCircle.png';
import Hexagonal from 'assets/img/shapes/hexagonal.png';
import Arched from 'assets/img/shapes/arched.png';
import Freeform from 'assets/img/shapes/free.png';

export const filterTabKeys = {
  vibes: 'vibes',
  styles: 'styles',
  subStyles: 'subStyles',
  roomTypes: 'roomTypes',
  colors: 'colors',
  itemClasses: 'itemClasses',
  videos: 'videos',
  metaTags: 'metaTags',
  materials: 'materials',
  shapes: 'shapes',
  prices:'prices',
};

export const filterTabsConfig = [
  {
    value: filterTabKeys.vibes,
    title: 'Vibes',
  },
  {
    value: filterTabKeys.styles,
    title: 'Styles',
  },
  {
    value: filterTabKeys.subStyles,
    title: 'Sub Styles',
  },
  {
    value: filterTabKeys.roomTypes,
    title: 'Room Types',
  },
  {
    value: filterTabKeys.colors,
    title: 'Colors',
  },
  {
    value: filterTabKeys.itemClasses,
    title: 'Items',
  },
];

export const modalSubtitles = {
  [filterTabKeys.styles]: 'Select Styles for your Search',
  [filterTabKeys.subStyles]: 'Select Sub Styles for your Search',
  [filterTabKeys.roomTypes]: 'Select Room Type(s)',
  [filterTabKeys.colors]: 'Select Primary Color(s) (optional)',
  [filterTabKeys.itemClasses]: 'Select Item Type(s) (optional)',
  [filterTabKeys.shapes]: 'SELECT Shapes (OPTIONAL)',
  [filterTabKeys.materials]: 'SELECT Material (OPTIONAL)',
  [filterTabKeys.prices]: 'SELECT Price (OPTIONAL)',
};

export const styleQuizSubtitles = {
  [filterTabKeys.vibes]: 'How do you want your room to feel?',
  [filterTabKeys.styles]: 'Which Room do you like best?',
  [filterTabKeys.subStyles]: 'Indicate which room images you like',
  [filterTabKeys.roomTypes]: 'What Room(s) are you decorating?',
  [filterTabKeys.colors]: 'What colors do you want to see in your room?',
  [filterTabKeys.itemClasses]: 'Indicate which item you like',
};

export const styleQuizDescriptions = {
  [filterTabKeys.vibes]: 'Select Up to One Vibe',
  [filterTabKeys.styles]: 'Select one',
  [filterTabKeys.subStyles]:
    'Indicate which room images you like best by clicking on the heart',
  [filterTabKeys.roomTypes]: 'Select all that apply',
  [filterTabKeys.colors]: 'Select all that apply',
};

export const shapeByName = {
  Rectangular,
  Square,
  Round: Circle,
  'Oval | Oblong': Ellipse,
  'Half Circle': HalfCircle,
  Hexagonal,
  Arched,
  Freeform,
};
