import { routesByName } from '../../../constants/routes';

const termsConfig = [
  {
    title: 'Pin The Look Terms',
    description: {
      text:
        'Welcome to Pin the Look! By confirming your registration, you are AGREEING to our Terms outlined here. Please carefully review our terms, which are legally binding.',
    },
    underCut: {
      text:
        'These Terms of Use ("Terms") govern your access to and use of any website, app, service, technology, API, widget, platform, channel or any other products or features owned, operated, or branded by Pin the Look (“Pin the Look” or the “Service“ or the “Product” or “Products”). Please read these Terms carefully, and contact us if you have any questions. By accessing or using our Products and Services, you agree to be bound by these Terms and by our Community Guidelines and  Privacy Policy. \n' +
        'By accessing or using Pin the Look, you agree to be bound by these Terms. If you do not agree to our Terms, you have no legal right to access or use this Service.\n',
      split: true,
      linkLabel1: 'Community Guidelines',
      linkHref1: routesByName.community,
      linkLabel2: 'Privacy Policy',
      linkHref2: routesByName.privacy,
    },
  },
  {
    title: 'Use of Pin the Look',
    description: {
      text:
        'If you use Pin the Look, you can only do so in compliance with our Terms. The key terms related to using the platform include:',
      list: [
        {
          id: 1,
          title: 'Who can use Pin the Look',
          text: 'Users must register and be at least 13 years of age.',
          sublist: [
            'You must provide accurate and complete profile information.',
            'You must be over 13',
            'If you open an account on behalf of a company or organization, all the terms apply to both you and the company.',
            'If you become an Ambassador on Pin the Look, all Terms apply and you may represent yourself as an Ambassador, but not as an Agent, Personnel, or Partner.',
            "If you use our platform for commercial purposes (for example, to promote your company’s products or services) you must create a business account. Business accounts must be approved by Pin the Look. Look Boards that contain only items from a company's site may not be published and the number of free Look Boards created by a business account will be limited. Paid options may be available.",
          ],
        },
        {
          id: 2,
          title: 'Images, Content, Links & Look Boards',
          text:
            'Users agree to pin only unrestricted images which can be stored, used, and shared on Pin the Look.',
          sublist: [
            'If you upload an image you own, you can specify “Exclusive Use”, but be ready to have your images shared and stored. ',
            'If you Pin an image from the Web, make sure it’s not restricted or copyright protected. Bottom line: YOU ARE LIABLE FOR WHAT YOU PIN!',
            'Even though you are responsible for what you add, you grant all our users a non-exclusive royalty-free transferable, sublicensable worldwide license to use, store, display,reproduce, save, modify, create derivative works and distribute any images you add...there’s just no shorter way to say this.\n',
            'If you cancel your account, the images you added from the Web and any works created using them, will still be used on Pin the Look.',
            'You can use Pin the Look to create Look Boards that you can share online or with friends. But once you create something, you agree that we can use it on our platform, even if you cancel your account, and others can share it, too.',
            'If we have previously disabled your account for violating these Terms, you agree not to create a new Pin the Look account without our express written permission, which is provided at Pin the Look’s sole discretion.',
            'You agree not to scrape, collect, search, copy or otherwise access data or content from Pin the Look in unauthorized ways. You also agree not to use our images or Look Boards for Artificial Intelligence (AI) related functions or machine learning purposes on sites unrelated to Pin the Look.',
          ],
        },
        {
          id: 3,
          title: 'License Granted to You',
          text: 'Your account cannot be transferred or used by third-parties.',
          sublist: [
            'modify or make derivative works based upon the Service',
            'reverse engineer the Service or access the Service',
            'copy any features or functions of the Service',
          ],
          sublistType: 'i',
        },
        {
          id: 4,
          title: 'Commercial use of Pin the Look\n',
          text:
            'Your account can only be used for commercial purposes if you are granted a Business Account and subscribe.',
          sublist: [],
        },
      ],
    },
    underCut: {
      list: [
        {
          id: 1,
          title: 'Who can use Pin the Look',
          sublist: [
            {
              title: 'Who can use Pin the Look',
              text:
                'You must register and provide accurate and complete profile information.',
            },
            {
              title: 'Our license to you',
              text: 'You must be over 13.',
            },
            {
              title: 'Commercial use of Pin the Look',
              text:
                'If you open an account on behalf of a company or organization, all the terms apply to both you and the company.\n',
            },
            {
              title: '',
              text:
                'If you become an Ambassador on Pin the Look, all Terms apply and you may represent yourself as an Ambassador, but not as an Agent, Personnel, or Partner. \n',
            },
            {
              title: '',
              text:
                "If you use our platform for commercial purposes (for example, to promote your company’s products or services) you must create a business account. Business accounts must be approved by Pin the Look. Look Boards that contain only items from a company's site may not be published and the number of free Look Boards created by a business account will be limited. Paid options may be available.\n",
            },
          ],
        },
        {
          id: 2,
          title: 'Images, Content, Links & Look Boards\n',
          sublist: [
            {
              title: 'Posting content',
              text:
                'If you upload an image you own, you can specify “Exclusive Use”, but you agree to have your images shared and stored by Pin the Look. \n',
            },
            {
              title: 'How Pin the Look and other users can use your content',
              text:
                'If you Pin an image from the Web, make sure it’s not restricted or copyright protected. Bottom line: YOU ARE LIABLE FOR WHAT YOU PIN!\n',
            },
            {
              title: 'How long we keep your content',
              text:
                'Even though you are responsible for what you add, you grant all our users a non-exclusive royalty-free transferable, sublicensable worldwide license to use, store, display, reproduce, save, modify, create derivative works and distribute any images you add...there’s just no shorter way to say this.',
            },
            {
              title: 'Feedback you provide',
              text:
                'If you cancel your account, the images you added from the Web and any works created using them, will still be used on Pin the Look.\n',
            },
            {
              title: '',
              text:
                'You can use Pin the Look to create Look Boards that you can share online or with friends. But once you create something, you agree that we can use it on our platform, even if you cancel your account, and others can share it, too.\n',
            },
            {
              title: '',
              text:
                'If we have previously disabled your account for violating these Terms, you agree not to create a new Pin the Look account without our express written permission, which is provided at Pin the Look’s sole discretion.\n',
            },
            {
              title: '',
              text:
                'You agree not to scrape, collect, search, copy or otherwise access data or content from Pin the Look in unauthorized ways. You also agree not to use our images or Look Boards for Artificial Intelligence (AI) related functions or machine learning purposes on sites unrelated to Pin the Look.\n',
            },
          ],
        },
        {
          id: 3,
          title: 'License Granted to You',
          text:
            'Subject to your compliance with these Terms and Community Policies, we hereby grant you a limited, non-exclusive, non-transferable, non-sublicensable and revocable license to access and use the Service as contemplated under these Terms and our policies.\n' +
            'You may not permit Pin the Look to be used by or for the benefit of unauthorized third parties. Nothing in these Terms will be construed to grant you any right to transfer or assign rights to access or use Pin the Look. All rights not expressly granted to you are reserved by us and our licensors. You may not:\n',
          sublist: [
            { text: 'modify or make derivative works based upon the Service' },
            { text: 'reverse engineer the Service or access the Service' },
            { text: 'copy any features or functions of the Service' },
          ],
          sublistType: 'i',
        },
        {
          id: 4,
          title: 'Commercial use of Pin the Look',
          text:
            'If you want to use Pin the Look for commercial purposes you must create a Business Account and agree to our Terms. Business accounts may be offered to retailers, home furnishing manufacturers, designers, architects, and other businesses in the home furnishing market. Business accounts are subject to approval and fees. To inquire about a Pin the Look business account, you can email us at info@pinthelook.com.\n' +
            'If you open an account for a company, organization or other entity, then "you" includes you and that entity, and you represent and warrant that you are authorized to grant all permissions and licenses provided in these Terms and bind the entity to these Terms, and that you agree to these Terms on the entity\'s behalf.\n',
          sublist: [],
        },
      ],
    },
  },
  {
    title: 'User Content',
    description: {
      text:
        'Pin the Look allows you to post content, including photos, comments, links, and other materials. Anything that you post or otherwise make available on our Products is referred to as "User Content." ',
      list: [],
    },
    underCut: {
      text: '',
      list: [
        {
          id: 1,
          title: 'Posting User Content',
          text:
            'You retain all rights in, and are solely responsible for, the User Content you post to Pin the Look.\n',
          sublist: [],
        },
        {
          id: 2,
          title: 'Use of Your User Content',
          text:
            'You grant Pin the Look and our users a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, store, display, reproduce, save, modify, create derivative works, perform, and distribute your User Content on Pin the Look solely for the purposes of operating, developing, providing, and using the Pin the Look Service. Nothing in these Terms shall restrict other legal rights Pin the Look may have to User Content, for example under other licenses. We reserve the right to remove or modify User Content for any reason, including User Content that we believe violates these Terms or Copyright laws.\n',
          sublist: [],
        },
        {
          id: 3,
          title: 'Longevity of Your User Content',
          text:
            'Following termination or deactivation of your account, or if you remove any User Content from Pin the Look, we may retain your User Content for a commercially reasonable period of time for backup, archival, or audit purposes. Furthermore, Pin the Look and its users may retain and continue to use, store, display, reproduce, re-pin, modify, create derivative works, perform, and distribute any of your User Content that other users have stored or shared through Pin the Look.\n',
          sublist: [],
        },
        {
          id: 4,
          title: 'Feedback you Provide',
          text:
            'We value hearing from our users, and are always interested in learning about ways we can make Pin the Look better. If you choose to submit comments, ideas or feedback, you agree that we are free to use them without any restriction or compensation to you. By accepting your submission, Pin the Look does not waive any rights to use similar or related Feedback previously known to Pin the Look, or developed by its employees, or obtained from sources other than you.',
          sublist: [],
        },
        {
          id: 5,
          title: 'Image Claims',
          text:
            'Pin the Look provides forms to claim image ownership, request images to be removed, or report images that you think are inappropriate or violate these Terms or local law. \n' +
            'We will review your report and take any appropriate action in a timely fashion.  In using these forms, you agree to submit reports in good faith and not misuse any reporting or appeals channel by making baseless reports or appeals. To report a copyright violation or an inappropriate image, click on the 3-dots located in the lower right corner of each Inspiration Image on Pin the Look. If you own the image that someone has pinned, you can either "claim the image" to get credit or request that it be removed from the site. If you claim an image for credit, you have the option of making the image “open” for others to curate or making it “exclusive” to be curated only by you.\n',
          sublist: [],
        },
      ],
    },
  },
  {
    title: 'Copyright Policy',
    description: {
      text:
        'Pin the Look respects the intellectual property rights of others and our users are expected to do the same.\n' +
        'Our copyright policy is in compliance with the Digital Millenium Act (DMCA), which can be found on the US Copyright Office site. We follow all copyright laws and our users need to as well.\n',
    },
    underCut: {
      list: [
        {
          id: 1,
          title: 'Copyright Infringement Reporting',
          text:
            'If you are a copyright owner, you can report a violation by clicking on the three-dots in the lower right corner of all images, and completing a form. Pin the Look will immediately investigate and take the appropriate action, including disabling or removing access to the reported image on Pin the Look.\n' +
            'If we disable or remove access to content in response to a copyright infringement report , we may notify the person who saved it on Pin the Look so they have the opportunity to send a counter notification. We may also send a complete copy of the notice to others, as appropriate.\n',
          sublist: [],
        },
        {
          id: 2,
          title: 'Counter to Copyright Infringement\n',
          text:
            'If you’ve received a notice that your content has been removed based on a copyright infringement report, it means the copyright owner submitted a complete and valid report asking us to remove it. If you want us to forward the information from that copyright report, you can request this information at info@pinthelook.com.\n' +
            'If your account gets content removed based on too many copyright infringement reports, you could lose the ability to Pin images on Pin the Look, or we may disable your account altogether.\n' +
            '\n' +
            'If you disagree with the copyright infringement action, you can counter by requesting reinstatement of the image by emailing us at info@pinthelook.com. We will forward your counter report to the person who made the original claim and provide your contact information. We will inform you of any change to the original claim decision.\n',
          sublist: [],
        },
      ],
    },
  },
  {
    title: 'Security',
    description: {
      text:
        'We will do everything possible to keep your content secure. But honestly, we can’t make any guarantees. So please keep your password secure! Please notify us immediately of any unauthorized access to your account or any other breach of security.',
    },
  },
  {
    title: 'Termination',
    description: {
      text:
        'We love having you as part of the Pin the Look community! But if you violate our Terms, we reserve the right to terminate your account. Where required by law, we will provide you with written notice. You may also terminate or delete your account at any time in the Profile section. Upon termination, the Terms of this agreement continue to be operative.',
    },
  },
  {
    title: 'Indemnity',
    description: {
      text:
        'By confirming your account, you agree to hold us harmless in the event that you are sued for content you post, create or share on Pin the Look. Plus, you agree to pay our legal fees if we get sued as a result. So please don’t violate our policies!',
    },
    underCut: {
      text:
        'If you use Pin the Look for commercial purposes, you agree to indemnify and hold harmless Pin the Look and its respective officers, directors, employees and agents, from and against any claims, suits, proceedings, disputes, demands, liabilities, damages, losses, costs and expenses, including, without limitation, reasonable legal and accounting fees (including costs of defense of claims, suits or proceedings brought by third parties), in any way related to (a) your access to or use of our products and services, (b) your User Content, or (c) your breach of any of these Terms.',
    },
  },
  {
    title: 'Arbitration',
    description: {
      text:
        'If we have a dispute, we both agree to resolve it through the American Association of Arbitration (AAA). You agree to contact us first. You also agree that any claim cannot exceed $100. If you don’t like these rules, please don’t use our platform.',
    },
    underCut: {
      text:
        'For any dispute you have with Pin the Look, you agree to first contact us and attempt to resolve the dispute with us informally. If Pin the Look has not been able to resolve the dispute with you informally, we each agree to resolve any claim, dispute, or controversy (excluding claims for injunctive or other equitable relief) arising out of or in connection with or relating to these Terms by binding arbitration by the American Arbitration Association ("AAA") under the Commercial Arbitration Rules and Supplementary Procedures for Consumer Related Disputes then in effect for the AAA, except as provided herein. Unless you and Pin the Look agree otherwise, the arbitration will be conducted in the county where you reside. Each party will be responsible for paying any AAA filing, administrative and arbitrator fees in accordance with AAA rules, except that Pin the Look will pay for your reasonable filing, administrative, and arbitrator fees if your claim for damages does not exceed $100 and is non-frivolous (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)). The award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys fees and reasonable costs for expert and other witnesses, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this Section shall prevent either party from seeking injunctive or other equitable relief from the courts for matters related to data security, intellectual property or unauthorized access to the Service. ALL CLAIMS MUST BE BROUGHT IN THE PARTIES INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON\'S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND PIN THE LOOK ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.',
    },
  },
  {
    title: 'Disclaimers',
    description: {
      text:
        'We can’t always control the images, products, and content that people post on Pin the Look or the webpages the pins link to. We do our best to check all links before images are posted, but we cannot guarantee that we’ll catch everything. If you see something that isn’t appropriate or relevant, please report it right away by clicking on the three-dots in the lower right corner  of every image on Pin the Look. We’ll review any image reported for inappropriate use and take it down if it violates our terms. Pin the Look is a social curation site where Ambassadors and users will exchange ideas and make recommendations. By using this site, you understand that Pin the Look takes no responsibility and assumes no liability for the recommendations, ideas, and input made through exchanges on the site. As a user of Pin the Look, you assume full responsibility for the actions you take based upon the recommendations of Pin the Look’s personnel or Ambassadors. ',
    },
    underCut: {
      text:
        'The Images, Products and all included content are provided on an "as is" basis without warranty of any kind, whether express or implied. Pin the Look SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. Pin the Look takes no responsibility and assumes no liability for any User Content that you or any other user or third party posts or transmits using our Service. You understand and agree that you may be exposed to User Content that is inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose. Our Products may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by Pin the Look. We do not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services. If you access any third party website, service, or content from Pin the Look, you do so at your own risk and you agree that Pin the Look will have no liability arising from your use of or access to any third-party website, service, or content. \n',
    },
  },
  {
    title: 'Limitation of Liability',
    description: {
      text:
        'We are creating this business to help people get the look they love and we’re doing the best we can. We’re not liable for all kinds of things that might occur as a result of your using our site and our liability is highly limited. Our aggregate liability for any claim cannot exceed $100 - and you agree to that.',
    },
    underCut: {
      text:
        'TO THE MAXIMUM EXTENT PERMITTED BY LAW, PIN THE LOOK SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE PRODUCTS; (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE PRODUCTS, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; OR (C) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL PIN THE LOOK’S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE PRODUCTS EXCEED ONE HUNDRED U.S. DOLLARS (U.S. $100.00).\n',
    },
  },
  {
    title: 'Governing Law',
    description: {
      text:
        'We operate in California and so these terms shall be governed by the laws of the State of California. And if you do try to sue us, you’ll have to do so in person in California. ',
    },
    underCut: {
      text:
        'These Terms shall be governed by the laws of the State of California, without respect to its conflict of laws principles. We each agree to submit to the personal jurisdiction of a state court located in San Francisco County, California or the United States District Court for the Northern District of California, for any actions not subject to Section 10 (Arbitration). These Terms, together with the Privacy policy and any amendments and any additional agreements you may enter into with Pin the Look in connection with the Products, shall constitute the entire agreement between you and Pin the Look concerning the Products. If any provision of these Terms is deemed invalid, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect. No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Pin the Look’s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.\n',
    },
  },
  {
    title: 'General Agreement',
    description: {
      text:
        'By clicking on “I Agree”, you are agreeing to ALL TERMS and that they are binding and this is the entire agreement.\n',
    },
    underCut: {
      list: [
        {
          id: 1,
          title: 'Changes to Terms',
          text:
            'We may change, add, or remove portions of these Terms over time. The most current version of our Terms will always be posted on our website. Unless otherwise required by law, we’ll notify you of any material changes to these Terms before any update enters into effect. Your continued access to or use of Pin the Look after such an update constitutes your binding acceptance of such changes. Disputes arising under these Terms will be resolved in accordance with the version of these Terms in place at the time the dispute arose. \n',
          sublist: [],
        },
        {
          id: 2,
          title: 'Entire Agreement',
          text:
            'These Terms constitute the entire agreement between you and Pin the Look concerning the Service. If any provision of these Terms is deemed invalid, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect.\n',
          sublist: [],
        },
        {
          id: 3,
          title: 'No Waiver',
          text:
            'No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision, and our failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.',
          sublist: [],
        },
      ],
    },
  },
];

export default termsConfig;
