import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const mixAndMatchService = {
  saveMixAndMatch(inspirationImageId, products) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/mix-match`, {
      inspirationImageId,
      products,
    });
  },

  getList(data, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/user/mix-matches`,
      data,
      config
    ).then(({ result }) => result);
  },

  removeMix(mixId) {
    return AuthorizedAxiosInstance.delete(`${urlPrefix}/mix-match/${mixId}`);
  },
};

export default mixAndMatchService;
