import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const comingSoonService = {
  sendNotifyEmail(data) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/notification-email`,
      data
    );
  },
  checkPasswordComingSoon(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/coming-soon`, data);
  },
};

export default comingSoonService;
