import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classes from 'modules/auth/forgotPassword/ForgotPassword.module.scss';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import { Field, Form } from 'react-final-form';
import { signUpFormFields } from 'modules/auth/signUp/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import passwordRecoveryValidator from 'modules/auth/forgotPassword/PasswordRecoveryModal/passwordRecoveryValidator';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import authService from 'modules/auth/authService';
import errorToastr from 'libs/toastr/errorToastr';

const PasswordRecoveryModal = ({ open, onClose }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailValue, setEmailValue] = useState('');

  const modalTitle = useMemo(() => {
    return emailSent ? (
      <span>
        Reset password <br /> link Sent to your email
      </span>
    ) : (
      'password recovery'
    );
  }, [emailSent]);

  useEffect(() => {
    if (!open) {
      setEmailSent(false);
      setEmailValue('');
    }
  }, [open]);

  const sendEmail = useCallback(async (formValues) => {
    try {
      await authService.forgotPassword(formValues);
      setEmailSent(true);
      setEmailValue(formValues[signUpFormFields.email]);
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      classes={{ root: classes.root }}
      fullWidth
      maxWidth="sm"
    >
      <h3 className={classes.title}>{modalTitle}</h3>
      {emailSent ? (
        <div className="px-4">
          <div className={`${classes.emailFakeInput} mb-3`}>
            <input readOnly value={emailValue} />
          </div>
        </div>
      ) : (
        <Form
          validate={passwordRecoveryValidator}
          onSubmit={sendEmail}
          render={({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="px-4">
                <Field
                  required
                  label="Email"
                  type="email"
                  name={signUpFormFields.email}
                  component={TextInputWrapper}
                />
              </div>
              <div className="text-center">
                <FinalFormSubmitButton inline>Submit</FinalFormSubmitButton>
              </div>
            </form>
          )}
        />
      )}
    </BasicModal>
  );
};

PasswordRecoveryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PasswordRecoveryModal;
