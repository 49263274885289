import { UPDATE_USERS_LIBRARY } from 'modules/users/store/constants';

const initialState = {
  library: {},
};

const usersReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case UPDATE_USERS_LIBRARY: {
      return {
        ...state,
        library: { ...state.library, ...payload.transformedUsers },
      };
    }
    default:
      return state;
  }
};

export default usersReducer;
