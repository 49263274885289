import React from 'react';
import SpriteIcon from 'components/ui/SpriteIcon';
import PropTypes from 'prop-types';
import classes from 'components/modals/FiltersModal/components/Prompt/Prompt.module.scss';

const Prompt = ({ open, text }) => {
  return open ? (
    <div id="prompt" className={classes.wrapper}>
      <div className={classes.infoIcon}>
        <SpriteIcon name="info" size="lg" />
      </div>
      <div className={classes.text}>{text}</div>
    </div>
  ) : null;
};

Prompt.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
export default Prompt;
