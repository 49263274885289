import { useEffect, useState } from 'react';
import throttle from 'lodash-es/throttle';

const useMediaQuery = (mediaQueryString) => {
  const [matches, setMatches] = useState(
    window.matchMedia(mediaQueryString).matches
  );

  useEffect(() => {
    const handleWindowResize = throttle(() => {
      setMatches(window.matchMedia(mediaQueryString).matches);
    }, 200);

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line
  }, []);

  return matches;
};

export default useMediaQuery;
