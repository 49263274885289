export const reportReasonTypes = {
  reportReason: 'reportReason',
  reportSubReason: 'reportSubReason',
};

export const reportReasonValues = {
  own: 'own',
  inappropriate: 'inappropriate',
};

export const reportSubReasonValues = {
  credit: 'credit',
  takeDown: 'takeDown',
  spam: 'spam',
  selfHarm: 'selfHarm',
  medical: 'medical',
  hateSpeechSymbols: 'hateSpeechSymbols',
  harassmentPrivacy: 'harassmentPrivacy',
  graphicViolence: 'graphicViolence',
};

export const firstStepConfig = [
  {
    label: 'I own this image',
    value: reportReasonValues.own,
  },
  {
    label: 'This image is inappropriate',
    value: reportReasonValues.inappropriate,
  },
];

export const secondStepConfig = {
  [reportReasonValues.own]: [
    {
      label: 'I want credit for this image',
      value: reportSubReasonValues.credit,
    },
    {
      label: 'I want this image taken off the site',
      value: reportSubReasonValues.takeDown,
    },
  ],
  [reportReasonValues.inappropriate]: [
    {
      label: 'Spam',
      description: 'Misleading or repetitive posts',
      value: reportSubReasonValues.spam,
    },
    {
      label: 'Self-harm',
      description: 'Eating disorders, cutting, suicide',
      value: reportSubReasonValues.selfHarm,
    },
    {
      label: 'Medical misinformation',
      description: 'Harmful or misleading content about health and safety',
      value: reportSubReasonValues.medical,
    },
    {
      label: 'Hate speech or symbols',
      description: 'Attacks directed at protected groups',
      value: reportSubReasonValues.hateSpeechSymbols,
    },
    {
      label: 'Harassment or privacy violations',
      description: 'Threats, blackmail, personal attacks',
      value: reportSubReasonValues.harassmentPrivacy,
    },
    {
      label: 'Graphic violence',
      description: 'Violent images or promotion of violence',
      value: reportSubReasonValues.graphicViolence,
    },
  ],
};

export const stepsConfig = [
  {
    number: 1,
    name: reportReasonTypes.reportReason,
  },
  {
    number: 2,
    name: reportReasonTypes.reportSubReason,
  },
];
