import {
  SET_ACTIVE_TAB,
  SET_SHOWCASE_ACTIVE_TAB,
} from 'modules/dashboard/store/constants';
import { imageSourceKeys } from 'constants/inspirationImageSearchParams';
import { dropItemType } from '../showcase/constants';

const initialState = {
  activeTab: imageSourceKeys.my,
  showcaseActiveTab: dropItemType.inspirationImages,
};

const dashboardReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }
    case SET_SHOWCASE_ACTIVE_TAB: {
      return {
        ...state,
        showcaseActiveTab: payload,
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
