import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import {
  requestModalFields,
  requestModalPlaceholders,
  describeYourselfOptions,
  ambassadorInterests,
  errorMessages,
  referredValues,
} from 'modules/ambassadors/components/RequestModal/constants';
import modalImg from 'assets/img/ambassador-page/request-modal.jpg';
import { reCaptchaActions } from 'constants/V3CaptchaActions';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import RadioInputWrapper from 'components/finalFormWrappers/RadioInputWrapper';
import { DescribeYourself } from '../DescribeYourself/DescribeYourself';
import { FavouriteStyles } from '../FavouriteStyles/FavouriteStyles';
import { Interests } from '../Interests/Interests';
import classes from './RequestModal.module.scss';
import TextInput from '../../../../components/inputs/TextInput/TextInput';
import Button from '../../../../components/ui/Button/Button';

const fieldsArr = [
  {
    id: 1,
    value: '',
  },
  {
    id: 2,
    value: '',
  },
  {
    id: 3,
    value: '',
  },
];
const RequestModal = ({ open, onClose, onSubmit }) => {
  const { firstName, lastName } = useSelector((state) => state.auth.user) ?? {};
  const enums = useSelector((state) => state.app.enums);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [userExperience, setUserExperience] = useState('');

  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const [isStylesError, setIsStylesError] = useState(false);
  const [isInterestsError, setIsInterestsError] = useState(false);
  const [isUserExperienceError, setIsUserExperienceError] = useState(false);
  const [fieldsArrData, setFieldsArrData] = useState(fieldsArr);
  const [referredField, setReferredField] = useState('');
  const [referredError, setReferredError] = useState(false);

  function isURL(str) {
    const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return urlPattern.test(str);
  }
  function hasNoError(arr) {
    return arr.every((obj) => !('error' in obj));
  }

  const handleSubmitForm = useCallback(
    async ({ isReferred }) => {
      let errors = 0;

      if (!selectedDescription) {
        setIsDescriptionError(true);
        errors += 1;
      } else {
        setIsDescriptionError(false);
      }

      if (!selectedStyles.length || selectedStyles.length > 3) {
        setIsStylesError(true);
        errors += 1;
      } else {
        setIsStylesError(false);
      }

      if (!selectedInterests.length || selectedInterests.length > 3) {
        setIsInterestsError(true);
        errors += 1;
      } else {
        setIsInterestsError(false);
      }

      if (!userExperience.length) {
        setIsUserExperienceError(true);
        errors += 1;
      } else {
        setIsUserExperienceError(false);
      }

      const arrayFields = [];
      const newFieldsArrData = fieldsArrData.map(({ id, value }) => {
        if (isURL(value) || value === '') {
          arrayFields.push(value);
          return { id, value };
        }
        return { id, value, error: 'Please enter a correct link' };
      });
      setFieldsArrData(newFieldsArrData);

      const filteredArr = arrayFields.filter((value) => value !== '');

      const validReferred =
        (isReferred === referredValues.yes && referredField.length > 3) ||
        isReferred === referredValues.no;

      setReferredError(!validReferred);

      if (errors === 0 && hasNoError(newFieldsArrData) && validReferred) {
        const formValues = {
          [requestModalFields.describe_yourself]: selectedDescription,
          [requestModalFields.styles]: selectedStyles,
          [requestModalFields.interests]: selectedInterests,
          [requestModalFields.message]: userExperience,
          [requestModalFields.arrayFields]: filteredArr,
          ...(isReferred === referredValues.yes && {
            [requestModalFields.referredName]: referredField,
          }),
        };
        let reCaptchaToken;
        if (!executeRecaptcha) {
          reCaptchaToken = null;
        }
        reCaptchaToken = await executeRecaptcha(
          reCaptchaActions.requestInvitation
        );
        onSubmit({ ...formValues, reCaptchaToken });
      }
    },
    [
      executeRecaptcha,
      onSubmit,
      selectedDescription,
      selectedStyles,
      selectedInterests,
      userExperience,
      fieldsArrData,
      referredField,
    ]
  );

  const handleDescribeYourself = useCallback((title) => {
    setSelectedDescription(title);
    if (title) {
      setIsDescriptionError(false);
    }
  }, []);

  const handleFavouriteStyles = useCallback(
    (title) => {
      if (selectedStyles.length === 3) {
        setIsStylesError(true);
      } else {
        setIsStylesError(false);
      }
      if (selectedStyles.length < 3) {
        if (selectedStyles.includes(title)) {
          const updatedArray = selectedStyles.filter(
            (element) => element !== title
          );
          setSelectedStyles(updatedArray);
        } else {
          const updatedArray = [...selectedStyles, title];
          setSelectedStyles(updatedArray);
        }
      } else if (selectedStyles.length >= 3 && selectedStyles.includes(title)) {
        const updatedArray = selectedStyles.filter(
          (element) => element !== title
        );
        setSelectedStyles(updatedArray);
        setIsStylesError(false);
      }
    },
    [selectedStyles]
  );

  const handleInterests = useCallback(
    (e) => {
      if (selectedInterests.length === 3) {
        setIsInterestsError(true);
      } else {
        setIsInterestsError(false);
      }
      if (selectedInterests.length < 3) {
        if (selectedInterests.includes(e.target.value)) {
          const updatedArray = selectedInterests.filter(
            (element) => element !== e.target.value
          );
          setSelectedInterests(updatedArray);
        } else {
          const updatedArray = [...selectedInterests, e.target.value];
          setSelectedInterests(updatedArray);
        }
      } else if (
        selectedInterests.length >= 3 &&
        selectedInterests.includes(e.target.value)
      ) {
        const updatedArray = selectedInterests.filter(
          (element) => element !== e.target.value
        );
        setSelectedInterests(updatedArray);
        setIsInterestsError(false);
      }
    },
    [selectedInterests]
  );

  const handleMessage = useCallback((e) => {
    setUserExperience(e.target.value);
    if (e.target.value) {
      setIsUserExperienceError(false);
    } else {
      setIsUserExperienceError(true);
    }
  }, []);

  const handleAddField = useCallback(() => {
    setFieldsArrData((prevState) => {
      return [
        ...prevState,
        {
          id: prevState.length + 1,
          value: '',
        },
      ];
    });
  }, []);

  const handleFieldChanged = useCallback((id, newValue) => {
    setFieldsArrData((prevState) => {
      const updatedFields = prevState.map((field) => {
        if (field.id === id) {
          if (isURL(newValue) || newValue === '') {
            return { id, value: newValue };
          }
          return {
            ...field,
            value: newValue,
          };
        }
        return field;
      });
      return updatedFields;
    });
  }, []);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ root: classes.root, closeBtn: classes.closeBtn }}
      maxWidth="md"
    >
      <div className="d-flex flex-column flex-md-row pb-0 pb-md-2">
        <div className={clsx('order-1 order-md-0', classes.about)}>
          <h2 className={classes.title}>BECOME AN AMBASSADOR</h2>
          <p className={classes.description}>
            Pin the Look Ambassadors are decor lovers with a keen eye for design
            who find and curate the most inspiring images and help others get
            the look they love. Ambassadors also benefit from a variety of
            perks, including community membership, reward points, social media
            exposure, and exclusive access to our affiliate programs.
          </p>
          <p
            className={clsx('font-italic font-semi-bold mb-2', classes.caption)}
          >
            Qualified Candidates include:
          </p>
          <ul className={classes.list}>
            <li>Decor Enthusiasts</li>
            <li>Design Professionals</li>
            <li>Influencers & Bloggers</li>
          </ul>
        </div>
        <div className="flex-fill order-0 order-md-1">
          <img
            className="w-100 object-fit-contain h-auto"
            src={modalImg}
            alt=""
          />
        </div>
      </div>

      <div className={clsx('w-100 d-md-none', classes.separator)}>
        <div />
      </div>

      <div className={classes.formContainer}>
        <p className="text-sm mb-2">
          <b className="mr-0 mr-md-1">From:</b> {`${firstName} ${lastName}`}
        </p>
        <p className="text-sm mb-3">
          <b className="mr-0 mr-md-1">Subject:</b> Ambassador Invitation Request
        </p>

        <div className={clsx('d-md-none', classes.banner)}>
          Tell us about you!
        </div>
        <Form
          noValidate
          onSubmit={handleSubmitForm}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-3">
                <div className={`${classes.stepTitle} mb-2`}>
                  1. WHAT BEST DESCRIBES YOU?
                </div>
                <div className={`${classes.stepDescription} mb-2`}>
                  Select one
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-2">
                  {describeYourselfOptions.map((option, idx) => {
                    return (
                      <DescribeYourself
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={idx}
                        selectedDescription={selectedDescription}
                        handleDescribeYourself={handleDescribeYourself}
                        title={option.title}
                        titleSvg={option.titleSvg}
                        description={option.description}
                        imageUrl={option.image}
                      />
                    );
                  })}
                </div>
                <div>
                  <Alert
                    icon={false}
                    severity="error"
                    className={clsx({
                      'd-none': !isDescriptionError,
                    })}
                  >
                    {errorMessages.required}
                  </Alert>
                </div>
              </div>
              <div>
                <div className={`${classes.stepTitle} mb-2`}>
                  2. WHAT ARE YOUR THREE FAVORITE STYLES?
                </div>
                <div className={`${classes.stepDescription} mb-2`}>
                  Select up to Three
                </div>
                <div className="mb-2">
                  <FavouriteStyles
                    styles={enums?.styles}
                    handleFavouriteStyles={handleFavouriteStyles}
                    selectedStyles={selectedStyles}
                  />
                </div>
                <div className="mb-3">
                  <Alert
                    icon={false}
                    severity="error"
                    className={clsx({
                      'd-none': !isStylesError,
                    })}
                  >
                    {errorMessages.styles}
                  </Alert>
                </div>
              </div>
              <div>
                <div className={`${classes.stepTitle} mb-2`}>
                  3. WHAT INTERESTS YOU MOST ABOUT BEING AN AMBASSADOR?
                </div>
                <div className={`${classes.stepDescription} mb-2`}>
                  Select up to Three
                </div>
                <div className="mb-2">
                  <Interests
                    ambassadorInterests={ambassadorInterests}
                    handleInterests={handleInterests}
                    selectedInterests={selectedInterests}
                  />
                </div>
                <div className="mb-3">
                  <Alert
                    icon={false}
                    severity="error"
                    className={clsx({
                      'd-none': !isInterestsError,
                    })}
                  >
                    {errorMessages.interests}
                  </Alert>
                </div>
              </div>
              <div className="mb-3">
                <div className={`${classes.stepTitle} mb-2`}>
                  4. PLEASE ENTER LINKS TO YOUR DECOR-RELATED SOCIALS AND IMAGE
                  BOARDS.
                </div>
                <div className={`${classes.stepDescription} mb-2`}>
                  Examples: Website, Pinterest Boards, Instagram & Facebook
                  Pages, Tiktok Channel, etc
                </div>
                {fieldsArrData.map((field) => {
                  return (
                    <>
                      <TextInput
                        label="https://site.com"
                        value={field.value}
                        key={field.id}
                        onChange={(e) =>
                          handleFieldChanged(field.id, e.target.value)
                        }
                      />
                      {field.error ? (
                        <span className={classes.fieldError}>
                          {field.error}
                        </span>
                      ) : null}
                    </>
                  );
                })}
                <Button
                  type="button"
                  inline
                  size="sm"
                  className="mr-1"
                  onClick={handleAddField}
                  disabled={fieldsArrData.length >= 10}
                >
                  Add field
                </Button>
              </div>
              <div className="mb-3">
                <div className={`${classes.stepTitle} mb-2`}>
                  5. WERE YOU REFERRED BY A PIN THE LOOK AMBASSADOR?
                </div>
                <RadioGroup className={classes.radioGroupWrapper}>
                  <div className="d-flex align-items-center">
                    <FormControlLabel
                      label={
                        <div className="d-flex align-items-center text-color">
                          <span className="mr-1">Yes</span>
                        </div>
                      }
                      control={
                        <Field
                          name={requestModalFields.isReferred}
                          type="radio"
                          value={referredValues.yes}
                          component={RadioInputWrapper}
                        />
                      }
                    />
                    {values[requestModalFields.isReferred] ===
                    referredValues.yes ? (
                      <div>
                        <TextInput
                          label="Enter Name"
                          value={referredField}
                          onChange={(e) => setReferredField(e.target.value)}
                          className="mb-0"
                        />
                      </div>
                    ) : null}
                  </div>

                  <FormControlLabel
                    control={
                      <Field
                        name={requestModalFields.isReferred}
                        type="radio"
                        value={referredValues.no}
                        component={RadioInputWrapper}
                        defaultValue={referredValues.no}
                      />
                    }
                    label={
                      <div className="d-flex align-items-center text-color">
                        <span className="mr-1">No</span>
                      </div>
                    }
                  />
                </RadioGroup>
                {values.isReferred === referredValues.yes && referredError ? (
                  <span
                    className={clsx(classes.fieldError, classes.referredError)}
                  >
                    Please Enter a Name
                  </span>
                ) : null}
              </div>
              <div>
                <div className={`${classes.stepTitle} mb-2`}>
                  {requestModalPlaceholders.message}
                </div>
                <TextField
                  value={userExperience}
                  multiline
                  rows={4}
                  name={requestModalFields.message}
                  onChange={handleMessage}
                  variant="outlined"
                  color="primary"
                  placeholder="Enter your text"
                  fullWidth
                  className={clsx('mb-2', classes.experienceField)}
                />
                <div className="mb-3">
                  <Alert
                    icon={false}
                    severity="error"
                    className={clsx({
                      'd-none': !isUserExperienceError,
                    })}
                  >
                    {errorMessages.required}
                  </Alert>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="text-center">
                  <FinalFormSubmitButton size="lg" inline>
                    Submit
                  </FinalFormSubmitButton>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </BasicModal>
  );
};

RequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

RequestModal.defaultProps = {
  onSubmit: () => {},
};

export default RequestModal;
