import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import TextInput from 'components/inputs/TextInput/TextInput';
import classes from 'modules/account/profileAndPreferences/profile/Profile.module.scss';
import Button from 'components/ui/Button/Button';
import currentUserService from 'modules/currentUser/currentUserService';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import {
  toggleSuggestStyleQuizAction,
  toggleVerificationModalAction,
} from 'modules/layout/store/actions';
import customEvent from 'utils/customEvent';
import { OPEN_REGISTERED_MODAL } from 'constants/customEventNames';
import { updateCurrentUserDataAction } from 'modules/auth/store/actions';
import emailClasses from './EmailVerification.module.scss';

const VerificationRemindModal = () => {
  const dispatch = useDispatch();
  const { email, newUser } = useSelector((state) => state.auth.user) ?? {};
  const { verificationRemindModalOpen, suggestStyleQuiz } = useSelector(
    (state) => state.layout
  );

  const suggestStyleQuizAfterRegistration = useCallback(() => {
    if (suggestStyleQuiz) {
      setTimeout(() => {
        customEvent.trigger(OPEN_REGISTERED_MODAL);
      }, 0);
      dispatch(toggleSuggestStyleQuizAction(false));
    }
  }, [dispatch, suggestStyleQuiz]);

  const handleCloseModal = useCallback(() => {
    dispatch(toggleVerificationModalAction(false));
    suggestStyleQuizAfterRegistration();
    dispatch(updateCurrentUserDataAction({ newUser: false }));
  }, [dispatch, suggestStyleQuizAfterRegistration]);

  const handleSendLink = useCallback(async () => {
    try {
      await currentUserService.sendVerificationLink();
      successToastr(
        'Success',
        'Verification link successfully sent to your email'
      );
      dispatch(toggleVerificationModalAction(false));
      suggestStyleQuizAfterRegistration();
      dispatch(updateCurrentUserDataAction({ newUser: false }));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [dispatch, suggestStyleQuizAfterRegistration]);

  return (
    <BasicModal
      open={verificationRemindModalOpen}
      onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth
    >
      <div className="pb-2">
        {newUser && (
          <h3
            className="font-title text-lg text-uppercase text-center mb-1 primary-color"
            style={{ lineHeight: 1.4 }}
          >
            Thank you for registering
          </h3>
        )}
        <h3
          className="font-title text-lg text-uppercase text-center mb-3"
          style={{ lineHeight: 1.4 }}
        >
          {newUser ? (
            <>
              Please verify your email before proceeding. <br />
              We sent a confirmation email to:
            </>
          ) : (
            <>
              {' '}
              Before proceeding please <br /> verify your email
            </>
          )}
        </h3>
        <div className="px-3">
          <TextInput
            disabled
            label="Email"
            value={email}
            InputProps={{
              classes: { disabled: classes.disabledInput },
            }}
          />
        </div>
        {newUser && (
          <div className={emailClasses.verifyButton}>Please verify now</div>
        )}
        <p className={emailClasses.subInputText}>
          If you did not get a Verification Email, click on &quot;Get New
          Link&quot; below.
        </p>
        <div className="d-flex justify-content-center">
          <Button inline size="sm" onClick={handleSendLink}>
            Get New Link
          </Button>
        </div>
      </div>
    </BasicModal>
  );
};

export default VerificationRemindModal;
