import decorLover from 'assets/img/ambassador-page/decorLover.png';
import desingProffessional from 'assets/img/ambassador-page/desingProffessional.png';
import bloggerImg from 'assets/img/ambassador-page/bloggerImg.jpg';

import decorLoverTitle from 'assets/img/ambassador-page/decorLoverTitle.svg';
import desingProffessionaTitlel from 'assets/img/ambassador-page/desingProffessionalTitle.svg';
import skilledOnlineShopperTitle from 'assets/img/ambassador-page/skilledOnlineShopperTitle.svg';

export const describeYourselfOptions = [
  {
    title: 'DECOR ENTHUSIASTS',
    description:
      'You have a keen eye for design and love decorating your home and sharing tips with friends.',
    image: decorLover,
    titleSvg: decorLoverTitle,
  },
  {
    title: 'DESIGN PROFESSIONAL',
    description:
      'You are an interior designer, architect, or staging pro with an interest in sharing your designs on a new channel.',
    image: desingProffessional,
    titleSvg: desingProffessionaTitlel,
  },
  {
    title: 'INFLUENCER | BLOGGER',
    description:
      'You are an influencer or blogger with a passion for home decor and sharing design tips with others online.',
    image: bloggerImg,
    titleSvg: skilledOnlineShopperTitle,
  },
];

export const ambassadorInterests = [
  'Having a creative outlet to showcase my decorating skills',
  'Joining a passionate community of decor lovers',
  'Helping others find beautiful decor in their style',
  'Earning reward points and affiliate commissions',
  'Enhancing my brand as a designer or influencer',
];

export const requestModalFields = {
  from: 'from',
  subject: 'subject',
  describe_yourself: 'describe_yourself',
  styles: 'styles',
  interests: 'interests',
  message: 'message',
  arrayFields: 'links',
  isReferred: 'isReferred',
  referredName: 'referred_by',
};

export const errorMessages = {
  required: 'This field is required',
  styles: 'Please select up to three',
  interests: 'Please select up to three',
};

export const requestModalPlaceholders = {
  message:
    'Please tell us more about your interest/experience in home decor (REQUIRED):',
};

export const referredValues = {
  yes: 'yes',
  no: 'no',
};

export default {
  requestModalFields,
  errorMessages,
};
