import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Popover } from '@material-ui/core';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './InfoIconPopover.module.scss';

const sizeToClass = {
  xs: classes.xs,
  sm: classes.sm,
  md: classes.md,
  lg: classes.lg,
  xl: classes.xl,
};

const defaultIconProps = {
  size: 'sm',
};

const InfoIconPopover = ({
  size,
  fullWidth,
  children,
  iconProps,
  vertAlignment,
  customIcon,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        {...defaultIconProps}
        {...iconProps}
        onClick={handleOpen}
        type="button"
      >
        <SpriteIcon name={customIcon} size={size || 'sm'} />
      </IconButton>
      <BasicModal
        component={Popover}
        classes={{
          root: clsx(classes.popover, sizeToClass[size], {
            [classes.fullWidth]: fullWidth,
          }),
        }}
        anchorOrigin={{
          vertical: vertAlignment,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: vertAlignment === 'top' ? 'bottom' : 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </BasicModal>
    </>
  );
};

InfoIconPopover.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizeToClass)),
  iconProps: PropTypes.shape({}),
  vertAlignment: PropTypes.string,
  customIcon: PropTypes.string,
};

InfoIconPopover.defaultProps = {
  fullWidth: false,
  size: 'xs',
  iconProps: {
    ...defaultIconProps,
  },
  vertAlignment: 'bottom',
  customIcon: 'info',
};

export default InfoIconPopover;
