import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  accountTypes,
  showProductsOptions,
  signUpFormFields,
} from 'modules/auth/signUp/constants';
import signUpValidator from 'modules/auth/signUp/signUpValidator';
import { signUpAction } from 'modules/auth/store/actions';
import { transformFormValuesToRegisterData } from 'modules/auth/signUp/transformers';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import SignUpComponent from 'modules/auth/signUp/SignUpComponent';
import PropTypes from 'prop-types';

const SignUpContainer = ({ setStylesModalOpen }) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [formValues] = useState({
    [signUpFormFields.type]: accountTypes.personal,
    [signUpFormFields.showProducts]: showProductsOptions.all,
    [signUpFormFields.countryId]: 1,
  });

  const onSubmit = useCallback(
    async (values) => {
      const preparedUserData = transformFormValuesToRegisterData(values);
      setLoading(true);
      try {
        await dispatch(signUpAction(preparedUserData));
        successToastr('Success', 'You successfully registered');
        setStylesModalOpen(true);
      } catch (e) {
        const registeredEmailError =
          e.response.data?.validation?.email &&
          e.response.data?.validation?.email[0] &&
          'There is already an account with this email. Please login';
        errorToastr('Error', registeredEmailError || e.generalError);
        return {
          ...e.validationErrors,
        };
      } finally {
        setLoading(false);
      }
      return true;
    },
    [dispatch, setStylesModalOpen]
  );

  return (
    <>
      <SignUpComponent
        initialValues={formValues}
        validateForm={signUpValidator}
        onSubmit={onSubmit}
        loading={loading}
        title={location.state?.title}
      />
    </>
  );
};
SignUpContainer.propTypes = {
  setStylesModalOpen: PropTypes.func,
};
SignUpContainer.defaultProps = {
  setStylesModalOpen: () => {},
};

export default SignUpContainer;
