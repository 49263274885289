import useConnectToEchoServer from 'libs/echo/useConnectToEchoServer';
import channelsByName from 'libs/echo/channels';
import { MESSAGE_SENT } from 'libs/echo/events';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateUsersLibraryAction } from 'modules/users/store/actions';
import successToastr from 'libs/toastr/successToastr';
import currentUserService from 'modules/currentUser/currentUserService';
import { useCallback, useEffect, useRef } from 'react';
import {
  setConversationOnTopAction,
  updateConversationsAction,
  updateUnreadConversationsAction,
} from 'modules/conversation/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import messagesService from 'modules/dashboard/messages/messagesService';

const ConversationsSubscribe = ({ user }) => {
  const dispatch = useDispatch();

  const usersLibrary = useSelector((state) => state.users.library);
  const { currentConversationId, conversationsList } = useSelector(
    (state) => state.conversation
  );

  const currentConversationIdRef = useRef();
  const conversationListRef = useRef();

  useEffect(() => {
    conversationListRef.current = conversationsList;
  }, [conversationsList]);

  useEffect(() => {
    currentConversationIdRef.current = currentConversationId;
  }, [currentConversationId]);

  const handleReceivedMessage = useCallback(
    async (data) => {
      const {
        message: { conversationId, senderId, body: messageBody, sentAt },
      } = data;

      if (
        conversationId !== currentConversationIdRef.current &&
        senderId !== user.id
      ) {
        let sender = null;

        if (!usersLibrary[senderId]) {
          try {
            sender = await currentUserService.getUserById(senderId);
            dispatch(updateUsersLibraryAction([sender]));
          } catch (e) {
            errorToastr('Error', e.message);
          }
        } else {
          sender = usersLibrary[senderId];
        }

        try {
          const conversation = await messagesService.getConversationById(
            conversationId
          );
          dispatch(updateUnreadConversationsAction(conversation));
          if (
            conversationListRef?.current.some((id) => id === conversation.id)
          ) {
            dispatch(setConversationOnTopAction(conversationId, sentAt));
          } else {
            dispatch(updateConversationsAction([conversation], { top: true }));
          }
        } catch (e) {
          errorToastr('Error', e.message);
        }

        const fullName =
          sender?.displayName ?? `${sender.firstName} ${sender.lastName}`;
        successToastr(`New message from ${fullName}`, messageBody);
      }
    },
    [
      currentConversationIdRef,
      usersLibrary,
      dispatch,
      user,
      conversationListRef,
    ]
  );

  useConnectToEchoServer({
    channelName: channelsByName.conversationsByUser(user.id),
    events: {
      [MESSAGE_SENT]: handleReceivedMessage,
    },
    namespace: 'DNC.Messenger.Laravel.Events.Broadcast',
  });

  return null;
};

ConversationsSubscribe.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default ConversationsSubscribe;
