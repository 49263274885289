export const notificationTypes = {
  youReMatch: 'match',
  youReAmbassador: 'ambassador',
  recommendationsReady: 'recommendation',
  imageApproved: 'approvedImage',
  lookBoardApproved: 'approvedLookBoard',
  lookBoardRejected: 'rejectedLookBoard',
  productApproved: 'approvedProduct',
  videoDeactivated: 'videoDeactivated',
  videoActivated: 'videoActivated',
};

export const queryTypes = {
  youReMatch: '/request-the-look/ambassador-match/',
  lookBoardApproved: '/look-board/',
  recommendationsReady: '/request-the-look/recommendations/',
  youReAmbassador: '/dashboard/ambassador-management/',
  imageApproved: '/dashboard/images',
  myLookBoards: '/dashboard/look-boards',
};
