import showcaseService from 'modules/showcase/showcaseService';
import {
  SHOWCASE_SET_PREVIEW_URL_KEY,
  SHOWCASE_UPDATE_CURRENT,
  SHOWCASE_UPDATE_IMAGES_DND_STATE,
  SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE,
  SHOWCASE_UPDATE_LOOKBOARDS_LIBRARY,
  SHOWCASE_UPDATE_OWN_COLOR_BANNERS,
  SHOWCASE_UPDATE_OWN_IMAGE_BANNERS,
} from 'modules/showcase/store/constants';
import { localStorageKeys } from 'modules/app/constants';
import { updateCurrentUserDataAction } from 'modules/auth/store/actions';

const updateShowcaseDataAction = (payload) => ({
  type: SHOWCASE_UPDATE_CURRENT,
  payload,
});

export const syncShowcaseAction = (cancelToken) => async (dispatch) => {
  try {
    const currentShowcase = await showcaseService.getShowcase({ cancelToken });
    dispatch(updateShowcaseDataAction(currentShowcase));
  } catch (e) {
    // eslint-disable-next-line no-underscore-dangle
    if (!e.cancel.__CANCEL__) {
      if (e.response.status === 404) {
        const newShowcase = await showcaseService.createShowcase('GetTheLook');
        dispatch(updateShowcaseDataAction(newShowcase));
      } else {
        throw new Error(e.message);
      }
    }
  }
};

const buildEmptyCells = (count, startNumber) => {
  const arrayFromColumnCount = Array.from(
    Array(count),
    (_, i) => i + startNumber + 1
  );

  return arrayFromColumnCount.map((currItem) => ({
    id: `item-${currItem}`,
    item: null,
  }));
};

export const buildFeaturedItemsDndState = (initialItemsToShow) => (
  dispatch,
  getState
) => {
  const {
    showcase: {
      current: { featuredImages },
    },
  } = getState();

  const columns = featuredImages.map((currentValue, index) => ({
    id: `item-${index + 1}`,
    item: Number(currentValue),
  }));

  let emptyColumns = [];

  if (featuredImages.length < initialItemsToShow) {
    const cellsToCreate = initialItemsToShow - featuredImages.length;
    emptyColumns = buildEmptyCells(cellsToCreate, featuredImages.length);
  }

  dispatch({
    type: SHOWCASE_UPDATE_IMAGES_DND_STATE,
    payload: [...columns, ...emptyColumns],
  });
};

export const buildFeaturedLookBoardsDndState = (initialItemsToShow) => (
  dispatch,
  getState
) => {
  const {
    showcase: {
      current: { featuredLookBoards },
    },
  } = getState();

  const columns = featuredLookBoards.map((currentValue, index) => ({
    id: `item-${index + 1}`,
    item: Number(currentValue),
  }));

  let emptyColumns = [];

  if (featuredLookBoards.length < initialItemsToShow) {
    const cellsToCreate = initialItemsToShow - featuredLookBoards.length;
    emptyColumns = buildEmptyCells(cellsToCreate, featuredLookBoards.length);
  }

  dispatch({
    type: SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE,
    payload: [...columns, ...emptyColumns],
  });
};

export const toggleShowcaseActivationAction = (value) => async (dispatch) => {
  const updateShowcase = await showcaseService.toggleActivation(value);
  dispatch({ type: SHOWCASE_UPDATE_CURRENT, payload: updateShowcase });
};

export const updateShowcasePageDataAction = (data) => async (dispatch) => {
  const updateShowcase = await showcaseService.updateShowcase(data);
  dispatch({ type: SHOWCASE_UPDATE_CURRENT, payload: updateShowcase });
  dispatch(updateCurrentUserDataAction({ shareUrl: updateShowcase.shareUrl }));
};

export const updateShowcaseBannerAction = (data) => async (dispatch) => {
  const updateShowcase = await showcaseService.updateBanner(data);
  dispatch({ type: SHOWCASE_UPDATE_CURRENT, payload: updateShowcase });
};

export const updateShowcaseDescriptionAction = (description) => async (
  dispatch
) => {
  const updateShowcase = await showcaseService.updateDescription(description);
  dispatch({ type: SHOWCASE_UPDATE_CURRENT, payload: updateShowcase });
};

export const updateShowcaseStylesAction = (styles) => async (dispatch) => {
  const updateShowcase = await showcaseService.updateStyles(styles);
  dispatch({ type: SHOWCASE_UPDATE_CURRENT, payload: updateShowcase });
};

export const updateFeaturedImagesAction = (images) => async (dispatch) => {
  const updateShowcase = await showcaseService.updateImages(images);
  dispatch({ type: SHOWCASE_UPDATE_CURRENT, payload: updateShowcase });
};

export const updateVideoLinkAction = (url) => async (dispatch) => {
  const updateShowcase = await showcaseService.updateVideoLink(url);
  dispatch({ type: SHOWCASE_UPDATE_CURRENT, payload: updateShowcase });
};

export const updateFeaturedLookBoardsAction = (lookBoards) => async () => {
  await showcaseService.updateFeaturedLookBoards(lookBoards);
};

export const populateOwnBannersFromStorageAction = () => (dispatch) => {
  const colorBanners = showcaseService.getOwnColorBannersFromStorage();
  const imageBanners = showcaseService.getOwnImageBannersFromStorage();

  if (colorBanners) {
    dispatch({
      type: SHOWCASE_UPDATE_OWN_COLOR_BANNERS,
      payload: colorBanners,
    });
  }

  if (imageBanners) {
    dispatch({
      type: SHOWCASE_UPDATE_OWN_IMAGE_BANNERS,
      payload: imageBanners,
    });
  }
};

export const addOwnColorBannerAction = (banner) => (dispatch, getState) => {
  const {
    showcase: { ownColorBanners },
  } = getState();
  const existedColor = ownColorBanners.find(
    ({ color }) => color === banner.color
  );

  if (!existedColor) {
    showcaseService.saveBannerToStorage(
      localStorageKeys.ownColorBanners,
      banner
    );
    dispatch({ type: SHOWCASE_UPDATE_OWN_COLOR_BANNERS, payload: [banner] });
  }
};

export const addOwnImageBannerAction = (banner) => (dispatch, getState) => {
  const {
    showcase: { ownImageBanners },
  } = getState();
  const existedImage = ownImageBanners.find(({ id }) => id === banner.id);

  if (!existedImage) {
    showcaseService.saveBannerToStorage(
      localStorageKeys.ownImageBanners,
      banner
    );
    dispatch({ type: SHOWCASE_UPDATE_OWN_IMAGE_BANNERS, payload: [banner] });
  }
};

export const setPreviewUrlKeyAction = (urlKey) => (dispatch) => {
  dispatch({ type: SHOWCASE_SET_PREVIEW_URL_KEY, payload: urlKey });
};

export const updateLookboardsLibraryAction = (list) => (dispatch) => {
  dispatch({ type: SHOWCASE_UPDATE_LOOKBOARDS_LIBRARY, payload: list });
};

export const addEmptyImageAction = (count = 1) => (dispatch, getState) => {
  const {
    showcase: {
      dndState: { featuredImages },
    },
  } = getState();

  const lastItem = featuredImages[featuredImages.length - 1];
  const lastItemNumber = lastItem.id.split('-')[1];

  const newCells = buildEmptyCells(count, Number(lastItemNumber));

  dispatch({
    type: SHOWCASE_UPDATE_IMAGES_DND_STATE,
    payload: [...featuredImages, ...newCells],
  });
};

export const addEmptyLookBoardAction = (count) => (dispatch, getState) => {
  const {
    showcase: {
      dndState: { featuredLookBoards },
    },
  } = getState();

  const lastItem = featuredLookBoards[featuredLookBoards.length - 1];
  const lastItemNumber = lastItem.id.split('-')[1];

  const newCells = buildEmptyCells(count, Number(lastItemNumber));

  dispatch({
    type: SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE,
    payload: [...featuredLookBoards, ...newCells],
  });
};

export const deleteEmptyImageAction = (columnId) => (dispatch, getState) => {
  const {
    showcase: {
      dndState: { featuredImages },
    },
  } = getState();

  const filteredFeaturedImages = featuredImages.filter(
    ({ id }) => id !== columnId
  );

  dispatch({
    type: SHOWCASE_UPDATE_IMAGES_DND_STATE,
    payload: filteredFeaturedImages,
  });
};

export const deleteEmptyLookBoardAction = (columnId) => (
  dispatch,
  getState
) => {
  const {
    showcase: {
      dndState: { featuredLookBoards },
    },
  } = getState();

  const filteredFeaturedLookBoards = featuredLookBoards.filter(
    ({ id }) => id !== columnId
  );

  dispatch({
    type: SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE,
    payload: filteredFeaturedLookBoards,
  });
};

export const deleteImageAction = (columnId) => async (dispatch, getState) => {
  const {
    showcase: {
      current,
      dndState: { featuredImages },
    },
  } = getState();

  const newDndState = [...featuredImages];

  const targetDropColumn = newDndState.find(({ id }) => id === columnId);

  if (targetDropColumn) {
    targetDropColumn.item = null;
  }

  const updatedFeaturedImages = newDndState
    .filter(({ item }) => Boolean(item))
    .map(({ item }) => Number(item));

  dispatch({
    type: SHOWCASE_UPDATE_IMAGES_DND_STATE,
    payload: newDndState,
  });

  dispatch({
    type: SHOWCASE_UPDATE_CURRENT,
    payload: { ...current, featuredImages: updatedFeaturedImages },
  });
  await showcaseService.updateImages(updatedFeaturedImages);
};

export const deleteLookBoardAction = (columnId) => async (
  dispatch,
  getState
) => {
  const {
    showcase: {
      current,
      dndState: { featuredLookBoards },
    },
  } = getState();

  const newDndState = [...featuredLookBoards];

  const targetDropColumn = newDndState.find(({ id }) => id === columnId);

  if (targetDropColumn) {
    targetDropColumn.item = null;
  }

  const updatedFeaturedLookBoards = newDndState
    .filter(({ item }) => Boolean(item))
    .map(({ item }) => Number(item));

  dispatch({
    type: SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE,
    payload: newDndState,
  });

  dispatch({
    type: SHOWCASE_UPDATE_CURRENT,
    payload: { ...current, featuredLookBoards: updatedFeaturedLookBoards },
  });

  await showcaseService.updateFeaturedLookBoards(updatedFeaturedLookBoards);
};

export const dropImageAction = ({ source, destination, draggableId }) => async (
  dispatch,
  getState
) => {
  if (destination.droppableId === source.droppableId) return;

  const {
    showcase: {
      current,
      dndState: { featuredImages },
    },
  } = getState();

  const sourceDroppableColumnId = source.droppableId.split('_')[1];
  const destDroppableColumnId = destination.droppableId.split('_')[1];

  const newDndState = [...featuredImages];

  const sourceDropColumn = newDndState.find(
    ({ id }) => id === sourceDroppableColumnId
  );
  const destDropColumn = newDndState.find(
    ({ id }) => id === destDroppableColumnId
  );

  if (destDropColumn) {
    if (sourceDropColumn) {
      sourceDropColumn.item = destDropColumn.item;
    }

    destDropColumn.item = Number(draggableId);
  }

  const updatedFeaturedImages = newDndState
    .filter(({ item }) => Boolean(item))
    .map(({ item }) => Number(item));

  dispatch({
    type: SHOWCASE_UPDATE_IMAGES_DND_STATE,
    payload: newDndState,
  });

  dispatch({
    type: SHOWCASE_UPDATE_CURRENT,
    payload: { ...current, featuredImages: updatedFeaturedImages },
  });

  await showcaseService.updateImages(updatedFeaturedImages);
};

export const dropLookBoardAction = ({
  source,
  destination,
  draggableId,
}) => async (dispatch, getState) => {
  if (destination.droppableId === source.droppableId) return;

  const {
    showcase: {
      current,
      dndState: { featuredLookBoards },
    },
  } = getState();

  const sourceDroppableColumnId = source.droppableId.split('_')[1];
  const destDroppableColumnId = destination.droppableId.split('_')[1];

  const newDndState = [...featuredLookBoards];

  const sourceDropColumn = newDndState.find(
    ({ id }) => id === sourceDroppableColumnId
  );
  const destDropColumn = newDndState.find(
    ({ id }) => id === destDroppableColumnId
  );

  if (destDropColumn) {
    if (sourceDropColumn) {
      sourceDropColumn.item = destDropColumn.item;
    }

    destDropColumn.item = Number(draggableId);
  }

  const updatedFeaturedLookBoards = newDndState
    .filter(({ item }) => Boolean(item))
    .map(({ item }) => Number(item));

  dispatch({
    type: SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE,
    payload: newDndState,
  });

  dispatch({
    type: SHOWCASE_UPDATE_CURRENT,
    payload: { ...current, featuredLookBoards: updatedFeaturedLookBoards },
  });

  await showcaseService.updateFeaturedLookBoards(updatedFeaturedLookBoards);
};
