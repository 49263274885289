const MuiSpeedDial = {
  root: {
    // position: 'absolute',
  },
  actions: {
    backgroundColor: `white`,
    borderRadius: '4px',
    // marginTop: '-48px !important',
    marginTop: '0px !important',
    paddingTop: '5px !important',
  },
  actionsClosed: {
    backgroundColor: `transparent`,
  },
  fab: {
    backgroundColor: `transparent !important`,
  },
};

export default MuiSpeedDial;
