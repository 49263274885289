import {
  FORCE_REGISTER_RTL_MODAL,
  LAYOUT_SUGGEST_STYLE_QUIZ,
  LAYOUT_TOGGLE_ADD_IMAGE_MODAL,
  LAYOUT_TOGGLE_CHOOSE_METHOD_MODAL,
  LAYOUT_TOGGLE_HOW_IT_WORKS_MODAL,
  LAYOUT_TOGGLE_HOW_IT_WORKS_VIDEO_MODAL,
  LAYOUT_TOGGLE_PIN_MEDIA_MODAL,
  LAYOUT_TOGGLE_PREFERENCES_REMIND_MODAL,
  LAYOUT_TOGGLE_QUIZ_VIEWED,
  LAYOUT_TOGGLE_STYLE_QUIZ,
  LAYOUT_TOGGLE_TERMS_MODAL,
  LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL,
  LAYOUT_TOGGLE_VERIFICATION_MODAL,
  LAYOUT_TOGGLE_YOUTUBE_VIDEO_MODAL,
  SET_STYLE_QUIZ_DATA,
} from 'modules/layout/store/constants';

export const toggleAddImageModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_ADD_IMAGE_MODAL, payload: isOpen });
};

export const toggleChooseMethodModalAction = (data) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_CHOOSE_METHOD_MODAL, payload: data });
};

export const togglePinMediaModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_PIN_MEDIA_MODAL, payload: isOpen });
};

export const toggleQuizViewedAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_QUIZ_VIEWED, payload: isOpen });
};

export const toggleUnderConstructionModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL, payload: isOpen });
};

export const toggleTermsModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_TERMS_MODAL, payload: isOpen });
};

export const toggleHowItWorksModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_HOW_IT_WORKS_MODAL, payload: isOpen });
};

export const toggleVerificationModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_VERIFICATION_MODAL, payload: isOpen });
};

export const toggleSuggestStyleQuizAction = (isSuggest) => ({
  type: LAYOUT_SUGGEST_STYLE_QUIZ,
  payload: isSuggest,
});

export const toggleStyleQuizResultModalAction = (isShown) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_STYLE_QUIZ, payload: isShown });
};

export const setStyleQuizResultModalAction = (data) => (dispatch) => {
  dispatch({ type: SET_STYLE_QUIZ_DATA, payload: data });
};

export const toggleHowItWorksVideoModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_HOW_IT_WORKS_VIDEO_MODAL, payload: isOpen });
};

export const togglePreferencesRemindModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_PREFERENCES_REMIND_MODAL, payload: isOpen });
};

export const toggleYoutubeVideoModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_YOUTUBE_VIDEO_MODAL, payload: isOpen });
};

export const toggleForceRegisterModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: FORCE_REGISTER_RTL_MODAL, payload: isOpen });
};
