import messengerConnect from '@rznv/core/dist/index';
import appConfig from 'constants/appConfig';
import errorToastr from 'libs/toastr/errorToastr';
import authService from 'modules/auth/authService';

const errorHandler = (error) => {
  // throw error
  // eslint-disable-next-line
  errorToastr(`Error`, error);
};

const initialConfig = {
  restApiUrl: appConfig.apiUrl,
  // socketUrl: appConfig.apiUrl,
  onError: errorHandler,
};

const messengerApi = messengerConnect(initialConfig);

export const attachTokenToMessengerApiInstance = () => {
  messengerApi.setConfig({
    ...initialConfig,
    token: authService.getAccessToken(),
  });
};

export default messengerApi;
