import currentUserService from 'modules/currentUser/currentUserService';
import { AUTH_LOGIN, AUTH_LOGOUT } from 'modules/auth/store/constants';
import authService from 'modules/auth/authService';

export const updateUserProfileAction = (profileData) => async (dispatch) => {
  const updatedUser = await currentUserService.updateProfile(profileData);
  dispatch({ type: AUTH_LOGIN, payload: updatedUser });
};

export const confirmUserAction = (profileData) => async (dispatch) => {
  const updatedUser = await currentUserService.confirmDetails(profileData);
  dispatch({ type: AUTH_LOGIN, payload: updatedUser });
};

export const deactivateAccountAction = () => async (dispatch) => {
  await currentUserService.deactivateUser();
  dispatch({ type: AUTH_LOGOUT });
  authService.clearAuthTokens();
};

export const updateUserPreferencesAction = (preferences) => (
  dispatch,
  getState
) => {
  const {
    auth: { user },
  } = getState();
  const updatedUser = {
    ...user,
    preferences,
  };
  dispatch({ type: AUTH_LOGIN, payload: updatedUser });
};

export const saveStyleQuizAsPreferencesAction = (filterValues) => async (
  dispatch,
  getState
) => {
  const {
    app: {
      enums: { colors: colorOptions },
    },
    auth: {
      user: { ambassador },
    },
  } = getState();
  const { vibes, styles, subStyles, roomTypes, colors } = filterValues;
  let currentPrefs = {};

  if (vibes.length) {
    const {
      result: { preferences },
    } = await currentUserService.updateVibes(vibes);
    currentPrefs = preferences;
  }
  if (styles.length) {
    const {
      result: { preferences },
    } = await currentUserService.updateStyles(styles);
    currentPrefs = preferences;
  }
  if (subStyles.length) {
    const preparedData = subStyles.reduce((accum, currValue) => {
      /* eslint-disable no-param-reassign */
      accum[currValue] = 1;

      return accum;
    }, {});

    const {
      result: { preferences },
    } = await currentUserService.updateSubStyles(preparedData);
    currentPrefs = preferences;
  }
  if (roomTypes.length || !ambassador) {
    const {
      result: { preferences },
    } = await currentUserService.updateRoomTypes(roomTypes);
    currentPrefs = preferences;
  }
  if (colors.length || !ambassador) {
    const col = Object.values(colorOptions)
      .filter((color) => colors.includes(color.color_group_id))
      .map((color) => color.id);
    const {
      result: { preferences },
    } = await currentUserService.updateColors(col);
    currentPrefs = preferences;
  }
  dispatch(updateUserPreferencesAction(currentPrefs));
};

// TODO: Update action logic for backend integration
export const changeEmailAction = (data) => async (dispatch, getState) => {
  const {
    auth: { user },
  } = getState();
  await currentUserService.changeEmail(data);
  dispatch({
    type: AUTH_LOGIN,
    payload: {
      ...user,
      email: data.newEmail,
      hasVerifiedEmail: 0,
    },
  });
};
