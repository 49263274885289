import React from 'react';
import { useSelector } from 'react-redux';
import ConversationsSubscribe from 'components/ConversationsSubscribe';

const ConversationController = () => {
  const user = useSelector((state) => state.auth.user);

  if (user?.status !== 'complete' || !user) {
    return null;
  }

  return <ConversationsSubscribe user={user} />;
};

export default ConversationController;
