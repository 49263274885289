export const CTL_UPDATE_DND_STATE = 'CTL_UPDATE_DND_STATE';
export const CTL_SET_IMAGES = 'CTL_SET_IMAGES';
export const CTL_UPDATE_IMAGES = 'CTL_UPDATE_IMAGES';
export const CTL_SET_PRODUCTS = 'CTL_SET_PRODUCTS';
export const CTL_UPDATE_PRODUCTS = 'CTL_UPDATE_PRODUCTS';
export const CTL_INSERT_PRODUCT_TO_TOP = 'CTL_INSERT_PRODUCT_TO_TOP';
export const CTL_SET_STEP = 'CTL_SET_STEP';
export const CTL_SET_SELECTED_TEMPLATE = 'CTL_SET_SELECTED_TEMPLATE';
export const CTL_UPDATE_LOOK_BOARD_DATA = 'CTL_UPDATE_LOOK_BOARD_DATA';
export const CTL_RESET_CANVAS = 'CTL_RESET_CANVAS';
export const CTL_UPDATE_II_SEARCH_PARAMS = 'CTL_UPDATE_II_SEARCH_PARAMS';
export const CTL_UPDATE_PRODUCT_SEARCH_PARAMS =
  'CTL_UPDATE_PRODUCT_SEARCH_PARAMS';
export const CTL_SET_FORM_VALUES = 'CTL_SET_FORM_VALUES';
export const CTL_SET_LOOK_BOARD_STATUS = 'CTL_SET_LOOK_BOARD_STATUS';
export const CTL_RESET_STATE = 'CTL_RESET_STATE';
export const CTL_UPDATE_STATE = 'CTL_UPDATE_STATE';
export const CTL_UPDATE_IMAGE_FILTER_VALUES = 'CTL_UPDATE_IMAGE_FILTER_VALUES';
export const CTL_RESET_IMAGE_FILTER_VALUES = 'CTL_RESET_IMAGE_FILTER_VALUES';
export const CTL_UPDATE_PRODUCT_FILTER_VALUES =
  'CTL_UPDATE_PRODUCT_FILTER_VALUES';
export const CTL_RESET_PRODUCT_FILTER_VALUES =
  'CTL_RESET_PRODUCT_FILTER_VALUES';
export const CTL_SET_EDIT_STATE = 'CTL_SET_EDIT_STATE';
