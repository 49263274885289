import {
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from 'modules/auth/store/constants';
import authService from 'modules/auth/authService';
import currentUserService from 'modules/currentUser/currentUserService';
import errorToastr from 'libs/toastr/errorToastr';
import {
  updateFilterValuesAction,
  updateGTLProductFilters,
} from 'modules/getTheLook/store/actions';
import { attachTokenToMessengerApiInstance } from 'libs/messenger';
import getTheLookService from 'modules/getTheLook/getTheLookService';
import { setUnreadConversationsAction } from 'modules/conversation/store/actions';
import moment from 'moment';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import { routesByName } from 'constants/routes';
import findObjectById from '../../../utils/findObjectById';

export const toggleDailyVerificationModalAction = (user, options) => (
  dispatch
) => {
  if (user.hasVerifiedEmail) {
    return;
  }

  const lastTimeWasShown = authService.getVerifyModalWasShown();

  if (!lastTimeWasShown || options.newUser) {
    authService.setVerifyModalWasShown(moment().unix());
    if (
      window.location.pathname === routesByName.ambassador.index ||
      window.location.pathname === routesByName.styleQuiz
    ) {
      return;
    }
    dispatch(toggleVerificationModalAction(true));
  } else {
    const dayInSeconds = 86400;

    if (dayInSeconds - (moment().unix() - lastTimeWasShown) < 0) {
      authService.setVerifyModalWasShown(moment().unix());
      dispatch(toggleVerificationModalAction(true));
    }
  }
};

export const setDefaultFilters = async (dispatch, getState) => {
  try {
    let filters = await getTheLookService.getDefaultFilters();
    const { subStyles, styles, colors } = filters;
    const {
      app: { enums },
    } = getState();
    const preparedSubStyles = subStyles
      .map((subStyleId) => findObjectById(subStyleId, enums.subStyles))
      .filter(({ styleId }) => styles.includes(styleId))
      .reduce((accum, { id: currValue }) => {
        /* eslint-disable no-param-reassign */
        accum[currValue] = 1;
        return accum;
      }, {});

    const preparedColors = colors
      .map((colorId) => enums.colors[colorId])
      .map((color) => enums.colorGroups[color.color_group_id])
      .map((group) => group.id)
      .filter((groupId, index, array) => array.lastIndexOf(groupId) === index);

    filters = {
      ...filters,
      subStyles: preparedSubStyles,
      colors: preparedColors,
    };
    dispatch(updateFilterValuesAction(filters));
  } catch (e) {
    errorToastr('Error', e.message);
  }
};

export const syncCurrentUserAction = (options = {}) => async (
  dispatch,
  getState
) => {
  const currentUser = await currentUserService.getCurrentUser();
  attachTokenToMessengerApiInstance();
  dispatch({
    type: AUTH_LOGIN,
    payload: { ...currentUser, newUser: options.newUser ?? false },
  });

  // dispatch(toggleDailyVerificationModalAction(currentUser));
  await dispatch(setUnreadConversationsAction());

  const { preferences } = currentUser;

  if (preferences) {
    const filters = {
      styles: preferences.styles ?? [],
      subStyles: preferences.subStyles ?? {},
      roomTypes: preferences.roomTypes ?? [],
      colors: Object.values(preferences.colors) ?? [],
    };
    dispatch(updateFilterValuesAction(filters));
    dispatch(updateGTLProductFilters(filters));
  } else {
    await setDefaultFilters(dispatch, getState);
  }
};

export const initAuthAction = () => async (dispatch, getState) => {
  const token = authService.getAccessToken();
  if (token) {
    try {
      await dispatch(syncCurrentUserAction());
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  } else {
    await setDefaultFilters(dispatch, getState);
  }
  dispatch({ type: AUTH_INIT });
};

export const loginAction = (formValues, options) => async (dispatch) => {
  await authService.login(formValues);
  await dispatch(syncCurrentUserAction(options));
};

export const loginByInvitationAction = (formValues) => async (dispatch) => {
  await authService.loginByInvitation(formValues);
  await dispatch(syncCurrentUserAction());
};

export const signUpAction = (formValues) => async (dispatch) => {
  await authService.signUp(formValues);
  const { email, password } = formValues;
  // eslint-disable-next-line no-return-await
  return await dispatch(loginAction({ email, password }, { newUser: true }));
};

export const verifyEmailAction = (userId, token) => async (
  dispatch,
  getState
) => {
  await authService.verifyEmail(userId, token);
  try {
    const currentUser = await currentUserService.getCurrentUser();
    dispatch({ type: AUTH_LOGIN, payload: currentUser });
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
  await setDefaultFilters(dispatch, getState);
};

export const logoutAction = () => async (dispatch) => {
  await authService.logout();
  dispatch({ type: AUTH_LOGOUT });
};

export const refreshCurrentUserDataAction = () => async (dispatch) => {
  const currentUser = await currentUserService.getCurrentUser();
  dispatch({ type: AUTH_LOGIN, payload: currentUser });
};

export const updateCurrentUserDataAction = (payload) => (
  dispatch,
  getState
) => {
  const {
    auth: { user },
  } = getState();

  dispatch({ type: AUTH_LOGIN, payload: { ...user, ...payload } });
};
