import {
  SET_CONVERSATIONS,
  UPDATE_MESSAGES_BY_CONVERSATION_ID,
  SET_MESSAGES_BY_CONVERSATION_ID,
  SET_META_BY_CONVERSATION_ID,
  PUSH_MESSAGE_BY_ID,
  UPDATE_CONVERSATIONS,
  SET_CONTACT_LIST,
  UPDATE_CONTACT_LIST,
  SET_UNREAD_CONVERSATIONS,
  UPDATE_UNREAD_CONVERSATIONS,
  SET_CURRENT_CONVERSATION,
  REMOVE_UNREAD_CONVERSATION,
  SET_CONVERSATION_ON_TOP,
} from 'modules/conversation/store/constants';
import transformArrayToMap from 'utils/transformArrayToMap';
import messagesService from 'modules/dashboard/messages/messagesService';
import { updateUsersLibraryAction } from 'modules/users/store/actions';
import currentUserService from 'modules/currentUser/currentUserService';

export const setMetaByConversationId = (conversationId, hasMore) => ({
  type: SET_META_BY_CONVERSATION_ID,
  payload: { [conversationId]: { hasMore } },
});

export const removeUnreadConversationAction = (id) => ({
  type: REMOVE_UNREAD_CONVERSATION,
  payload: id,
});

export const readConversationAction = ({ id, timestamp }) => async (
  dispatch
) => {
  const { result } = await messagesService.readConversation({
    id,
    timestamp,
  });

  if (result) {
    dispatch(removeUnreadConversationAction(id));
  }
};

export const setConversationsAction = (conversations) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      user: { id: currentUserId },
    },
  } = getState();

  const listWithInterlocutor = conversations.map((convers) => {
    const interlocutor = convers.participants.find(({ userId }) => {
      return userId !== currentUserId;
    });
    return { ...convers, interlocutor };
  });

  const transformedListWithInterlocutor = transformArrayToMap(
    listWithInterlocutor
  );

  const conversationsList = conversations.map(({ id }) => id);

  dispatch({
    type: SET_CONVERSATIONS,
    payload: {
      transformedConversations: transformedListWithInterlocutor,
      conversationsList,
    },
  });
};

export const updateConversationsAction = (conversations, options = {}) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      user: { id: currentUserId },
    },
  } = getState();

  const listWithInterlocutor = conversations.map((convers) => {
    const interlocutor = convers.participants.find(({ userId }) => {
      return userId !== currentUserId;
    });
    return { ...convers, interlocutor };
  });

  const transformedListWithInterlocutor = transformArrayToMap(
    listWithInterlocutor
  );

  const conversationsList = conversations.map(({ id }) => id);

  dispatch({
    type: UPDATE_CONVERSATIONS,
    payload: {
      transformedConversations: transformedListWithInterlocutor,
      conversationsList,
      options,
    },
  });
};

export const setMessagesByIdAction = (conversationId, messageList, hasMore) => (
  dispatch,
  getState
) => {
  const {
    conversation: { conversations },
  } = getState();

  const transformedMessages = {
    [conversationId]: messageList.map((message) => ({
      ...message,
      type: 'text',
    })),
  };

  const { lastMessage } = conversations[conversationId];

  dispatch({
    type: SET_MESSAGES_BY_CONVERSATION_ID,
    payload: transformedMessages,
  });

  dispatch(
    readConversationAction({
      id: conversationId,
      timestamp: lastMessage.updatedAt,
    })
  );
  dispatch(setMetaByConversationId(conversationId, hasMore));
};

export const pushMessageByIdAction = (id, message) => (dispatch, getState) => {
  const {
    conversation: { messagesByConversationId },
  } = getState();

  const isMessageExist = messagesByConversationId[id].find(
    (messageItem) => messageItem.id === message.id
  );

  if (isMessageExist) {
    return;
  }

  const validMessage = { ...message, type: 'text' };

  dispatch({
    type: PUSH_MESSAGE_BY_ID,
    payload: { id, message: validMessage },
  });
  dispatch(readConversationAction({ id, timestamp: message.updatedAt }));
};

export const updateMessagesByIdAction = ({
  conversationId,
  messages,
  hasMore,
}) => (dispatch) => {
  const transformedMessages = messages.map((message) => ({
    ...message,
    type: 'text',
  }));

  dispatch({
    type: UPDATE_MESSAGES_BY_CONVERSATION_ID,
    payload: { id: conversationId, messages: transformedMessages },
  });

  dispatch(setMetaByConversationId(conversationId, hasMore));
};

export const setContactListAction = (users) => ({
  type: SET_CONTACT_LIST,
  payload: users,
});

export const updateContactListAction = (users) => ({
  type: UPDATE_CONTACT_LIST,
  payload: users,
});

export const setUnreadConversationsAction = () => async (
  dispatch,
  getState
) => {
  const {
    auth: {
      user: { id: currentUserId },
    },
    users: { library: usersLibrary },
  } = getState();

  const unreadConversations = await messagesService.getUnreadConversations();

  if (unreadConversations.length > 0) {
    let interlocutorsId = [];

    const listWithInterlocutor = unreadConversations.map((convers) => {
      const interlocutor = convers.participants.find(({ userId }) => {
        return userId !== currentUserId;
      });
      interlocutorsId.push(interlocutor.userId);
      return { ...convers, interlocutor };
    });

    const transformedConversations = transformArrayToMap(listWithInterlocutor);
    const conversationsIds = unreadConversations.map(({ id }) => id);

    interlocutorsId = interlocutorsId.filter((id) => !usersLibrary[id]);

    if (interlocutorsId.length > 0) {
      const users = await currentUserService.getUsersById([
        ...new Set(interlocutorsId),
      ]);

      dispatch(updateUsersLibraryAction(users));
    }

    dispatch({
      type: SET_UNREAD_CONVERSATIONS,
      payload: {
        unreadConversations: conversationsIds,
        unreadConversationsById: transformedConversations,
      },
    });
  }
};

export const updateUnreadConversationsAction = (conversation) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      user: { id: currentUserId },
    },
  } = getState();

  const conversationWithInterLocutor = {
    interlocutor: conversation.participants.find(
      ({ userId }) => userId !== currentUserId
    ),
    ...conversation,
  };

  dispatch({
    type: UPDATE_UNREAD_CONVERSATIONS,
    payload: { newUnreadConversation: conversationWithInterLocutor },
  });
};

export const setCurrentConversationAction = (id) => async (
  dispatch,
  getState
) => {
  const {
    conversation: { conversations },
  } = getState();

  if (!conversations[id] && id) {
    const conversation = await messagesService.getConversationById(id);

    dispatch(updateConversationsAction([conversation]));
  }
  dispatch({
    type: SET_CURRENT_CONVERSATION,
    payload: id,
  });
};

export const setConversationOnTopAction = (id, sentAt) => ({
  type: SET_CONVERSATION_ON_TOP,
  payload: { conversationId: id, updatedAt: sentAt },
});
