const findDifferentFields = (obj1, obj2) => {
  const differentFields = [];

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  // eslint-disable-next-line
  for (const key in obj1) {
    // eslint-disable-next-line
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (!areArraysEqual(obj1[key], obj2[key])) {
          differentFields.push(key);
        }
      } else if (obj1[key] !== obj2[key]) {
        differentFields.push(key);
      }
    }
  }

  // eslint-disable-next-line
  for (const key in obj2) {
    // eslint-disable-next-line
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      differentFields.push(key);
    }
  }

  return differentFields;
};

export default findDifferentFields;
