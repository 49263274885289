import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserAvatarThumbUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';

const mapState = ({ auth: { user }, app: { config } }) => ({
  user,
  userAvatarThumbUrl: getUserAvatarThumbUrl(config),
});

const useCurrentUserAvatarUrl = () => {
  const { user, userAvatarThumbUrl } = useSelector(mapState);

  const currentAvatarUrl = useMemo(() => {
    if (user && user.avatar) {
      const { userId, hash } = user.avatar;
      return buildResourceUrl(userAvatarThumbUrl, userId, hash);
    }
    return null;
  }, [user, userAvatarThumbUrl]);

  return currentAvatarUrl;
};

export default useCurrentUserAvatarUrl;
