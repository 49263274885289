import { signUpFormFields, errorMessages } from 'modules/auth/signUp/constants';

const emailPattern = /.+@.+..+/;

const passwordRecoveryValidator = ({ [signUpFormFields.email]: email }) => {
  const errors = {};

  if (!email) {
    errors.email = errorMessages.required;
  } else if (!emailPattern.test(email)) {
    errors.email = errorMessages.emailInvalid;
  }

  return errors;
};

export default passwordRecoveryValidator;
