class RequestError extends Error {
  validation = {};

  constructor({ message, response, cancel, ...rest }) {
    super();
    this.response = response;
    this.cancel = cancel;


    Object.keys(rest).forEach((key) => {
      this[key] = rest[key];
    });

    if (response && response.data) {
      if (response.data.validation) {
        this.validation = response.data.validation;
      }
      if (response.data.error) {
        this.message = response.data.error.message;
      }
    }
  }

  get generalError() {
    return this.message;
  }

  get validationErrors() {
    return this.validation;
  }

  get cancelError() {
    return this.cancel;
  }

  get responseError() {
    return this.response;
  }

  get exceptionMessageError() {
    return this.response.data?.exception?.message || this.generalError;
  }
}

export default RequestError;
