import {
  SHOWCASE_SET_PREVIEW_URL_KEY,
  SHOWCASE_UPDATE_CURRENT,
  SHOWCASE_UPDATE_IMAGES_DND_STATE,
  SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE,
  SHOWCASE_UPDATE_LOOKBOARDS_LIBRARY,
  SHOWCASE_UPDATE_OWN_COLOR_BANNERS,
  SHOWCASE_UPDATE_OWN_IMAGE_BANNERS,
} from 'modules/showcase/store/constants';

const initialState = {
  current: null,
  ownColorBanners: [],
  ownImageBanners: [],
  previewUrlKey: '',
  lookBoardsLibrary: {},
  dndState: {
    featuredImages: [],
    featuredLookBoards: [],
  },
};

const showcaseReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SHOWCASE_UPDATE_CURRENT: {
      return {
        ...state,
        current: payload,
      };
    }
    case SHOWCASE_UPDATE_OWN_COLOR_BANNERS: {
      return {
        ...state,
        ownColorBanners: [...payload, ...state.ownColorBanners],
      };
    }
    case SHOWCASE_UPDATE_OWN_IMAGE_BANNERS: {
      return {
        ...state,
        ownImageBanners: [...payload, ...state.ownImageBanners],
      };
    }
    case SHOWCASE_SET_PREVIEW_URL_KEY: {
      return {
        ...state,
        previewUrlKey: payload,
      };
    }
    case SHOWCASE_UPDATE_LOOKBOARDS_LIBRARY: {
      return {
        ...state,
        lookBoardsLibrary: {
          ...state.lookBoardsLibrary,
          ...payload,
        },
      };
    }
    case SHOWCASE_UPDATE_IMAGES_DND_STATE: {
      return {
        ...state,
        dndState: {
          ...state.dndState,
          featuredImages: [...payload],
        },
      };
    }
    case SHOWCASE_UPDATE_LOOKBOARDS_DND_STATE: {
      return {
        ...state,
        dndState: {
          ...state.dndState,
          featuredLookBoards: [...payload],
        },
      };
    }
    default:
      return state;
  }
};

export default showcaseReducer;
