export const GTL_SET_AMBASSADOR_USERS = 'GTL_SET_AMBASSADOR_USERS';
export const GTL_SET_AMBASSADOR_FOLLOWED = 'GTL_SET_AMBASSADOR_FOLLOWED';
export const GTL_UPDATE_AMBASSADOR_USERS = 'GTL_UPDATE_AMBASSADOR_USERS';
export const GTL_UPDATE_AMBASSADORS_LIBRARY = 'GTL_UPDATE_AMBASSADORS_LIBRARY';
export const GTL_UPDATE_AMBASSADORS_LOOKBOARDS_DATA =
  'GTL_UPDATE_AMBASSADORS_LOOKBOARDS_DATA';
export const GTL_UPDATE_FOLLOWED_AMBASSADORS_LOOKBOARDS_DATA =
  'GTL_UPDATE_FOLLOWED_AMBASSADORS_LOOKBOARDS_DATA';
export const GTL_SET_AMBASSADORS_ACTIVE_TAB = 'GTL_SET_AMBASSADORS_ACTIVE_TAB';
export const GTL_UPDATE_AMBASSADORS_SEARCH_PARAMS =
  'GTL_UPDATE_AMBASSADORS_SEARCH_PARAMS';
export const GTL_UPDATE_AMBASSADORS_FILTER_VALUES =
  'GTL_UPDATE_AMBASSADORS_FILTER_VALUES';
export const GTL_RESET_AMBASSADOR_FILTER_VALUES =
  'GTL_RESET_AMBASSADOR_FILTER_VALUES';
export const GTL_SET_SELECTED_AMBASSADOR = 'GTL_SET_SELECTED_AMBASSADOR';
export const GTL_UPDATE_LOADING_STATUS_AMBASSADOR =
  'GTL_UPDATE_LOADING_STATUS_AMBASSADOR';
export const GTL_UPDATE_HAS_MORE_STATUS_AMBASSADOR =
  'GTL_UPDATE_HAS_MORE_STATUS_AMBASSADOR';
export const GTL_SET_AMBASSADOR_SHOWCASES = 'GTL_SET_AMBASSADOR_SHOWCASES';
export const GTL_AMBASSADOR_LIKE_LOOKBOARD = 'GTL_AMBASSADOR_LIKE_LOOKBOARD';
export const GTL_AMBASSADOR_VOTE_LOOKBOARD = 'GTL_AMBASSADOR_VOTE_LOOKBOARD';
export const GTL_UPDATE_AMBASSADOR_FOLLOWED = 'GTL_UPDATE_AMBASSADOR_FOLLOWED';
export const GTL_UPDATE_AMBASSADORS_FOLLOWED_LIBRARY =
  'GTL_UPDATE_AMBASSADORS_FOLLOWED_LIBRARY';
export const GTL_UPDATE_AMBASSADORS_FOLLOWED_LOOKBOARDS_DATA =
  'GTL_UPDATE_AMBASSADORS_FOLLOWED_LOOKBOARDS_DATA';
