import React from 'react';
import PropTypes from 'prop-types';
import classes from 'modules/layout/ClaimImageModal/ClaimImageModal.module.scss';

const SuccessMessage = ({ imgUrl }) => {
  return imgUrl ? (
    <div className="d-flex">
      <div className="col-6 flex-fill flex-center flex-column text-center p-5">
        <h2 className={`${classes.titleSuccess} font-title text-uppercase primary-color mb-2`}>
          Image Reported
        </h2>
        <p className={`${classes.descriptionSuccess} text-lg`}>
          Thank you for reporting <br /> this image. We will look into this <br /> right away
        </p>
      </div>
      <div className="col-6 p-0">
        <img className="w-100" src={imgUrl} alt="success" />
      </div>
    </div>
  ) : null;
};

SuccessMessage.propTypes = {
  imgUrl: PropTypes.string,
};

SuccessMessage.defaultProps = {
  imgUrl: null,
};

export default SuccessMessage;
