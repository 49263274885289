import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { loginAction } from 'modules/auth/store/actions';
import { useDispatch } from 'react-redux';
import { routesByName } from 'constants/routes';
import SignInComponent from 'modules/auth/signIn/SignInComponent';
import signInValidator from 'modules/auth/signIn/signInValidator';
import errorToastr from 'libs/toastr/errorToastr';
import { useHistory } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

const SignInContainer = ({ onCloseModal }) => {
  const queryParams = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (values) => {
      try {
        const queryAuth = queryParams.get('auth');
        const queryDashboard = queryParams.get('dashboard');
        const queryCurateTheLook = queryParams.get('curate');
        const queryRequestTheLook = queryParams.get('request');
        const queryAmbassadorMatch = queryParams.get('match');
        const queryRecommendation = queryParams.get('recommend');
        const requestId = queryParams.get('requestId');

        await dispatch(loginAction(values));
        onCloseModal();

        if (queryAuth === 'sign-in' && queryDashboard === 'true') {
          history.push(`${routesByName.dashboard.images}/?ext=true`);
          window.location.reload();
        } else if (queryAuth === 'sign-in' && queryCurateTheLook === 'true') {
          history.push(`${routesByName.curateTheLook.canvas}/?ext=true`);
          window.location.reload();
        } else if (queryAuth === 'sign-in' && queryRequestTheLook === 'true') {
          history.push(`${routesByName.requestTheLook.index}/?ext=true`);
          window.location.reload();
        } else if (queryAuth === 'sign-in' && queryAmbassadorMatch === 'true') {
          history.push(
            `${routesByName.requestTheLook.ambassadorMatch(requestId)}`
          );
          window.location.reload();
        } else if (queryAuth === 'sign-in' && queryRecommendation === 'true') {
          history.push(
            `${routesByName.requestTheLook.recommendations(requestId)}`
          );
          window.location.reload();
        }
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      return true;
    },
    [history, dispatch, onCloseModal, queryParams]
  );

  return <SignInComponent validateForm={signInValidator} onSubmit={onSubmit} />;
};

SignInContainer.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default SignInContainer;
