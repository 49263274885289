import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTermsModalAction } from 'modules/layout/store/actions';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import termsConfig from 'components/modals/TermsModal/termsConfig';
import UnderCut from 'components/modals/TermsModal/UnderCut';
import useQuery from 'hooks/useQuery';
import classes from './TermsModal.module.scss';

const TermsModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.layout.termsModalOpen);

  const queryParams = useQuery();

  const getTextWithLinks = (underCut) => {
    const { text, linkLabel1, linkLabel2, linkHref1, linkHref2 } = underCut;
    const parts = text.split(linkLabel1);
    const firstPart = parts[0];
    const secondParts = parts[1].split(linkLabel2);
    const secondPart = secondParts[0];
    const thirdPart = secondParts[1];
    return (
      <>
        {firstPart}
        <a href={linkHref1} className="primary-color">
          {linkLabel1}
        </a>
        {secondPart}
        <a href={linkHref2} className="primary-color">
          {linkLabel2}
        </a>
        {thirdPart}
      </>
    );
  };

  useEffect(() => {
    if (queryParams.get('termsModal') === 'true') {
      dispatch(toggleTermsModalAction(true));
    }
    // eslint-disable-next-line
  }, [queryParams]);

  const handleCloseModal = useCallback(() => {
    dispatch(toggleTermsModalAction(false));
  }, [dispatch]);

  return (
    <BasicModal
      open={open}
      onClose={handleCloseModal}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <h3 className="text-lg text-uppercase font-title text-center mb-4">
        Pin The Look Terms
      </h3>
      <ol className={classes.termsList}>
        {termsConfig.map(({ title, description, underCut }) => (
          <li key={title}>
            <div className="d-flex mb-3">
              <span className={classes.rootTitle}>{title}</span>
              <div className="text-sm">
                <p className="mb-1">{description.text}</p>
                {description.list && (
                  <ul className="mb-1" style={{ listStyleType: 'none' }}>
                    {description.list.map((listItem) => (
                      <li className="mb-2" key={listItem.id}>
                        <span
                          style={{ marginRight: '0.5em', fontSize: '20px' }}
                        >
                          &#8226;
                        </span>
                        {listItem.text && (
                          <span className="mb-1">{listItem.text}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
                {!!underCut && (
                  <UnderCut>
                    {underCut.text && (
                      <p className="mb-1">
                        {underCut.split
                          ? getTextWithLinks(underCut)
                          : underCut.text}
                      </p>
                    )}
                    {underCut.list && (
                      <ol type="a" className={classes.underCutList}>
                        {underCut.list.map(
                          ({
                            title: listTitle,
                            sublist,
                            id,
                            sublistType,
                            text: listText,
                          }) => (
                            <li className="mb-1" key={id}>
                              <p className={`${classes.listTitle} mb-1`}>
                                {listTitle}
                              </p>
                              <p className="mb-1">{listText}</p>
                              <ol type={sublistType ?? null}>
                                {sublist.map(({ text }) => (
                                  <li className="mb-1" key={text}>
                                    <p>{text}</p>
                                  </li>
                                ))}
                              </ol>
                            </li>
                          )
                        )}
                      </ol>
                    )}
                  </UnderCut>
                )}
              </div>
            </div>
          </li>
        ))}
      </ol>
    </BasicModal>
  );
};

export default TermsModal;
