import BaseAxiosInstance from 'libs/axios/BaseAxiosInstance';

const urlPrefix = '/v1';

const getTheLookService = {
  getDefaultFilters() {
    return BaseAxiosInstance.get(`${urlPrefix}/default-filters`).then(
      ({ result }) => result
    );
  },
};

export default getTheLookService;
