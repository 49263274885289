import { errorMessages, signUpFormFields } from 'modules/auth/signUp/constants';
import { supportFormFields } from 'modules/support/constants';

const tradeOpportunitiesValidator = ({
  [signUpFormFields.firstName]: firstName,
  [signUpFormFields.lastName]: lastName,
  [signUpFormFields.businessName]: businessName,
  [signUpFormFields.businessUrl]: businessUrl,
  [supportFormFields.subject]: subject,
  [supportFormFields.message]: message,
}) => {
  const errors = {};

  if (!firstName) {
    errors.firstName = errorMessages.required;
  }

  if (!lastName) {
    errors.lastName = errorMessages.required;
  }

  if (!businessName) {
    errors.businessName = errorMessages.required;
  }

  if (!businessUrl) {
    errors.businessUrl = errorMessages.required;
  }

  if (!subject) {
    errors.subject = errorMessages.required;
  }

  if (!message) {
    errors.message = errorMessages.required;
  }

  return errors;
};

export default tradeOpportunitiesValidator;
