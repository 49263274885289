export const imageSourceKeys = {
  all: 'all',
  my: 'my',
  liked: 'liked',
  requests: 'requests',
};

export const imageTypeKeys = {
  all: 'all',
  room: 'room',
  single: 'single',
  makeOver: 'makeOver',
};

export const imageSortKeys = {
  newest: 'newest',
  oldest: 'oldest',
  highest: 'highest',
  liked: 'liked',
};

export const imagePageTypes = {
  database: 'database',
  getTheLook: 'get-the-look',
  showcase: 'showcase',
  myImages: 'images',
  mixMatch: 'mix-match',
};
