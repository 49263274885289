import {
  AFFILIATE_SITES,
  GTL_SET_MORE_USERS,
  GTL_SET_SELECTED_USER,
  GTL_SET_USERS,
  GTL_SET_USERS_LOADING,
  GTL_SET_USERS_LOOKBOARDS,
  GTL_SET_USERS_SEARCH_PARAMS,
  GTL_TOGGLE_FOLLOWED_USER,
  GTL_TOGGLE_USER_LOOKBOARD_LIKE,
  GTL_UPDATE_HAS_MORE_STATUS_USERS,
  GTL_VOTE_USER_LOOKBOARD,
} from './constants';

const initialState = {
  hasMore: true,
  loading: false,
  usersList: [],
  lookBoards: {},
  selectedUser: null,
  usersSearchParams: {
    search: '',
    offset: 0,
  },
  limit: 10,
  affiliateSites: [],
};

const userPageReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GTL_SET_USERS_LOADING: {
      return { ...state, loading: payload };
    }
    case GTL_SET_USERS_SEARCH_PARAMS: {
      return { ...state, usersSearchParams: payload };
    }
    case GTL_SET_SELECTED_USER: {
      return { ...state, selectedUser: payload };
    }
    case GTL_SET_USERS: {
      return { ...state, usersList: payload };
    }
    case GTL_SET_MORE_USERS: {
      return { ...state, usersList: [...state.usersList, ...payload] };
    }
    case GTL_UPDATE_HAS_MORE_STATUS_USERS: {
      return { ...state, hasMore: payload };
    }
    case GTL_SET_USERS_LOOKBOARDS: {
      return { ...state, lookBoards: { ...state.lookBoards, ...payload } };
    }
    case GTL_TOGGLE_USER_LOOKBOARD_LIKE: {
      return {
        ...state,
        lookBoards: {
          ...state.lookBoards,
          [payload.id]: {
            ...state.lookBoards[payload.id],
            isLiked: payload.status,
          },
        },
      };
    }
    case GTL_VOTE_USER_LOOKBOARD: {
      return {
        ...state,
        lookBoards: {
          ...state.lookBoards,
          [payload.id]: {
            ...state.lookBoards[payload.id],
            isVoted: payload.status,
          },
        },
      };
    }
    case GTL_TOGGLE_FOLLOWED_USER: {
      return {
        ...state,
        usersList: state.usersList.map((user) =>
          user.id === payload ? { ...user, isFollowed: !user.isFollowed } : user
        ),
      };
    }
    case AFFILIATE_SITES: {
      return { ...state, affiliateSites: payload };
    }
    default:
      return state;
  }
};

export default userPageReducer;
