import React from 'react';
import { Helmet } from 'react-helmet';
import appConfig from '../../constants/appConfig';

const Gtag = () => {
  return (
    appConfig.nodeEnv !== 'staging' &&
    appConfig.nodeEnv !== 'development' && (
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WQQKEJ2DM9"
        />
        <script>
          {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-WQQKEJ2DM9');
      `}
        </script>
      </Helmet>
    )
  );
};

export default Gtag;
