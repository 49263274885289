import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const messagesService = {
  getUnreadConversations() {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/conversations/unread`);
  },

  createConversation(participants, name) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/conversations`, {
      type: 'private',
      participants,
      name,
    });
  },

  getMessagesByConversationId(data, config = null) {
    const { since, limit, id } = data;
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/conversations/${id}/messages?since=${since}&sort=asc&limit=${limit}`,
      config
    );
  },
  getConversationList(
    { offset, limit, name = '', interlocutor = '' },
    config = null
  ) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/conversations?limit=${limit}&offset=${offset}${
        name && `&name=${name}`
      }${interlocutor && `&interlocutor=${interlocutor}`}`,
      config
    );
  },

  getConversationById(id) {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/conversations/${id}`);
  },

  getContactList({ limit, offset, name = '' }, config = null) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/contact-list?limit=${limit}&offset=${offset}${
        name && `&name=${name}`
      }`,
      config
    );
  },

  sendMessage(id, messageText) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/conversations/${id}/messages`,
      { type: 1, version: 0, body: messageText }
    );
  },

  readConversation(data) {
    const { id, timestamp } = data;
    return AuthorizedAxiosInstance.patch(
      `${urlPrefix}/conversations/${id}/read`,
      {
        timestamp,
      }
    );
  },
};

export default messagesService;
