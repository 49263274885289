import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stylePreferencesTimeoutConfig } from 'modules/getTheLook/constants';
import { togglePreferencesRemindModalAction } from 'modules/layout/store/actions';
import useMediaQuery from './useMediaQuery';
import { maxWidthMd } from '../constants/mediaQueries';

export const useStylePreferencesEffect = () => {
  const currentUser = useSelector((state) => state.auth.user);
  const timeout = useRef(null);
  const dispatch = useDispatch();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const openStylePreferencesModal = useCallback(() => {
    dispatch(togglePreferencesRemindModalAction(true));
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && !currentUser.preferences && !matchesMediaQuery) {
      timeout.current = setTimeout(() => {
        openStylePreferencesModal();
      }, stylePreferencesTimeoutConfig.first);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [currentUser, openStylePreferencesModal, matchesMediaQuery]);
};
