import { useCallback, useContext, useRef, useEffect } from 'react';

import { EchoContext } from './Provider';

const useConnectToEchoServer = ({
  channelName,
  events = {},
  namespace = '',
}) => {
  const channel = useRef(null);
  const Echo = useContext(EchoContext);
  const instance = useRef(null);
  const channelNameRef = useRef(null);

  const connectToEcho = useCallback(
    async (cn) => {
      instance.current = await Echo.connect();
      channel.current = await instance.current.channel(cn);
      channelNameRef.current = cn;
      Object.entries(events).forEach(([name, callback]) =>
        channel.current.listen(`${namespace}.${name}`, callback)
      );
    },
    [Echo, namespace, events]
  );

  useEffect(() => {
    if (channelName) {
      connectToEcho(channelName);
    }
    return () => {
      if (channelNameRef.current) {
        instance.current.leave(channelNameRef.current);
      }
    };
    //  eslint-disable-next-line
  }, [channelName]);
};

export default useConnectToEchoServer;
