import {
  PUSH_MESSAGE_BY_ID,
  SET_CONVERSATIONS,
  SET_MESSAGES_BY_CONVERSATION_ID,
  SET_META_BY_CONVERSATION_ID,
  UPDATE_CONVERSATIONS,
  UPDATE_MESSAGES_BY_CONVERSATION_ID,
  SET_CONTACT_LIST,
  UPDATE_CONTACT_LIST,
  SET_UNREAD_CONVERSATIONS,
  UPDATE_UNREAD_CONVERSATIONS,
  REMOVE_UNREAD_CONVERSATION,
  SET_CURRENT_CONVERSATION,
  SET_CONVERSATION_ON_TOP,
} from 'modules/conversation/store/constants';

const initialState = {
  conversations: {},
  conversationsList: [],
  messagesByConversationId: {},
  metaByConversationId: {},
  contactList: [],
  unreadConversations: [],
  unreadConversationsById: {},
  currentConversationId: null,
};

const conversationReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: payload.transformedConversations,
        conversationsList: payload.conversationsList,
      };
    }
    case UPDATE_CONVERSATIONS: {
      const { top = false } = payload.options;

      if (top) {
        return {
          ...state,
          conversations: {
            ...state.conversations,
            ...payload.transformedConversations,
          },
          conversationsList: [
            ...payload.conversationsList,
            ...state.conversationsList,
          ],
        };
      }
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...payload.transformedConversations,
        },
        conversationsList: [
          ...state.conversationsList,
          ...payload.conversationsList,
        ],
      };
    }
    case SET_MESSAGES_BY_CONVERSATION_ID: {
      return {
        ...state,
        messagesByConversationId: {
          ...state.messagesByConversationId,
          ...payload,
        },
      };
    }
    case PUSH_MESSAGE_BY_ID: {
      return {
        ...state,
        messagesByConversationId: {
          ...state.messagesByConversationId,
          [payload.id]: [
            ...state.messagesByConversationId[payload.id],
            payload.message,
          ],
        },
      };
    }
    case SET_META_BY_CONVERSATION_ID: {
      return {
        ...state,
        metaByConversationId: {
          ...state.metaByConversationId,
          ...payload,
        },
      };
    }
    case UPDATE_MESSAGES_BY_CONVERSATION_ID: {
      return {
        ...state,
        messagesByConversationId: {
          ...state.messagesByConversationId,
          [payload.id]: [
            ...payload.messages,
            ...state.messagesByConversationId[payload.id],
          ],
        },
      };
    }
    case SET_CONTACT_LIST: {
      return {
        ...state,
        contactList: payload,
      };
    }
    case UPDATE_CONTACT_LIST: {
      return {
        ...state,
        contactList: [...state.contactList, ...payload],
      };
    }
    case SET_UNREAD_CONVERSATIONS: {
      const { unreadConversations, unreadConversationsById } = payload;
      return {
        ...state,
        unreadConversations,
        unreadConversationsById,
      };
    }
    case UPDATE_UNREAD_CONVERSATIONS: {
      const unreadConversationsCopy = [...state.unreadConversations];

      const { newUnreadConversation } = payload;

      if (state.unreadConversationsById[newUnreadConversation.id]) {
        const index = unreadConversationsCopy.findIndex(
          (id) => id === newUnreadConversation.id
        );
        const removed = unreadConversationsCopy.splice(index, 1);
        unreadConversationsCopy.unshift(removed[0]);
      } else {
        unreadConversationsCopy.unshift(newUnreadConversation.id);
      }

      const transformedConversation = {
        [newUnreadConversation.id]: newUnreadConversation,
      };

      return {
        ...state,
        unreadConversations: unreadConversationsCopy,
        unreadConversationsById: {
          ...state.unreadConversationsById,
          ...transformedConversation,
        },
      };
    }

    case REMOVE_UNREAD_CONVERSATION: {
      if (state.unreadConversationsById[payload]) {
        const newList = [...state.unreadConversations];
        const newTransformedList = { ...state.unreadConversationsById };

        delete newTransformedList[payload];

        const deletedConversationIndex = newList.findIndex(
          (id) => id === payload
        );
        newList.splice(deletedConversationIndex, 1);

        return {
          ...state,
          unreadConversations: newList,
          unreadConversationsById: newTransformedList,
        };
      }
      return { ...state };
    }
    case SET_CURRENT_CONVERSATION: {
      return {
        ...state,
        currentConversationId: payload,
      };
    }
    case SET_CONVERSATION_ON_TOP: {
      const { conversationId, updatedAt } = payload;
      const conversationsListCopy = [...state.conversationsList];

      const conversationIndex = conversationsListCopy.findIndex(
        (id) => id === conversationId
      );

      if (conversationIndex) {
        conversationsListCopy.splice(conversationIndex, 1);
        conversationsListCopy.unshift(conversationId);
        return {
          ...state,
          conversationsList: conversationsListCopy,
          conversations: {
            ...state.conversations,
            [conversationId]: {
              ...state.conversations[conversationId],
              updatedAt,
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default conversationReducer;
