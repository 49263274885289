import React from 'react';
import PropTypes from 'prop-types';
import Echo from './index';

export const EchoContext = React.createContext(Echo);

const EchoProvider = ({ children }) => {
  return <EchoContext.Provider value={Echo}>{children}</EchoContext.Provider>;
};

EchoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default EchoProvider;
