import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './HeaderTooltip.module.scss';

const placementToSize = {
  top: classes.topPlacement,
  bottom: classes.bottomPlacement,
};
const HeaderTooltip = ({ children, text, placement, className, ...rest }) => {
  return (
    <div className={clsx(classes.root)}>
      <div
        className={clsx(classes.text, placementToSize[placement], className)}
      >
        {text}
      </div>
      <div {...rest} className="d-flex">
        {children}
      </div>
    </div>
  );
};

HeaderTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom']),
};
HeaderTooltip.defaultProps = {
  placement: 'bottom',
  className: '',
};

export default HeaderTooltip;
