import {
  II_UPDATE_LIBRARY,
  II_UPDATE_IMAGE_LIKE_STATUS,
  II_UPDATE_CREATION_IMAGE_STATUS,
  II_SELECT_OWN_IMAGE,
  II_LIKE_LOOKBOARD_GTL,
  II_VOTE_LOOKBOARD_GTL,
} from 'modules/inspirationImage/store/constants';

const initialState = {
  library: {},
  createInstantlyFor: null,
  isOwnImage: true,
};

const inspirationImageReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case II_UPDATE_LIBRARY: {
      return {
        ...state,
        library: {
          ...state.library,
          ...payload,
        },
      };
    }
    case II_UPDATE_IMAGE_LIKE_STATUS: {
      const { id, isLiked } = payload;
      return {
        ...state,
        library: {
          ...state.library,
          [id]: {
            ...state.library[id],
            isLiked,
          },
        },
      };
    }
    case II_UPDATE_CREATION_IMAGE_STATUS: {
      return {
        ...state,
        createInstantlyFor: payload,
      };
    }
    case II_SELECT_OWN_IMAGE: {
      return {
        ...state,
        isOwnImage: payload,
      };
    }
    case II_LIKE_LOOKBOARD_GTL: {
      const { imageId, likeStatus } = payload;

      const currentImageCopy = { ...state.library[imageId] };
      const currentLookBoard = currentImageCopy.firstLookBoard;

      currentLookBoard.isLiked = likeStatus;

      return {
        ...state,
        library: {
          ...state.library,
          [imageId]: currentImageCopy,
        },
      };
    }
    case II_VOTE_LOOKBOARD_GTL: {
      const { imageId } = payload;

      const currentImageCopy = { ...state.library[imageId] };
      const currentLookBoard = currentImageCopy.firstLookBoard;
      currentLookBoard.isVoted = true;

      return {
        ...state,
        library: {
          ...state.library,
          [imageId]: currentImageCopy,
        },
      };
    }
    default:
      return state;
  }
};

export default inspirationImageReducer;
