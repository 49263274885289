import {
  GTL_AMBASSADOR_LIKE_LOOKBOARD,
  GTL_AMBASSADOR_VOTE_LOOKBOARD,
  GTL_RESET_AMBASSADOR_FILTER_VALUES,
  GTL_SET_AMBASSADOR_FOLLOWED,
  GTL_SET_AMBASSADOR_USERS,
  GTL_SET_AMBASSADORS_ACTIVE_TAB,
  GTL_SET_SELECTED_AMBASSADOR,
  GTL_UPDATE_AMBASSADOR_FOLLOWED,
  GTL_UPDATE_AMBASSADOR_USERS,
  GTL_UPDATE_AMBASSADORS_FILTER_VALUES,
  GTL_UPDATE_AMBASSADORS_FOLLOWED_LIBRARY,
  GTL_UPDATE_AMBASSADORS_FOLLOWED_LOOKBOARDS_DATA,
  GTL_UPDATE_AMBASSADORS_LIBRARY,
  GTL_UPDATE_AMBASSADORS_LOOKBOARDS_DATA,
  GTL_UPDATE_AMBASSADORS_SEARCH_PARAMS,
  GTL_UPDATE_HAS_MORE_STATUS_AMBASSADOR,
  GTL_UPDATE_LOADING_STATUS_AMBASSADOR,
} from 'modules/getTheLook/ambassadorPage/store/constants';
import transformArrayToMap from 'utils/transformArrayToMap';
import errorToastr from 'libs/toastr/errorToastr';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import { setDefaultFilters } from 'modules/auth/store/actions';
import showcaseService from '../../../showcase/showcaseService';
import { getTheLookAmbassadorsTabKeys, professionOptions } from '../config';

const unauthorizedLimit = 70;

export const updateAmbassadorsLookBoardsDataAction = (
  lookBoardIdsByShowcase
) => async (dispatch) => {
  const promiseArr = [];

  lookBoardIdsByShowcase.forEach((item) => {
    if (item) {
      promiseArr.push(
        showcaseService.getLookBoards(item.showcaseId, item.featuredLookBoards)
      );
    }
  });

  const result = await Promise.all(promiseArr);
  const lookBoardsData = result.reduce((accum, item) => {
    let newAccum = { ...accum };
    item.forEach((lookBoard) => {
      newAccum = { ...newAccum, [lookBoard.id]: lookBoard };
    });

    return newAccum;
  }, {});

  dispatch({
    type: GTL_UPDATE_AMBASSADORS_LOOKBOARDS_DATA,
    payload: lookBoardsData,
  });
};
export const updateFollowedAmbassadorsLookBoardsDataAction = (
  lookBoardIdsByShowcase
) => async (dispatch) => {
  const promiseArr = [];

  lookBoardIdsByShowcase.forEach((item) => {
    if (item) {
      promiseArr.push(
        showcaseService.getLookBoards(item.showcaseId, item.featuredLookBoards)
      );
    }
  });

  const result = await Promise.all(promiseArr);
  const lookBoardsData = result.reduce((accum, item) => {
    let newAccum = { ...accum };
    item.forEach((lookBoard) => {
      newAccum = { ...newAccum, [lookBoard.id]: lookBoard };
    });

    return newAccum;
  }, {});

  dispatch({
    type: GTL_UPDATE_AMBASSADORS_FOLLOWED_LOOKBOARDS_DATA,
    payload: lookBoardsData,
  });
};

export const toggleLikeAmbassadorLookBoardAction = (
  lookBoardId,
  isLiked
) => async (dispatch) => {
  await lookBoardService.toggleLike(lookBoardId, isLiked);

  dispatch({
    type: GTL_AMBASSADOR_LIKE_LOOKBOARD,
    payload: { lookBoardId, isLiked },
  });
};
export const voteAmbassadorLookBoardAction = (lookBoardId, status) => async (
  dispatch
) => {
  await lookBoardService.voteHandler(lookBoardId, status);

  dispatch({
    type: GTL_AMBASSADOR_VOTE_LOOKBOARD,
    payload: { lookBoardId, status },
  });
};

export const setAmbassadorsActiveTabAction = (tab) => (dispatch) => {
  dispatch({
    type: GTL_SET_AMBASSADORS_ACTIVE_TAB,
    payload: tab,
  });
};

export const updateAmbassadorsSearchParamsAction = (
  ambassadorsSearchParams
) => ({
  type: GTL_UPDATE_AMBASSADORS_SEARCH_PARAMS,
  payload: ambassadorsSearchParams,
});

export const updateAmbassadorsFilterValuesAction = (
  ambassadorsFilterValues
) => (dispatch) => {
  dispatch({
    type: GTL_UPDATE_AMBASSADORS_FILTER_VALUES,
    payload: ambassadorsFilterValues,
  });
  dispatch(updateAmbassadorsSearchParamsAction({ offset: 0 }));
};

export const updateAmbassadorsLibraryAction = (payload) => ({
  type: GTL_UPDATE_AMBASSADORS_LIBRARY,
  payload,
});

export const updateAmbassadorsFollowedLibraryAction = (payload) => ({
  type: GTL_UPDATE_AMBASSADORS_FOLLOWED_LIBRARY,
  payload,
});

export const syncSelectedAmbassadorDataAction = (ambassadorId) => (
  dispatch,
  getState
) => {
  const {
    ambassadorsPage: { usersLibrary },
  } = getState();
  try {
    const userData = Object.values(usersLibrary).find(
      (user) => user.id === ambassadorId
    );

    dispatch({ type: GTL_SET_SELECTED_AMBASSADOR, payload: userData });
  } catch (e) {
    errorToastr(e.message);
  }
};

export const setSelectedAmbassadorAction = (userData) => ({
  type: GTL_SET_SELECTED_AMBASSADOR,
  payload: userData,
});

export const updateAmbassadorFilterValuesAction = (filterValues) => (
  dispatch
) => {
  dispatch({
    type: GTL_UPDATE_AMBASSADORS_FILTER_VALUES,
    payload: filterValues,
  });
  dispatch(updateAmbassadorsSearchParamsAction({ offset: 0 }));
};

export const resetAmbassadorFilterValuesAction = () => async (
  dispatch,
  getState
) => {
  const {
    auth: { user },
  } = getState();
  if (user?.preferences) {
    const { preferences } = user;
    const filters = {
      styles: preferences.styles ?? [],
      subStyles: preferences.subStyles ?? {},
      roomTypes: preferences.roomTypes ?? [],
      colors: Object.values(preferences.colors) ?? [],
      profession: preferences.profession ?? '',
    };
    dispatch(updateAmbassadorFilterValuesAction(filters));
  } else {
    await setDefaultFilters(dispatch, getState);
  }
};

export const clearAllAmbassadorFilterValuesAction = () => (dispatch) => {
  dispatch({
    type: GTL_RESET_AMBASSADOR_FILTER_VALUES,
  });
  dispatch(updateAmbassadorsSearchParamsAction({ offset: 0 }));
};

export const loadFirstShowcaseAction = () => async (dispatch, getState) => {
  const {
    ambassadorsPage: {
      ambassadorsSearchParams,
      ambassadorsFilterValues,
      limit,
    },
    auth: { user },
  } = getState();

  dispatch({ type: GTL_UPDATE_LOADING_STATUS_AMBASSADOR, payload: true });
  const profession =
    ambassadorsFilterValues.profession.map(
      (id) => professionOptions.find((item) => item.id === id).value
    ) || '';

  const ambassadorsSearchParamsCopy = { ...ambassadorsSearchParams, offset: 0 };

  if (user) {
    const showcaseFollowedList = await showcaseService.searchShowcaseFollowers({
      ...ambassadorsFilterValues,
      ...ambassadorsSearchParamsCopy,
      profession,
    });

    const lookBoardsList = showcaseFollowedList.map(
      ({ featuredLookBoards, id }) =>
        featuredLookBoards.length > 0
          ? { featuredLookBoards, showcaseId: id }
          : null
    );

    const showcaseIds = Object.values(showcaseFollowedList).map(
      ({ id }) => `${id}`
    );

    const transformedList = transformArrayToMap(showcaseFollowedList);

    dispatch(updateAmbassadorsFollowedLibraryAction(transformedList));
    dispatch({ type: GTL_SET_AMBASSADOR_FOLLOWED, payload: showcaseIds });
    await dispatch(
      updateFollowedAmbassadorsLookBoardsDataAction(lookBoardsList)
    );
  }

  const showcaseList = await showcaseService.searchShowcase({
    ...ambassadorsFilterValues,
    ...ambassadorsSearchParamsCopy,
    profession,
  });

  const lookBoardsList = showcaseList.map(({ featuredLookBoards, id }) =>
    featuredLookBoards.length > 0
      ? { featuredLookBoards, showcaseId: id }
      : null
  );

  const showcaseIds = Object.values(showcaseList).map(({ id }) => `${id}`);

  const transformedList = transformArrayToMap(showcaseList);

  dispatch(updateAmbassadorsLibraryAction(transformedList));
  dispatch({ type: GTL_SET_AMBASSADOR_USERS, payload: showcaseIds });
  await dispatch(updateAmbassadorsLookBoardsDataAction(lookBoardsList));
  if (showcaseIds.length > 0) {
    dispatch(
      updateAmbassadorsSearchParamsAction({
        offset: ambassadorsSearchParamsCopy.offset + showcaseIds.length,
      })
    );
  }

  dispatch({
    type: GTL_UPDATE_HAS_MORE_STATUS_AMBASSADOR,
    payload: showcaseIds.length === limit,
  });
  dispatch({ type: GTL_UPDATE_LOADING_STATUS_AMBASSADOR, payload: false });
};

export const loadMoreAmbassadorsAction = (isFollowed) => async (
  dispatch,
  getState
) => {
  const {
    auth: { user },
    ambassadorsPage: {
      ambassadorsSearchParams,
      ambassadorsFilterValues,
      limit,
    },
  } = getState();

  dispatch({ type: GTL_UPDATE_LOADING_STATUS_AMBASSADOR, payload: true });

  const profession =
    ambassadorsFilterValues.profession.map(
      (id) => professionOptions.find((item) => item.id === id).value
    ) || '';

  if (user && isFollowed) {
    const showcaseFollowedList = await showcaseService.searchShowcaseFollowers({
      ...ambassadorsFilterValues,
      ...ambassadorsSearchParams,
      profession,
    });

    const lookBoardsList = showcaseFollowedList.map(
      ({ featuredLookBoards, id }) =>
        featuredLookBoards.length > 0
          ? { featuredLookBoards, showcaseId: id }
          : null
    );

    const showcaseIds = Object.values(showcaseFollowedList).map(
      ({ id }) => `${id}`
    );

    const transformedList = transformArrayToMap(showcaseFollowedList);

    dispatch(updateAmbassadorsFollowedLibraryAction(transformedList));
    dispatch({ type: GTL_UPDATE_AMBASSADOR_FOLLOWED, payload: showcaseIds });
    await dispatch(
      updateFollowedAmbassadorsLookBoardsDataAction(lookBoardsList)
    );
    const updatedListLength =
      ambassadorsSearchParams.offset + showcaseIds.length;
    if (showcaseIds.length > 0) {
      dispatch(
        updateAmbassadorsSearchParamsAction({
          offset: ambassadorsSearchParams.offset + showcaseIds.length,
        })
      );
    }
    const hasMore =
      !user && updatedListLength >= unauthorizedLimit
        ? false
        : showcaseIds.length === limit;
    dispatch({
      type: GTL_UPDATE_HAS_MORE_STATUS_AMBASSADOR,
      payload: hasMore,
    });
  } else {
    const showcaseList = await showcaseService.searchShowcase({
      ...ambassadorsFilterValues,
      ...ambassadorsSearchParams,
      profession,
    });

    const lookBoardsList = showcaseList.map(({ featuredLookBoards, id }) =>
      featuredLookBoards.length > 0
        ? { featuredLookBoards, showcaseId: id }
        : null
    );

    const showcaseIds = Object.values(showcaseList).map(({ id }) => `${id}`);

    const transformedList = transformArrayToMap(showcaseList);

    dispatch(updateAmbassadorsLibraryAction(transformedList));
    dispatch({ type: GTL_UPDATE_AMBASSADOR_USERS, payload: showcaseIds });
    await dispatch(updateAmbassadorsLookBoardsDataAction(lookBoardsList));
    const updatedListLength =
      ambassadorsSearchParams.offset + showcaseIds.length;
    if (showcaseIds.length > 0) {
      dispatch(
        updateAmbassadorsSearchParamsAction({
          offset: ambassadorsSearchParams.offset + showcaseIds.length,
        })
      );
    }
    const hasMore =
      !user && updatedListLength >= unauthorizedLimit
        ? false
        : showcaseIds.length === limit;
    dispatch({
      type: GTL_UPDATE_HAS_MORE_STATUS_AMBASSADOR,
      payload: hasMore,
    });
  }
  dispatch({ type: GTL_UPDATE_LOADING_STATUS_AMBASSADOR, payload: false });
};

export const findAmbassadorUserAction = (firstName, lastName, userId) => async (
  dispatch
) => {
  const showcases = await showcaseService.searchShowcase({
    colors: [],
    offset: 0,
    profession: '',
    roomTypes: [],
    search: `${firstName} ${lastName}`,
    styles: [],
    subStyles: {},
  });
  const selectedUser = showcases.find((showcase) => showcase.userId === userId);

  const transformedList = transformArrayToMap([selectedUser]);
  dispatch(updateAmbassadorsLibraryAction(transformedList));
  dispatch({
    type: GTL_SET_AMBASSADOR_USERS,
    payload: [String(selectedUser.id)],
  });

  dispatch(
    updateAmbassadorsLookBoardsDataAction([
      selectedUser.featuredLookBoards.length > 0
        ? {
            featuredLookBoards: selectedUser.featuredLookBoards,
            showcaseId: selectedUser.id,
          }
        : null,
    ])
  );
  dispatch(setSelectedAmbassadorAction(selectedUser));
  dispatch(
    setAmbassadorsActiveTabAction(getTheLookAmbassadorsTabKeys.lookBoards)
  );
  dispatch(updateAmbassadorsSearchParamsAction({ offset: 0 }));
};
