import decorLoverImgUrl from 'assets/img/preference-profession-decor-lover.png';
import { professionsMap } from 'modules/account/profileAndPreferences/preferences/constants';
import designerImgUrl from 'assets/img/preference-profession-designer.png';
import onlineShopperImgUrl from 'assets/img/preference-profession-online-shopper.png';

export const ambassadorFilterTabKeys = {
  profession: 'profession',
  styles: 'styles',
  subStyles: 'subStyles',
  roomTypes: 'roomTypes',
  colors: 'colors',
};

export const ambassadorsFilterModalTitle = {
  title: 'Ambassadors',
};

export const ambassadorFilterTabsConfig = [
  {
    value: ambassadorFilterTabKeys.profession,
    title: 'Type',
  },
  {
    value: ambassadorFilterTabKeys.styles,
    title: 'Styles',
  },
  {
    value: ambassadorFilterTabKeys.subStyles,
    title: 'Sub Styles',
  },
  {
    value: ambassadorFilterTabKeys.roomTypes,
    title: 'Room Types',
  },
  {
    value: ambassadorFilterTabKeys.colors,
    title: 'Colors',
  },
];

export const getTheLookAmbassadorsTabKeys = {
  main: 'main',
  showcase: 'showcase',
  lookBoards: 'lookBoards',
};

export const ambassadorModalSubtitles = {
  [ambassadorFilterTabKeys.styles]: 'Select Styles for your Search',
  [ambassadorFilterTabKeys.subStyles]: 'Select Sub Styles for your Search',
  [ambassadorFilterTabKeys.roomTypes]: 'Select Room Type(s)',
  [ambassadorFilterTabKeys.colors]: 'Select Primary Color(s) (optional)',
  [ambassadorFilterTabKeys.profession]: 'Select Ambassador Type',
};

export const professionOptions = [
  {
    id: 1,
    imgUrl: decorLoverImgUrl,
    title: 'Decor Lover',
    name: 'Decor Lover',
    description: 'I love decorating and sharing decorating ideas with others.',
    icon: 'plants',
    value: professionsMap.decorLover.value,
  },
  {
    id: 2,
    imgUrl: designerImgUrl,
    title: 'Design Professional',
    name: 'Design Professional',
    description:
      'I am an interior designer or architect and can provide additional design services.',
    icon: 'interior-design',
    value: professionsMap.designProfessional.value,
  },
  {
    id: 3,
    imgUrl: onlineShopperImgUrl,
    title: 'Skilled Online Shopper',
    name: 'Skilled Online Shopper',
    description:
      'I am a skilled online shopper and can help others Get the Look according to their specifications.',
    icon: 'monitor',
    value: professionsMap.onlineShopper.value,
  },
];
