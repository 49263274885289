import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import {
  setClaimReasonAction,
  setClaimSubReasonAction,
  toggleClaimImageModalAction,
} from 'modules/report/store/actions';
import {
  stepsConfig,
  firstStepConfig,
  secondStepConfig,
  reportReasonValues,
  reportReasonTypes,
  reportSubReasonValues,
} from 'modules/layout/ClaimImageModal/constants';
import errorToastr from 'libs/toastr/errorToastr';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import { routesByName } from 'constants/routes';
import buildResourceUrl from 'utils/buildResourceUrl';
import SuccessMessage from 'modules/layout/ClaimImageModal/components/SuccessMessage';
import Step from 'modules/layout/ClaimImageModal/components/Step';
import classes from './ClaimImageModal.module.scss';

const ClaimImageModal = () => {
  const dispatch = useDispatch();

  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const {
    reason,
    subReason,
    claimImageModalOpen: isOpen,
    imageId,
  } = useSelector((state) => state.report);
  const library = useSelector((state) => state.inspirationImage.library);
  const selectedImage = useMemo(() => library[imageId], [imageId, library]);

  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(stepsConfig[0]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const imgUrl = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  const goToNextStep = useCallback(() => {
    const nextStep = stepsConfig[currentStep.number];
    if (nextStep) {
      setCurrentStep(nextStep);
    }
  }, [currentStep]);

  const handleChangeReason = useCallback(
    ({ target: { value } }) => {
      dispatch(setClaimReasonAction(value));
    },
    [dispatch]
  );

  const handleChangeSubReason = useCallback(
    ({ target: { value } }) => {
      dispatch(setClaimSubReasonAction(value));
    },
    [dispatch]
  );

  const backToFirstStep = useCallback(() => {
    setCurrentStep(stepsConfig[0]);
    dispatch(setClaimSubReasonAction(null));
  }, [dispatch]);

  const handleCloseSuccessModal = useCallback(() => {
    setSuccessModalOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(toggleClaimImageModalAction(false));
  }, [dispatch]);

  const handleSubmit = useCallback(async () => {
    if (reason === reportReasonValues.own) {
      if (subReason === reportSubReasonValues.takeDown) {
        history.push(routesByName.report.copyrightInfringement);
      } else {
        history.push(routesByName.report.getCredit);
      }
      handleClose();
    } else {
      try {
        await inspirationImageService.reportImage(imageId, {
          [reportReasonTypes.reportReason]: reason,
          [reportReasonTypes.reportSubReason]: subReason,
        });
        dispatch(toggleClaimImageModalAction(false));
        setSuccessModalOpen(true);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [reason, subReason, imageId, dispatch, history, handleClose]);

  useEffect(() => {
    if (isOpen) {
      dispatch(setClaimReasonAction(null));
      dispatch(setClaimSubReasonAction(null));
      setCurrentStep(stepsConfig[0]);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const getCurrentStep = useMemo(() => {
    switch (currentStep.number) {
      case 1:
        return (
          <Step
            selected={reason}
            name={currentStep.name}
            options={firstStepConfig}
            onChange={handleChangeReason}
            onCancel={handleClose}
            onNext={goToNextStep}
          />
        );
      case 2:
        return (
          <Step
            selected={subReason}
            name={currentStep.name}
            options={secondStepConfig[reason]}
            onChange={handleChangeSubReason}
            onCancel={backToFirstStep}
            onNext={handleSubmit}
          />
        );
      default:
        return null;
    }
  }, [
    currentStep,
    reason,
    subReason,
    handleChangeReason,
    handleChangeSubReason,
    handleClose,
    handleSubmit,
    goToNextStep,
    backToFirstStep,
  ]);

  return (
    <>
      <BasicModal open={isOpen} onClose={handleClose} fullWidth>
        <div className={`${classes.root} d-flex flex-column`}>
          <h2
            className={`${classes.title} text-lg font-title text-center text-uppercase mb-2`}
          >
            Claim or report image
          </h2>
          {getCurrentStep}
        </div>
      </BasicModal>
      <BasicModal
        classes={{ root: 'p-0' }}
        open={successModalOpen}
        onClose={handleCloseSuccessModal}
        maxWidth="md"
        fullWidth
      >
        <SuccessMessage imgUrl={imgUrl} />
      </BasicModal>
    </>
  );
};

export default ClaimImageModal;
