import { createSelector } from 'reselect';
import { filter } from 'lodash-es';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import findObjectById from 'utils/findObjectById';

export const getEnumAsSelectOptions = (resource) =>
  Object.values(resource).map(({ id: value, name: label }) => ({
    label,
    value,
  }));

export const getEnumAsSelectOptionsMap = (resource) => {
  return Object.values(resource)
    .map(({ id: value, name: label }) => ({
      label,
      value,
    }))
    .reduce((accum, item) => ({ ...accum, [item.value]: item }), {});
};

export const getCommonItemClassesByRoomId = (commonRoomClasses) => {
  const result = Object.values(commonRoomClasses).reduce((accum, currValue) => {
    /* eslint-disable no-param-reassign */
    (accum[currValue.roomTypeId] || (accum[currValue.roomTypeId] = [])).push(
      currValue
    );
    return accum;
  }, {});

  Object.keys(result).map((roomTypeId) => {
    result[roomTypeId].sort((a, b) => a.order - b.order);
    return null;
  });

  return result;
};

const itemIds = (_, filterIds) => filterIds;

export const getItemClassesAsSelectOptionsByIds = createSelector(
  getEnumAsSelectOptions,
  itemIds,
  (options, filterIds) =>
    filter(options, (option) => filterIds.includes(option.value))
);

export const getUserAvatarUrl = ({
  endpoints: {
    media: { userAvatar },
  },
}) => userAvatar.small;

export const getUserAvatarThumbUrl = ({
  endpoints: {
    media: { userAvatar },
  },
}) => userAvatar.thumb;

export const getStyleImgUrl = ({
  endpoints: {
    media: { style },
  },
}) => style.thumb;

export const getVibeImgUrl = ({
  endpoints: {
    media: { vibes },
  },
}) => vibes.medium;

export const getStyleImgPreviewUrl = ({
  endpoints: {
    media: { style },
  },
}) => style.large;

export const getVibeImgPreviewUrl = ({
  endpoints: {
    media: { vibes },
  },
}) => vibes.large;

export const getSubStyleImgUrl = ({
  endpoints: {
    media: { subStyle },
  },
}) => subStyle.medium;

export const getInspirationImgUrl = ({
  endpoints: {
    media: { inspirationImage },
  },
}) => inspirationImage;

export const getProductImgUrl = ({
  endpoints: {
    media: { product },
  },
}) => product;

export const getFilteredOptionsByPreferences = (options, preferences) =>
  preferences.length > 0
    ? preferences.map((preferenceId) => options[preferenceId])
    : [];

export const getFilteredOptionsByPreferencesById = (options, preferences) =>
  preferences.length > 0
    ? preferences.map((preferenceId) => findObjectById(preferenceId, options))
    : [];

export const getSubStylesBySelectedStyles = (subStyles, selectedStyleIds) => {
  if (!selectedStyleIds) {
    return [];
  }
  return selectedStyleIds.reduce((accum, currValue) => {
    const selectedSubStyles = Object.values(subStyles).filter(
      ({ styleId }) => styleId === currValue
    );

    return [...accum, ...selectedSubStyles];
  }, []);
};

export const getStylesBySelectedVibes = (styles, selectedVibeIds) => {
  if (!selectedVibeIds) {
    return [];
  }
  return selectedVibeIds.reduce((accum, currValue) => {
    const selectedSubStyles = Object.values(styles).filter(
      ({ vibeId }) => vibeId === currValue
    );

    return [...accum, ...selectedSubStyles];
  }, []);
};

export const getSubStylesBySelectedStylesForFilters = (
  styles,
  subStyles,
  selectedStyleIds
) => {
  if (!selectedStyleIds) {
    return [];
  }
  const selected = selectedStyleIds.reduce((accum, currValue) => {
    const { name } = findObjectById(currValue, styles);
    const selectedSubStyles = Object.values(subStyles).filter(
      ({ styleId }) => styleId === currValue
    );
    accum.push({
      id: currValue,
      name,
      subStyles: selectedSubStyles,
    });

    return accum;
  }, []);
  return selected.map((item) => ({
    ...item,
    subStyles: item.subStyles.sort((a, b) => a.order - b.order),
  }));
};

export const getSubStylesByPreference = (
  styles,
  subStyles,
  selectedStyleIds,
  selectedSubStyleIds
) =>
  selectedStyleIds.reduce((accum, currValue) => {
    const { name } = findObjectById(currValue, styles);
    const selectedSubStyles = selectedSubStyleIds
      ? Object.values(subStyles).filter(
          ({ id, styleId }) =>
            styleId === currValue &&
            Object.keys(selectedSubStyleIds).includes(id.toString())
        )
      : [];
    accum.push({
      id: currValue,
      name,
      selectedSubStyles,
    });

    return accum;
  }, []);

export const getStyleNames = (styles, enums) =>
  styles.map((styleId) => enums.styles[styleId].name);

export const getRoomTypeName = (roomTypeId, enums) =>
  enums.roomTypes[roomTypeId]?.name;

export const getImageType = (image, enums) =>
  image.type === imageTypeKeys.room
    ? enums.roomTypes[image.roomTypeId]
    : findObjectById(image.itemClassId || 1, enums.itemClasses); // This mock '1' is only for development purposes, since seed II with type 'single' may not have itemClassId

export const getColors = (colors, enums) =>
  colors.map((colorId) => enums.colors[colorId]);

const getItemCategories = ({ categories }) => categories;
const getItemTypes = ({ itemTypes }) => itemTypes;
const getItemClasses = ({ itemClasses }) => itemClasses;

export const getItemClassesByStructure = createSelector(
  [getItemCategories, getItemTypes, getItemClasses],
  (categories, types, items) =>
    Object.values(categories).map((category) => ({
      ...category,
      types: Object.values(types)
        .filter(({ categoryId }) => category.id === categoryId)
        .map((type) => ({
          ...type,
          items: Object.values(items).filter(
            ({ itemTypeId }) => type.id === itemTypeId
          ),
        })),
    }))
);

export const getItemClassesWithItemTypes = createSelector(
  [getItemTypes, getItemClasses],
  (types, itemsClasses) => {
    const itemClassesCopy = { ...itemsClasses };
    const notSpecifiedItemClass = Object.values(itemClassesCopy).find(
      ({ name }) => name === 'Item Class Not Specified'
    );

    const itemClassesForSelect = Object.values(itemClassesCopy)
      .filter(({ id }) => id !== notSpecifiedItemClass.id)
      .map(({ name, id, itemTypeId }) => ({
        label: name,
        value: id,
        typeName: types[itemTypeId]?.name,
      }));

    const compare = (a, b) => {
      if (a.typeName > b.typeName) {
        return 1;
      }
      if (a.typeName < b.typeName) {
        return -1;
      }
      return 0;
    };

    itemClassesForSelect.sort(compare);

    //  Default Item Class
    itemClassesForSelect.unshift({
      label: notSpecifiedItemClass.name,
      value: notSpecifiedItemClass.id,
      typeName: 'NONE',
    });

    return itemClassesForSelect;
  }
);

export const selectFilterValues = createSelector(
  (requestData) => requestData,
  (requestData) => {
    const { requestColor, requestStyle } = requestData;
    return {
      colors: requestColor,
      styles: requestStyle,
      subStyles: {},
    };
  }
);
