import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { routesByName } from 'constants/routes';

const PrivateRoute = ({ children, ...rest }) => {
  const authenticated = Boolean(useSelector((state) => state.auth.user));

  return (
    <Route {...rest}>
      {authenticated ? children : <Redirect to={routesByName.home} />}
    </Route>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
