import room1 from 'assets/img/dashboard-page/showcase/room-image1.png';
import room2 from 'assets/img/dashboard-page/showcase/room-image3.png';
import room3 from 'assets/img/dashboard-page/showcase/room-image2.png';
import room4 from 'assets/img/dashboard-page/showcase/room-image4.png';
import lookBoard1 from 'assets/img/dashboard-page/showcase/lookboard1.png';
import lookBoard2 from 'assets/img/dashboard-page/showcase/lookboard2.png';
import lookBoard3 from 'assets/img/dashboard-page/showcase/lookboard3.png';

export const bannerTypeKeys = {
  color: 'color',
  image: 'image',
};

export const featuredTabKeys = {
  all: 'all',
  featured: 'featured',
};

export const dropItemType = {
  inspirationImages: 'inspirationImages',
  lookBoards: 'lookBoards',
};

export const pageDataFormFields = {
  pageName: 'pageName',
  subtitle: 'subtitle',
  isSubtitleActive: 'isSubtitleActive',
  metaTitle: 'metaTitle',
  urlKey: 'urlKey',
  metaDescription: 'metaDescription',
  description: 'description',
  facebook: 'facebook',
  instagram: 'instagram',
  pinterest: 'pinterest',
  linkedin: 'linkedin',
  twitter: 'twitter',
  displayName: 'displayName',
};

export const shareViaEmailFormFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  toFirstName: 'toFirstName',
  toEmailAddress: 'toEmailAddress',
  subject: 'subject',
  message: 'message',
};

export const placeholderImages = {
  rooms: [
    { id: 1, imgSrc: room1 },
    { id: 2, imgSrc: room2 },
    { id: 3, imgSrc: room3 },
    { id: 4, imgSrc: room4 },
  ],
  lookboards: [
    { id: 1, imgSrc: lookBoard1 },
    { id: 2, imgSrc: lookBoard2 },
    { id: 3, imgSrc: lookBoard3 },
  ],
};
