import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import customCheckbox from 'assets/img/ambassador-page/customCheckbox.svg';
import clsx from 'clsx';
import classes from './Interests.module.scss';

export const Interests = ({
  ambassadorInterests,
  handleInterests,
  selectedInterests,
}) => {
  const useStyles = makeStyles({
    icon: {
      borderRadius: 3,
      border: '1px solid #87BDC3',
      width: 18,
      height: 18,
      backgroundColor: '#fff',
      'input:hover ~ &': {
        backgroundColor: '#f8f8f8',
      },
    },
    checkedIcon: {
      backgroundColor: '#fff',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: `url(${customCheckbox})`,
        content: '""',
      },
    },
  });

  function StyledCheckbox(props) {
    // eslint-disable-next-line no-shadow
    const classes = useStyles();
    return (
      <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        {...props}
      />
    );
  }

  return (
    <div>
      {ambassadorInterests.map((interest, idx) => {
        return (
          /* eslint-disable-next-line react/no-array-index-key */
          <div key={idx} className={clsx('mb-2 mb-md-0', classes.checkbox)}>
            <FormControlLabel
              onChange={handleInterests}
              checked={selectedInterests.includes(interest)}
              label={interest}
              control={<StyledCheckbox value={interest} />}
            />
          </div>
        );
      })}
    </div>
  );
};

Interests.propTypes = {
  ambassadorInterests: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleInterests: PropTypes.func.isRequired,
  selectedInterests: PropTypes.arrayOf(PropTypes.string).isRequired,
};
