import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from 'components/ui/Button/Button';
import classes from 'modules/layout/ClaimImageModal/ClaimImageModal.module.scss';

const Step = ({ name, selected, options, onChange, onCancel, onNext }) => (
  <>
    <FormControl component="fieldset">
      <RadioGroup name={name} value={selected} onChange={onChange}>
        {options.map(({ label, value, description }) => (
          <div key={value} className={`${classes.item} py-1`}>
            <FormControlLabel
              value={value}
              control={<Radio color="primary" />}
              label={
                <>
                  <b>{label}</b>
                  <div className={classes.itemDescription}>{description}</div>
                </>
              }
            />
          </div>
        ))}
      </RadioGroup>
    </FormControl>
    <div className="flex-center mt-auto">
      <Button
        onClick={onCancel}
        variant="outlined"
        className={`${classes.btn} mx-1`}
        inline
      >
        Cancel
      </Button>
      <Button
        onClick={onNext}
        className={`${classes.btn} mx-1`}
        disabled={!selected}
        inline
      >
        Next
      </Button>
    </div>
  </>
);

Step.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

Step.defaultProps = {
  selected: null,
};

export default Step;
