import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextInput from 'components/inputs/TextInput/TextInput';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';

const PasswordInput = ({
  InputProps: { endAdornment, ...InputProps },
  ...props
}) => {
  const [type, setType] = useState('password');

  const toggleType = useCallback(() => {
    setType(type === 'text' ? 'password' : 'text');
  }, [type]);

  return (
    <TextInput
      {...props}
      type={type}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="end">
              <IconButton
                type="button"
                tabIndex="-1"
                color="grey"
                size="xs"
                onClick={toggleType}
              >
                <SpriteIcon
                  name={type === 'password' ? 'eye' : 'eye-crossed'}
                  size="sm"
                />
              </IconButton>
            </InputAdornment>
            {endAdornment}
          </>
        ),
        ...InputProps,
      }}
    />
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
};

PasswordInput.propTypes = {
  InputProps: PropTypes.shape({
    endAdornment: PropTypes.element,
  }),
};

PasswordInput.defaultProps = {
  InputProps: {},
};

export default PasswordInput;
