import React from 'react';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import { passwordInfoConfig } from 'modules/auth/signUp/constants';

const PasswordTooltip = () => (
  <InfoIconPopover
    iconProps={{
      className: 'ml-1',
      variant: 'inverted-grey',
      size: 'xs',
      color: 'secondary',
    }}
  >
    <p className="font-semi-bold text-sm font-normal mb-1">
      {passwordInfoConfig.title}
    </p>
    <ul className="list-dotted text-xs">
      {passwordInfoConfig.rules.map(({ id, rule }) => (
        <li key={id}>{rule}</li>
      ))}
    </ul>
  </InfoIconPopover>
);

export default PasswordTooltip;
