import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import NotificationItem from 'modules/layout/Header/NotificationItem/NotificationItem';
import classes from './Popovers.module.scss';

const NotificationPopover = ({
  open,
  onClose,
  anchorEl,
  notificationsList,
  setMessagesRead,
  hasMore,
  loading,
  loadMore,
  unreadNotificationsCount,
}) => {
  let lastMessages = [];
  let unreadMessages = [];

  lastMessages = useMemo(() => notificationsList?.data.slice(0, 2), [
    notificationsList,
  ]);
  unreadMessages = useMemo(
    () => notificationsList?.data.slice(0, unreadNotificationsCount),
    [notificationsList, unreadNotificationsCount]
  );

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading || unreadMessages.length === 0) {
      return;
    }
    loadMore();
  }, [hasMore, loading, unreadMessages, loadMore]);

  return (
    <BasicModal
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      component={Popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.root}
      classes={{ root: classes.notificationsRoot }}
    >
      <p className="font-title text-lg mb-2">Updates</p>
      {notificationsList && notificationsList?.data.length > 0 ? (
        <div>
          <div className="d-flex justify-content-between">
            {unreadMessages.length > 0 && (
              <p className="text-sm text-gray mb-2">New Messages</p>
            )}
            {unreadNotificationsCount > 0 && (
              <span className={classes.markAllRead} onClick={setMessagesRead}>
                Mark all messages as read
              </span>
            )}
          </div>
          <div className="mb-4">
            {unreadMessages.length > 0 ? (
              <CustomScrollBar autoHeightMax={500}>
                <CustomInfiniteScroll
                  isLoading={loading}
                  hasMore={hasMore}
                  loadMore={handleLoadMore}
                  initialLoad={false}
                  useWindow={false}
                >
                  {unreadMessages.map(
                    ({
                      id,
                      linkMessage,
                      title,
                      message,
                      createdAt,
                      media,
                      type,
                      slug,
                      notifiableId,
                    }) => (
                      <NotificationItem
                        key={id}
                        title={title}
                        link={linkMessage}
                        message={message}
                        time={createdAt}
                        slug={slug}
                        media={media}
                        type={type}
                        notifiableId={notifiableId}
                        onClosePopover={onClose}
                      />
                    )
                  )}
                </CustomInfiniteScroll>
              </CustomScrollBar>
            ) : (
              <p className="font-italic text-gray">
                You don&apos;t have unread notifications
              </p>
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <p className="text-sm text-gray mb-2">Last Messages</p>
            {lastMessages.map(
              ({
                id,
                linkMessage,
                title,
                message,
                createdAt,
                media,
                type,
                slug,
                notifiableId,
              }) => (
                <NotificationItem
                  key={id}
                  title={title}
                  link={linkMessage}
                  message={message}
                  slug={slug}
                  time={createdAt}
                  media={media}
                  type={type}
                  notifiableId={notifiableId}
                  onClosePopover={onClose}
                />
              )
            )}
          </div>
        </div>
      ) : (
        <div className={classes.noMessagesPlug}>
          <p className="text-sm text-gray mb-2">
            You don&apos;t have new notifications
          </p>
        </div>
      )}
    </BasicModal>
  );
};

NotificationPopover.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  notificationsList: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        id: PropTypes.number,
        linkMessage: PropTypes.string,
        media: PropTypes.shape({
          hash: PropTypes.string,
          id: PropTypes.number,
          type: PropTypes.string,
          userId: PropTypes.number,
        }),
        message: PropTypes.string,
        notifiableId: PropTypes.number,
        readAt: PropTypes.number,
        title: PropTypes.string,
        type: PropTypes.string,
      })
    ).isRequired,
    unread: PropTypes.number,
  }),
  setMessagesRead: PropTypes.func.isRequired,
  unreadNotificationsCount: PropTypes.number,
};

NotificationPopover.defaultProps = {
  anchorEl: null,
  notificationsList: null,
  unreadNotificationsCount: null,
};

export default NotificationPopover;
