import React from 'react';
import PropTypes from 'prop-types';
import modalImg from 'assets/img/ambassador-page/request-modal.jpg';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './RequestSentModal.module.scss';

const RequestSentModal = ({ open, onClose }) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      classes={{ root: 'p-0' }}
    >
      <div className="d-flex flex-column flex-md-row">
        <div className={classes.imgContainer}>
          <img src={modalImg} alt="" />
        </div>
        <div className={classes.textContainer}>
          <h3 className={classes.title}>
            Thank you <br /> for applying!
          </h3>
          <p className={classes.description}>
            We will review your application and be back in touch soon!
          </p>
          <div className={classes.banner}>
            <div className="position-relative text-center mb-1">
              <span className={classes.bannerTitle}>Pin The Look</span>
              <div className={classes.crossline} />
            </div>
            <p className={classes.bannerSubtitle}>
              <span className="primary-color">DECORATING THE WORLD</span>{' '}
              TOGETHER
            </p>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

RequestSentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RequestSentModal;
