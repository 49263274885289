import {
  REPORT_SET_CLAIM_IMAGE_ID,
  REPORT_SET_REASON,
  REPORT_SET_SUB_REASON,
  REPORT_TOGGLE_CLAIM_IMAGE_MODAL,
} from 'modules/report/store/constants';

const initialState = {
  reason: null,
  subReason: null,
  claimImageModalOpen: false,
  imageId: null,
};

const reportReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case REPORT_TOGGLE_CLAIM_IMAGE_MODAL: {
      return {
        ...state,
        claimImageModalOpen: payload,
      };
    }
    case REPORT_SET_CLAIM_IMAGE_ID: {
      return {
        ...state,
        imageId: payload,
      };
    }
    case REPORT_SET_REASON: {
      return {
        ...state,
        reason: payload,
      };
    }
    case REPORT_SET_SUB_REASON: {
      return {
        ...state,
        subReason: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reportReducer;
