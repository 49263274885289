import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { signUpFormFields } from 'modules/auth/signUp/constants';
import { supportFormFields } from 'modules/support/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import TextAreaWrapper from 'components/finalFormWrappers/TextAreaWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import tradeOpportunitiesValidator from 'modules/ambassadors/TradeOpportunities/tradeOpportunitiesValidator';
import womanImg from 'assets/img/trade-opportunities-woman.png';
import currentUserService from 'modules/currentUser/currentUserService';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import classes from './TradeOpportunities.module.scss';

const TradeOpportunitiesContainer = () => {
  const user = useSelector((state) => state.auth.user);

  const history = useHistory();
  const [initialFormValues] = useState({
    [signUpFormFields.firstName]: user?.firstName,
    [signUpFormFields.lastName]: user?.lastName,
    [signUpFormFields.businessName]: user?.business?.name,
    [signUpFormFields.businessUrl]: user?.business?.url,
    [supportFormFields.subject]: 'Trade Opportunities',
    [supportFormFields.message]:
      'Please send me more information about upcoming Trade Opportunities!',
  });

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const submitForm = useCallback(async (formValues) => {
    try {
      await currentUserService.sendTradeOpportunities(formValues);
      successToastr('Success', 'Your message successfully sent');
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  return (
    <div className={classes.contentWrapper}>
      <div className="d-flex align-items-center mb-4">
        <IconButton variant="inverted-white" size="sm" onClick={goBack}>
          <SpriteIcon name="left-arrow" size="sm" />
        </IconButton>
        <span className="primary-color text-sm ml-1">Back</span>
      </div>
      <h1 className={`${classes.title} mb-2`}>TRADE Opportunities</h1>
      <p className={`${classes.subTitle} mb-3`}>
        For Home Furnishing Retailers, Manufacturers & Artisans
      </p>
      <div className="background-white p-5">
        <h2
          className="font-title text-lg text-uppercase mb-2"
          style={{ lineHeight: 1.5 }}
        >
          discover Pin the Look’s <br />
          Trade opportunities.
        </h2>
        <div className="d-flex">
          <div className="flex-fill" style={{ marginRight: 160 }}>
            <Form
              initialValues={initialFormValues}
              validate={tradeOpportunitiesValidator}
              onSubmit={submitForm}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="row">
                    <div className="col-5">
                      <Field
                        required
                        label="First Name"
                        name={signUpFormFields.firstName}
                        component={TextInputWrapper}
                      />
                    </div>
                    <div className="col-2" />
                    <div className="col-5">
                      <Field
                        required
                        label="Last Name"
                        name={signUpFormFields.lastName}
                        component={TextInputWrapper}
                      />
                    </div>
                    <div className="col-5">
                      <Field
                        label="Company Name"
                        name={signUpFormFields.businessName}
                        component={TextInputWrapper}
                      />
                    </div>
                    <div className="col-2" />
                    <div className="col-5">
                      <Field
                        label="Company URL"
                        name={signUpFormFields.businessUrl}
                        component={TextInputWrapper}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        label="Subject"
                        name={supportFormFields.subject}
                        component={TextInputWrapper}
                      />
                    </div>
                    <div className="col-12">
                      <div className="text-sm text-gray font-semi-bold mb-1">
                        Add a Message to learn more about Trade Opportunities
                      </div>
                      <Field
                        required
                        name={supportFormFields.message}
                        component={TextAreaWrapper}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <FinalFormSubmitButton inline>Send</FinalFormSubmitButton>
                  </div>
                </form>
              )}
            />
          </div>
          <div style={{ minWidth: 700, width: 700 }}>
            <div className={classes.banner}>
              <div className={classes.bannerShape} />
              <div className={classes.bannerTextWrapper}>
                <div className={classes.bannerQuote}>
                  ‘’So many <br />
                  possibilities‘’
                </div>
                <div className={classes.bannerAuthor}>Janice l. Ambassador</div>
              </div>
              <img src={womanImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeOpportunitiesContainer;
