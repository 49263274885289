export const RTL_SET_IMAGES = 'RTL_SET_IMAGES';
export const RTL_UPDATE_IMAGES = 'RTL_UPDATE_IMAGES';
export const RTL_UPDATE_REQUEST_DATA = 'RTL_UPDATE_REQUEST_DATA';
export const RTL_UPDATE_PRIORITY_ITEM = 'RTL_UPDATE_PRIORITY_ITEM';
export const RTL_ADD_PRIORITY_ITEM = 'RTL_ADD_PRIORITY_ITEM';
export const RTL_UPDATE_II_SEARCH_PARAMS = 'RTL_UPDATE_II_SEARCH_PARAMS';
export const RTL_RESET_REQUEST_DATA = 'RTL_RESET_REQUEST_DATA';
export const RTL_RESET_REQUEST_FORM = 'RTL_RESET_REQUEST_FORM';
export const RTL_RESET_STATE = 'RTL_RESET_STATE';
export const RTL_UPDATE_FILTER_VALUES = 'RTL_UPDATE_FILTER_VALUES';
export const RTL_RESET_FILTER_VALUES = 'RTL_RESET_FILTER_VALUES';
export const RTL_UPDATE_ITEMS_FOR_TABS = 'RTL_UPDATE_ITEMS_FOR_TABS';
export const RTL_DELETE_PRIORITY_ITEM = 'RTL_DELETE_PRIORITY_ITEM';
