import { errorMessages, signInFormFields } from 'modules/auth/signIn/constants';

const emailPattern = /.+@.+..+/;

const signInValidator = ({
  [signInFormFields.email]: email,
  [signInFormFields.password]: password,
}) => {
  const errors = {};

  if (!email) {
    errors.email = errorMessages.required;
  } else if (!emailPattern.test(email)) {
    errors.email = errorMessages.emailInvalid;
  }

  if (!password) {
    errors.password = errorMessages.required;
  } else if (password.length < 8) {
    errors.password = errorMessages.passwordInvalid;
  }

  return errors;
};

export default signInValidator;
