import {
  LAYOUT_SUGGEST_STYLE_QUIZ,
  LAYOUT_TOGGLE_ADD_IMAGE_MODAL,
  LAYOUT_TOGGLE_CHOOSE_METHOD_MODAL,
  LAYOUT_TOGGLE_HOW_IT_WORKS_MODAL,
  LAYOUT_TOGGLE_PIN_MEDIA_MODAL,
  LAYOUT_TOGGLE_QUIZ_VIEWED,
  LAYOUT_TOGGLE_STYLE_QUIZ,
  LAYOUT_TOGGLE_TERMS_MODAL,
  LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL,
  LAYOUT_TOGGLE_VERIFICATION_MODAL,
  SET_STYLE_QUIZ_DATA,
  LAYOUT_TOGGLE_HOW_IT_WORKS_VIDEO_MODAL,
  LAYOUT_TOGGLE_PREFERENCES_REMIND_MODAL,
  LAYOUT_TOGGLE_YOUTUBE_VIDEO_MODAL,
  FORCE_REGISTER_RTL_MODAL,
} from 'modules/layout/store/constants';
import { localStorageKeys } from '../../app/constants';

const initialState = {
  addImageModal: false,
  underConstructionModalOpen: false,
  termsModalOpen: false,
  howItWorksModalOpen: false,
  verificationRemindModalOpen: false,
  pinMediaModal: false,
  suggestStyleQuiz: false,
  chooseMethodModal: {
    isVisible: false,
    type: 'both',
  },
  styleQuizResult: {
    isShown: false,
    data: {},
  },
  quizViewed: !!localStorage.getItem(localStorageKeys.quizViewed),
  howItWorksVideoModalOpen: false,
  preferencesRemindModalOpen: false,
  youtubeVideoModalOpen: false,
  forceRegisterRTLModal: false,
};

const layoutReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case LAYOUT_TOGGLE_ADD_IMAGE_MODAL: {
      return { ...state, addImageModal: payload };
    }
    case LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL: {
      return { ...state, underConstructionModalOpen: payload };
    }
    case LAYOUT_TOGGLE_TERMS_MODAL: {
      return {
        ...state,
        termsModalOpen: payload,
      };
    }
    case LAYOUT_TOGGLE_CHOOSE_METHOD_MODAL: {
      return {
        ...state,
        chooseMethodModal: {
          isVisible: payload.isVisible,
          type: payload.type ? payload.type : state.chooseMethodModal.type,
        },
      };
    }
    case LAYOUT_TOGGLE_HOW_IT_WORKS_MODAL: {
      return {
        ...state,
        howItWorksModalOpen: payload,
      };
    }
    case LAYOUT_TOGGLE_VERIFICATION_MODAL: {
      return {
        ...state,
        verificationRemindModalOpen: payload,
      };
    }
    case LAYOUT_TOGGLE_PIN_MEDIA_MODAL: {
      return {
        ...state,
        pinMediaModal: payload,
      };
    }
    case LAYOUT_TOGGLE_QUIZ_VIEWED: {
      localStorage.setItem(localStorageKeys.quizViewed, payload);
      return {
        ...state,
        quizViewed: payload,
      };
    }
    case LAYOUT_SUGGEST_STYLE_QUIZ: {
      return {
        ...state,
        suggestStyleQuiz: payload,
      };
    }
    case LAYOUT_TOGGLE_STYLE_QUIZ:
      return {
        ...state,
        styleQuizResult: {
          ...state.styleQuizResult,
          isShown: payload,
        },
      };
    case SET_STYLE_QUIZ_DATA: {
      return {
        ...state,
        styleQuizResult: {
          ...state.styleQuizResult,
          data: payload,
        },
      };
    }
    case LAYOUT_TOGGLE_HOW_IT_WORKS_VIDEO_MODAL: {
      return {
        ...state,
        howItWorksVideoModalOpen: payload,
      };
    }
    case LAYOUT_TOGGLE_PREFERENCES_REMIND_MODAL: {
      return {
        ...state,
        preferencesRemindModalOpen: payload,
      };
    }
    case LAYOUT_TOGGLE_YOUTUBE_VIDEO_MODAL: {
      return {
        ...state,
        youtubeVideoModalOpen: payload,
      };
    }
    case FORCE_REGISTER_RTL_MODAL: {
      return {
        ...state,
        forceRegisterRTLModal: payload,
      };
    }
    default:
      return state;
  }
};

export default layoutReducer;
