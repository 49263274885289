import {
  RTL_SET_IMAGES,
  RTL_UPDATE_IMAGES,
  RTL_UPDATE_REQUEST_DATA,
  RTL_UPDATE_PRIORITY_ITEM,
  RTL_UPDATE_II_SEARCH_PARAMS,
  RTL_RESET_REQUEST_DATA,
  RTL_RESET_REQUEST_FORM,
  RTL_RESET_STATE,
  RTL_UPDATE_FILTER_VALUES,
  RTL_RESET_FILTER_VALUES,
  RTL_UPDATE_ITEMS_FOR_TABS,
  RTL_DELETE_PRIORITY_ITEM,
} from 'modules/requestTheLook/store/constants';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';

export const initialFilterValues = {
  [filterTabKeys.styles]: [],
  [filterTabKeys.subStyles]: {},
  [filterTabKeys.roomTypes]: [],
  [filterTabKeys.colors]: [],
  [filterTabKeys.itemClasses]: [],
};

const initialImageSearchParams = {
  source: imageSourceKeys.all,
  type: imageTypeKeys.room,
  search: '',
  offset: 0,
};

const initialRequestFormData = {
  title: '',
  items: [],
  itemsForTabs: [],
  roomType: null,
  message: '',
  requestColor: [],
  requestStyle: [],
};

const initialRequestData = {
  inspirationImageId: null,
  requestType: imageTypeKeys.room,
  ...initialRequestFormData,
};

const initialState = {
  inspirationImageSearchParams: initialImageSearchParams,
  filterValues: initialFilterValues,
  imageList: [],
  requestData: initialRequestData,
};

const requestThelookReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case RTL_SET_IMAGES: {
      return {
        ...state,
        imageList: payload,
      };
    }
    case RTL_UPDATE_IMAGES: {
      return {
        ...state,
        imageList: [...state.imageList, ...payload],
      };
    }
    case RTL_UPDATE_REQUEST_DATA: {
      return {
        ...state,
        requestData: {
          ...state.requestData,
          ...payload,
        },
      };
    }
    case RTL_UPDATE_PRIORITY_ITEM: {
      return {
        ...state,
        requestData: {
          ...state.requestData,
          items: [payload, ...state.requestData.items],
        },
      };
    }
    case RTL_DELETE_PRIORITY_ITEM: {
      const newItems = [...state.requestData.items];
      const index = newItems.findIndex((item) => item.value === payload.value);
      newItems.splice(index, 1);
      return {
        ...state,
        requestData: {
          ...state.requestData,
          items: newItems,
        },
      };
    }
    case RTL_RESET_REQUEST_DATA: {
      return {
        ...state,
        requestData: { ...initialRequestData },
      };
    }
    case RTL_RESET_REQUEST_FORM: {
      return {
        ...state,
        requestData: {
          ...state.requestData,
          ...initialRequestFormData,
        },
      };
    }
    case RTL_UPDATE_II_SEARCH_PARAMS: {
      return {
        ...state,
        inspirationImageSearchParams: {
          ...state.inspirationImageSearchParams,
          ...payload,
        },
      };
    }
    case RTL_RESET_STATE: {
      return { ...initialState };
    }
    case RTL_UPDATE_FILTER_VALUES: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          ...payload,
        },
      };
    }
    case RTL_RESET_FILTER_VALUES: {
      return {
        ...state,
        filterValues: { ...initialFilterValues },
      };
    }
    case RTL_UPDATE_ITEMS_FOR_TABS: {
      if (Array.isArray(payload)) {
        return {
          ...state,
          requestData: {
            ...state.requestData,
            itemsForTabs: [...payload, ...state.requestData.itemsForTabs],
          },
        };
      }

      return {
        ...state,
        requestData: {
          ...state.requestData,
          itemsForTabs: [payload, ...state.requestData.itemsForTabs],
        },
      };
    }

    default:
      return state;
  }
};

export default requestThelookReducer;
