import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import classes from './AmbassadorMenu.module.scss';

const AmbassadorMenu = ({ open, onClose, anchorEl, onMouseEnter }) => {
  const theme = createMuiTheme({});
  const user = useSelector((state) => state.auth.user);

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        onClose(e);
      },
      onMouseEnter,
    },
  };

  const menuItems = useMemo(() => {
    return [
      {
        title: 'Visit Showcases',
        link: routesByName.getTheLook.ambassadors.index,
      },
      {
        title: 'Become an Ambassador',
        link: user?.ambassador
          ? routesByName.ambassador.becomeAmbassador
          : routesByName.ambassador.index,
      },
    ];
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <Menu
        anchorEl={anchorEl}
        open={open}
        id="dashboardMenu"
        onClose={onClose}
        getContentAnchorEl={null}
        classes={{ list: classes.menuList }}
        PopoverClasses={{ paper: classes.menuPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          {menuItems.map((item) => (
            <MenuItem
              onClick={onClose}
              className={classes.menuItem}
              key={item.title}
            >
              <Link to={item.link}>{item.title}</Link>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </ThemeProvider>
  );
};

AmbassadorMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

AmbassadorMenu.defaultProps = {
  anchorEl: null,
};

export default AmbassadorMenu;
