import { setCurrentConversationAction } from 'modules/conversation/store/actions';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import MessageItem from 'modules/layout/Header/MessageItem/MessageItem';
import { Link, useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAvatarUrl } from 'modules/app/store/selectors';
import clsx from 'clsx';
import classes from './Popovers.module.scss';

const DialogsPopover = ({ open, onClose, anchorEl }) => {
  const dispatch = useDispatch();

  const userAvatarUrl = getUserAvatarUrl(
    useSelector((state) => state.app.config)
  );
  const {
    unreadConversations: conversations,
    unreadConversationsById: conversationsById,
  } = useSelector((state) => state.conversation);
  const usersLibrary = useSelector((state) => state.users.library);

  const history = useHistory();
  const handleSelect = useCallback(
    (id) => {
      dispatch(setCurrentConversationAction(Number(id)));
      history.push(routesByName.dashboard.messages);
      onClose();
    },
    [dispatch, onClose, history]
  );
  return (
    <BasicModal
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      component={Popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={clsx(classes.root)}
      classes={{ root: classes.dialogsRoot }}
    >
      <p className="font-title text-lg mb-2 ml-3">
        Dialogs ({conversations.length})
      </p>

      <p className="text-sm text-gray mb-2 ml-3">New</p>
      <CustomScrollBar autoHeightMin={350} autoHeightMax={450}>
        {conversations.map((id) => {
          const {
            interlocutor: { userId },
            lastMessage: { body, sentAt },
          } = conversationsById[id];
          return (
            <MessageItem
              key={id}
              id={id}
              date={sentAt}
              text={body}
              user={usersLibrary[userId]}
              userAvatarUrl={userAvatarUrl}
              handleSelect={handleSelect}
              onClick={onClose}
            />
          );
        })}
      </CustomScrollBar>
      <Link
        to={routesByName.dashboard.messages}
        className={`${classes.dialogLink} mt-2 ml-3`}
        onClick={onClose}
      >
        Go to All Dialogs
      </Link>
    </BasicModal>
  );
};

DialogsPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

DialogsPopover.defaultProps = {
  anchorEl: null,
};

export default DialogsPopover;
