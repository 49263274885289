import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

export const notificationsService = {
  getNotifications(offset) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/notifications?offset=${offset}`
    );
  },

  setMessagesRead() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/notifications/read`);
  },
};
