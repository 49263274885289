import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './DescribeYourself.module.scss';

export const DescribeYourself = ({
  handleDescribeYourself,
  title,
  description,
  imageUrl,
  titleSvg,
  selectedDescription,
}) => {
  return (
    <div
      className={clsx(classes.root, {
        [classes.selected]: title === selectedDescription,
      })}
      onClick={() => handleDescribeYourself(title)}
    >
      <div className={classes.cardImageWrapper}>
        <img className={classes.cardImage} src={imageUrl} alt={title} />
      </div>
      <div className={classes.contentWrapper}>
        <div className="d-flex align-items-center mb-2">
          <img style={{ width: 25, height: 25 }} src={titleSvg} alt="" />
          <span className={classes.cardTitle}>{title}</span>
        </div>
        <div className={classes.cardDescription}>{description}</div>
      </div>
    </div>
  );
};

DescribeYourself.propTypes = {
  handleDescribeYourself: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  titleSvg: PropTypes.string.isRequired,
  selectedDescription: PropTypes.string.isRequired,
};
