import appConfig from 'constants/appConfig.js';

const notificationsChannelPrefix = appConfig.broadcastNotificationsChannelName;
const conversationsChannelPrefix = appConfig.broadcastMessengerChannelName;

const channelsByName = {
  conversationById: (id) =>
    id ? `${conversationsChannelPrefix}.conversation.${id}` : null,
  conversationsByUser: (id) =>
    id ? `${conversationsChannelPrefix}.user.${id}.conversations` : null,
  notificationsById: (id) =>
    id ? `${notificationsChannelPrefix}.user.${id}` : null,
};

export default channelsByName;
