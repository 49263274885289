import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { useSelector } from 'react-redux';
import { userStatus } from 'modules/app/constants';

const UncompletedUserRedirect = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user && user.status !== userStatus.complete) {
      history.push(routesByName.ambassador.becomeAmbassador);
    }
    // eslint-disable-next-line
  }, [pathname, user]);

  return null;
};

export default UncompletedUserRedirect;
