import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import stylesOptionShape from 'components/PreferenceQuiz/propTypes/stylesOptionShape';
import {
  getStyleImgPreviewUrl,
  getStyleImgUrl,
} from 'modules/app/store/selectors';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from 'components/PreferenceQuiz/StylesCard/StylesCard.module.scss';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const StylesCard = ({
  option: {
    id,
    name,
    media: { hash },
  },
  selected,
  onChange,
  className,
  onOpenPreviewModal,
  isQuiz,
}) => {
  const config = useSelector((state) => state.app.config);
  const styleImgUrl = useMemo(() => getStyleImgUrl(config), [config]);
  const styleImgPreviewUrl = useMemo(() => getStyleImgPreviewUrl(config), [
    config,
  ]);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const handleSelect = useCallback(() => {
    onChange(id, !selected);
  }, [onChange, id, selected]);

  const handleOpenPreview = useCallback(() => {
    const previewUrl = styleImgPreviewUrl.replace(urlPlaceholders.hash, hash);
    onOpenPreviewModal(previewUrl);
  }, [onOpenPreviewModal, styleImgPreviewUrl, hash]);

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.selected]: selected,
      })}
    >
      <div className={classes.imgContainer} onClick={handleSelect}>
        <img src={styleImgUrl.replace(urlPlaceholders.hash, hash)} alt={name} />
      </div>
      <div
        className={clsx('d-flex align-items-center justify-content-center', {
          'p-3': !matchesMediaQuery,
          'p-1': matchesMediaQuery,
        })}
        onClick={handleSelect}
      >
        <div className="d-flex align-items-center">
          <div>
            {selected ? (
              <SpriteIcon name="checked" size="md" />
            ) : (
              <div className={classes.unselectedIcon} />
            )}
          </div>
          {!isQuiz && <div className="px-2">{name}</div>}
        </div>
      </div>
      <IconButton
        className={classes.previewBtn}
        type="button"
        onClick={handleOpenPreview}
      >
        <SpriteIcon name="loupe" size="sm" />
      </IconButton>
    </div>
  );
};

StylesCard.propTypes = {
  option: stylesOptionShape.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpenPreviewModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  isQuiz: PropTypes.bool,
};

StylesCard.defaultProps = {
  className: '',
  isQuiz: false,
};

export default StylesCard;
