import * as Sentry from '@sentry/react';
import appConfig from 'constants/appConfig';

if (!appConfig.isDev) {
  Sentry.init({
    dsn: appConfig.sentryDSN,
    environment: appConfig.nodeEnv,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        // maskAllInputs: false,
      }),
    ],
  });
}

export default Sentry;
