import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import { routesByName } from 'constants/routes';
import SignUpContainer from 'modules/auth/signUp/SignUpContainer';
import SignInContainer from 'modules/auth/signIn/SignInContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import classes from './AuthModal.module.scss';
import SetStylesModal from '../../../auth/signUp/components/SetStylesModal/SetStylesModal';
import mixAndMatchService from '../../../mixAndMatch/mixAndMatchService';
import successToastr from '../../../../libs/toastr/successToastr';
import errorToastr from '../../../../libs/toastr/errorToastr';
import {
  toggleStyleQuizResultModalAction,
  toggleSuggestStyleQuizAction,
} from '../../store/actions';
import { toggleDailyVerificationModalAction } from '../../../auth/store/actions';

const cannotClose = [];
const AuthModal = ({ action, onClose, toggleHowItWorksModal }) => {
  const { state, pathname } = useLocation();
  const history = useHistory();
  const currentUser = useSelector((dataState) => dataState.auth.user);
  const reopenTimeout = useRef(null);
  const dispatch = useDispatch();

  const quizData = useSelector(
    (reduxState) => reduxState.layout.styleQuizResult.data
  );
  const user = useSelector((reduxState) => reduxState.auth.user);

  const isStyleQuiz = useMemo(() => Boolean(quizData), [quizData]);

  const [stylesModalOpen, setStylesModalOpen] = useState(false);

  const handleClose = useCallback(() => {
    if (state?.reopen) {
      reopenTimeout.current = setTimeout(() => {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
          { title: 'REGISTER NOW' }
        );
      }, state.timeout);
    }
    if (
      pathname === routesByName.faqs ||
      pathname === routesByName.requestTheLook.submit
    ) {
      onClose();
      return;
    }
    if (!cannotClose.includes(pathname) && !currentUser) {
      onClose();
    } else {
      history.push(routesByName.home);
    }
  }, [currentUser, history, onClose, pathname, state]);

  useEffect(() => {
    if (currentUser && reopenTimeout.current) {
      clearTimeout(reopenTimeout.current);
    }
  }, [currentUser]);

  useEffect(() => {
    clearTimeout(reopenTimeout.current);
  }, [pathname]);

  /*  const handleHowItWorksModalOpen = useCallback(() => {
    handleClose();
    toggleHowItWorksModal(true);
  }, [handleClose, toggleHowItWorksModal]);

  const handleGoToHomepage = useCallback(() => {
    handleClose();
    history.push(routesByName.home);
  }, [handleClose, history]); */

  const onApplyStyles = useCallback(async () => {
    setStylesModalOpen(false);
    onClose();
    const selectedMixMatchIds = state?.ids;
    if (selectedMixMatchIds) {
      const path = pathname.split('/');
      const imageId = path[path.length - 1];
      try {
        await mixAndMatchService.saveMixAndMatch(imageId, selectedMixMatchIds);
        successToastr(
          'Success',
          'Your Mix & Match has been successfully saved'
        );
      } catch (e) {
        errorToastr('Error', e.message);
      }
    }
    dispatch(toggleDailyVerificationModalAction(currentUser));
    if (!pathname === routesByName.requestTheLook.submit)
      if (pathname === routesByName.ambassador.index) {
        history.push(routesByName.ambassador.index);
      } else if (pathname === routesByName.styleQuiz) {
        history.push(routesByName.styleQuiz);
      } else {
        if (!isStyleQuiz) {
          dispatch(toggleSuggestStyleQuizAction(true));
        }
        history.push(routesByName.getTheLook.index);
        dispatch(toggleStyleQuizResultModalAction(true));
      }
  }, [currentUser, dispatch, history, isStyleQuiz, onClose, pathname, state]);

  return (
    <>
      <BasicModal
        open={Boolean(action)}
        onClose={handleClose}
        classes={{
          root: 'p-0',
          closeBtn: classes.closeBtn,
        }}
        maxWidth="md"
        scroll="body"
        style={{ zIndex: 1299 }}
      >
        <div className="d-flex">
          <div className={classes.formContainer}>
            <Logo className={classes.logo} />
            {action === routesByName.auth.signIn && (
              <SignInContainer onCloseModal={onClose} />
            )}
            {action === routesByName.auth.signUp && (
              <SignUpContainer
                setStylesModalOpen={setStylesModalOpen}
                onCloseModal={onClose}
              />
            )}
          </div>
          {/* {!matchesMediaQuery && (
          <div className="w-50">
            <div className={classes.imgContainer}>
              <img src={authModalImg} alt="" />
              <div className={classes.banner}>
                <div className="position-relative text-center mb-1">
                  <span className={classes.bannerTitle}>Pin The Look</span>
                  <div className={classes.crossline} />
                </div>
                <p className={classes.bannerSubtitle}>
                  <span className="primary-color">DECORATING THE WORLD</span>{' '}
                  TOGETHER
                </p>
              </div>
            </div>
            {action === routesByName.auth.signUp && (
              <div className="d-flex flex-column align-items-center mt-3">
                <Button
                  className={classes.homepageBtn}
                  onClick={handleGoToHomepage}
                >
                  Homepage
                </Button>
                <ButtonHowItWorks
                  text="PIN THE LOOK"
                  onClick={handleHowItWorksModalOpen}
                />
                <button
                  type="button"
                  className={classes.quizBtn}
                  onClick={handleOpenStyleQuizPage}
                >
                  <div>
                    <div className={classes.subTitle}>
                      <div className={classes.line} />
                      <span className="px-1">Take the</span>
                      <div className={classes.line} />
                    </div>
                    <p className="text-sm">Style Quiz</p>
                  </div>

                  <SpriteIcon name="play-icon" size="lg" />
                </button>
              </div>
            )}
          </div>
        )} */}
        </div>
      </BasicModal>
      <SetStylesModal open={stylesModalOpen} onApplyStyles={onApplyStyles} />
    </>
  );
};

AuthModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  toggleHowItWorksModal: PropTypes.func.isRequired,
  action: PropTypes.string,
};

AuthModal.defaultProps = {
  action: undefined,
};

export default AuthModal;
