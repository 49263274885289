import BaseAxiosInstance from 'libs/axios/BaseAxiosInstance';
import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';
import { localStorageKeys } from 'modules/app/constants';

const urlPrefix = '/v1';

const authService = {
  signUp(formValues) {
    const currentUrl = window.location.href;
    const domainName = new URL(currentUrl).hostname;

    return BaseAxiosInstance.post(`${urlPrefix}/auth/sign-up`, {
      ...formValues,
      domain: domainName,
    });
  },

  verifyEmail(userId, token) {
    return BaseAxiosInstance.get(
      `${urlPrefix}/auth/email-verification/${userId}/${token}`
    );
  },

  async login(formValues) {
    const {
      result: {
        token_type: tokenType,
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    } = await BaseAxiosInstance.post(`${urlPrefix}/auth/login`, formValues);

    this.setAccessToken(`${tokenType} ${accessToken}`);
    this.setRefreshToken(refreshToken);
  },

  async loginByInvitation(formValues) {
    const {
      result: {
        token_type: tokenType,
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    } = await BaseAxiosInstance.post(
      `${urlPrefix}/auth/login/by-invitation-code`,
      formValues
    );

    this.setAccessToken(`${tokenType} ${accessToken}`);
    this.setRefreshToken(refreshToken);
  },

  forgotPassword(data) {
    return BaseAxiosInstance.post(`${urlPrefix}/auth/forgot-password`, data);
  },

  resetPassword(token, formValues) {
    return BaseAxiosInstance.post(
      `${urlPrefix}/auth/password-reset/${token}`,
      formValues
    );
  },

  getAccessToken() {
    return localStorage.getItem(localStorageKeys.accessToken);
  },

  setAccessToken(token) {
    localStorage.setItem(localStorageKeys.accessToken, token);
  },

  getVerifyModalWasShown() {
    return localStorage.getItem(localStorageKeys.verifyModalWasShown);
  },

  setVerifyModalWasShown(timestamp) {
    return localStorage.setItem(
      localStorageKeys.verifyModalWasShown,
      timestamp
    );
  },

  getRefreshToken() {
    return localStorage.getItem(localStorageKeys.refreshToken);
  },

  setRefreshToken(token) {
    localStorage.setItem(localStorageKeys.refreshToken, token);
  },

  async refreshToken() {
    const token = this.getRefreshToken();
    if (!token) {
      throw new Error('Refresh is impossible');
    }

    const {
      result: {
        token_type: tokenType,
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    } = await BaseAxiosInstance.post(`${urlPrefix}/auth/refresh-token`, {
      refreshToken: token,
    });

    this.setAccessToken(`${tokenType} ${accessToken}`);
    this.setRefreshToken(refreshToken);
  },

  clearAuthTokens() {
    localStorage.removeItem(localStorageKeys.accessToken);
    localStorage.removeItem(localStorageKeys.refreshToken);
    localStorage.removeItem(localStorageKeys.verifyModalWasShown);
  },

  logout() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/auth/logout`).then(() => {
      this.clearAuthTokens();
    });
  },

  changePassword(passwordData) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/auth/change-password`,
      passwordData
    );
  },
};

export default authService;
