import PropTypes from 'prop-types';
import { mediaShape } from 'modules/media/propTypes';

export const userShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  avatar: mediaShape,
  preferences: PropTypes.shape({}),
  hasVerifiedEmail: PropTypes.oneOf([0, 1]),
  countryId: PropTypes.number,
});
