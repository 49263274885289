import getPreviewImg from 'assets/img/home-page/sections-preview/get.svg';
import requestPreviewImg from 'assets/img/home-page/sections-preview/request.svg';
import curatePreviewImg from 'assets/img/home-page/sections-preview/curate.svg';
import comparePreviewImg from 'assets/img/home-page/sections-preview/compare.svg';
import shopPreviewImg from 'assets/img/home-page/sections-preview/shop.svg';

import select1Img from 'assets/img/about-us-page/select-images/image-1.jpg';
import select2Img from 'assets/img/about-us-page/select-images/image-2.jpg';
import select3Img from 'assets/img/about-us-page/select-images/image-3.jpg';
import select4Img from 'assets/img/about-us-page/select-images/image-4.jpg';

import lookBoardImg from 'assets/img/about-us-page/receive-images/look-board.jpg';
import streamImg from 'assets/img/about-us-page/receive-images/stream.png';
import mixAndMatchImg from 'assets/img/about-us-page/receive-images/mix-match.jpg';

export const howItWorksModalConfig = [
  {
    id: 1,
    title: 'Get the Look',
    description:
      'Browse popular curated images via Look Boards and Products Streams',
    imgUrl: getPreviewImg,
  },
  {
    id: 2,
    title: 'Request the Look',
    description:
      'Request the Look of your favorite inspiration images to get Look Board recommendations from our Ambassadors',
    imgUrl: requestPreviewImg,
  },
  {
    id: 3,
    title: 'Curate the Look',
    description:
      'Choose from thousands of products in our database to curate the look of your favorite items yourself!',
    imgUrl: curatePreviewImg,
  },
  {
    id: 4,
    title: 'Compare the Look',
    description:
      'Use our Mix & Match tool to easily visualize your favorite items together',
    imgUrl: comparePreviewImg,
  },
  {
    id: 5,
    title: 'Shop the Look',
    description:
      'Get the Look you Love at your price point and enjoy special offers from in Network vendors.',
    imgUrl: shopPreviewImg,
  },
];

export const selectImagesModalConfig = [
  {
    id: 1,
    imgUrl: select1Img,
  },
  {
    id: 2,
    imgUrl: select2Img,
  },
  {
    id: 3,
    imgUrl: select3Img,
  },
  {
    id: 4,
    imgUrl: select4Img,
  },
];

export const receiveImagesModalConfig = [
  {
    id: 1,
    title: 'Look Board',
    description: 'View Look Board Recommendations',
    imgUrl: lookBoardImg,
  },
  {
    id: 2,
    title: 'Product Stream',
    description: 'View Products from All Look Board Types',
    imgUrl: streamImg,
  },
  {
    id: 3,
    title: 'Mix & Match',
    description:
      'Mix and Match Products from all Look Boards to Create the Perfect one',
    imgUrl: mixAndMatchImg,
  },
];

export default {
  howItWorksModalConfig,
  selectImagesModalConfig,
  receiveImagesModalConfig,
};
