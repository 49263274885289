export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const UPDATE_MESSAGES_BY_CONVERSATION_ID =
  'UPDATE_MESSAGES_BY_CONVERSATION_ID';
export const SET_MESSAGES_BY_CONVERSATION_ID =
  'SET_MESSAGES_BY_CONVERSATION_ID';
export const SET_META_BY_CONVERSATION_ID = 'SET_META_BY_CONVERSATION_ID';
export const PUSH_MESSAGE_BY_ID = 'PUSH_MESSAGE_BY_ID';
export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS';
export const SET_CONTACT_LIST = 'SET_CONTACT_LIST';
export const UPDATE_CONTACT_LIST = 'UPDATE_CONTACT_LIST';
export const SET_UNREAD_CONVERSATIONS = 'SET_UNREAD_CONVERSATIONS';
export const UPDATE_UNREAD_CONVERSATIONS = 'UPDATE_UNREAD_CONVERSATIONS';
export const REMOVE_UNREAD_CONVERSATION = 'REMOVE_UNREAD_CONVERSATION';
export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
export const SET_CONVERSATION_ON_TOP = 'SET_CONVERSATION_ON_TOP';
