export const GTL_SET_USERS_LOADING = 'GTL_SET_USERS_LOADING';
export const GTL_SET_USERS_SEARCH_PARAMS = 'GTL_SET_USERS_SEARCH_PARAMS';
export const GTL_SET_USERS = 'GTL_SET_USERS';
export const GTL_SET_MORE_USERS = 'GTL_SET_MORE_USERS';
export const GTL_TOGGLE_FOLLOWED_USER = 'GTL_TOGGLE_FOLLOWED_USER';
export const GTL_UPDATE_HAS_MORE_STATUS_USERS =
  'GTL_UPDATE_HAS_MORE_STATUS_USERS';
export const GTL_SET_USERS_LOOKBOARDS = 'GTL_SET_USERS_LOOKBOARDS';
export const GTL_SET_SELECTED_USER = 'GTL_SET_SELECTED_USER';
export const GTL_TOGGLE_USER_LOOKBOARD_LIKE = 'GTL_TOGGLE_USER_LOOKBOARD_LIKE';
export const GTL_VOTE_USER_LOOKBOARD = 'GTL_VOTE_USER_LOOKBOARD';
export const AFFILIATE_SITES = 'AFFILIATE_SITES';
