import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import appReducer from 'modules/app/store/reducer';
import authReducer from 'modules/auth/store/reducer';
import layoutReducer from 'modules/layout/store/reducer';
import curateTheLookReducer from 'modules/curateTheLook/store/reducer';
import requestTheLookReducer from 'modules/requestTheLook/store/reducer';
import productsReducer from 'modules/product/store/reducer';
import inspirationImageReducer from 'modules/inspirationImage/store/reducer';
import getTheLookReducer from 'modules/getTheLook/store/reducer';
import showcaseReducer from 'modules/showcase/store/reducer';
import reportReducer from 'modules/report/store/reducer';
import usersReducer from 'modules/users/store/reducer';
import conversationReducer from 'modules/conversation/store/reducer';
import ambassadorsPageReducer from 'modules/getTheLook/ambassadorPage/store/reducer';
import dashboardReducer from 'modules/dashboard/store/reducer';
import userPageReducer from "modules/getTheLook/userPage/store/reducer";

export default function createRootReducer(history) {
  return combineReducers({
    app: appReducer,
    auth: authReducer,
    layout: layoutReducer,
    getTheLook: getTheLookReducer,
    ambassadorsPage: ambassadorsPageReducer,
    userPage: userPageReducer,
    curateTheLook: curateTheLookReducer,
    requestTheLook: requestTheLookReducer,
    inspirationImage: inspirationImageReducer,
    users: usersReducer,
    conversation: conversationReducer,
    product: productsReducer,
    showcase: showcaseReducer,
    report: reportReducer,
    dashboard: dashboardReducer,
    router: connectRouter(history),
    toastr: toastrReducer,
  });
}
