const appConfig = {
  isDev: process.env.REACT_APP_ENV === 'development',
  nodeEnv: process.env.REACT_APP_ENV,
  appVersion: process.env.REACT_APP_appVersion,
  appDomain: process.env.REACT_APP_appDomain,
  apiUrl: process.env.REACT_APP_apiUrl,
  configUrl: process.env.REACT_APP_appConfigUrl,
  reCaptchaSiteKeyV2: process.env.REACT_APP_reCaptchaSiteKeyV2,
  reCaptchaSiteKeyV3: process.env.REACT_APP_reCaptchaSiteKeyV3,
  sentryDSN: process.env.REACT_APP_sentryDSN,
  broadcastUrl: process.env.REACT_APP_BROADCAST_URL,
  broadcastMessengerChannelName:
    process.env.REACT_APP_BROADCAST_CHANNEL_NAME_CONVERSATIONS,
  broadcastNotificationsChannelName:
    process.env.REACT_APP_BROADCAST_CHANNEL_NAME_NOTIFICATIONS,
  productCountMax: 100,
};

export default appConfig;
