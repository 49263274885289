import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';
import { localStorageKeys } from 'modules/app/constants';

const urlPrefix = '/v1';

const showcaseService = {
  getShowcase(config = null) {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/showcase`, config).then(
      ({ result }) => result
    );
  },

  getShowcaseByUrlKey(urlKey) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/showcase/url`, {
      urlKey,
    }).then(({ result }) => result);
  },

  searchShowcase(config) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/showcase/search`,
      config
    ).then(({ result }) => result);
  },

  searchShowcaseFollowers(config) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/showcase/user-followed`,
      config
    ).then(({ result }) => result);
  },

  createShowcase(pageName, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/showcase`,
      {
        pageName,
      },
      config
    ).then(({ result }) => result);
  },

  updateShowcase(data) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/showcase`, data).then(
      ({ result }) => result
    );
  },

  getLookBoards(showcaseId, lookBoards) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/showcase/${showcaseId}/look-boards`,
      {
        lookBoards: lookBoards.filter((im) => im),
      }
    ).then(({ result }) => result);
  },

  updateFeaturedLookBoards(lookBoards) {
    return AuthorizedAxiosInstance.put(
      `${urlPrefix}/showcase/feature-look-boards`,
      { lookBoards: lookBoards.filter((im) => im) }
    );
  },

  updateBanner(data) {
    return AuthorizedAxiosInstance.put(
      `${urlPrefix}/showcase/banner`,
      data
    ).then(({ result }) => result);
  },

  updateDescription(description) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/showcase/description`, {
      description,
    }).then(({ result }) => result);
  },

  updateStyles(styles) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/showcase/styles`, {
      styles,
    }).then(({ result }) => result);
  },

  updateImages(images) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/showcase/images`, {
      images: images.filter((im) => im),
    }).then(({ result }) => result);
  },

  getImages(id, images) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/showcase/${id}/images`, {
      images: images.filter((im) => im),
    }).then(({ result }) => result);
  },

  updateLookBoards(lookBoards) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/showcase/look-boards`, {
      lookBoards: lookBoards.filter((im) => im),
    }).then(({ result }) => result);
  },

  toggleActivation(value) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/showcase/activate`, {
      value,
    }).then(({ result }) => result);
  },

  updateVideoLink(value) {
    return AuthorizedAxiosInstance.put(
      `${urlPrefix}/showcase/upload-video-url`,
      {
        videoUrl: value,
      }
    ).then(({ result }) => result);
  },

  getOwnColorBannersFromStorage() {
    return JSON.parse(localStorage.getItem(localStorageKeys.ownColorBanners));
  },

  getOwnImageBannersFromStorage() {
    return JSON.parse(localStorage.getItem(localStorageKeys.ownImageBanners));
  },

  saveBannerToStorage(type, banner) {
    const banners = JSON.parse(localStorage.getItem(type));
    if (!banners) {
      const newValue = JSON.stringify([banner]);
      localStorage.setItem(type, newValue);
    } else {
      const updatedValue = JSON.stringify([banner, ...banners]);
      localStorage.setItem(type, updatedValue);
    }
  },
};

export default showcaseService;
