import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'components/modals/TermsModal/TermsModal.module.scss';

const UnderCut = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <div>
      <div
        onClick={handleToggle}
        className="d-flex align-items-center secondary-color mb-1 cursor-pointer"
        style={{ maxWidth: '190px' }}
      >
        <p className="mr-1 text-underline">Read the legal jargon</p>
        <SpriteIcon
          name="arrow-down"
          size="xs"
          className={clsx(classes.underCutArrow, {
            [classes.expanded]: expanded,
          })}
        />
      </div>
      <div className={clsx('text-xs', { 'd-none': !expanded })}>{children}</div>
    </div>
  );
};

UnderCut.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnderCut;
