import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Badge from '@material-ui/core/Badge'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { routesByName } from 'constants/routes'
import SpriteIcon from 'components/ui/SpriteIcon'
import Button from 'components/ui/Button/Button'
import HeaderMenu from 'modules/layout/Header/HeaderMenu/HeaderMenu'
import AuthModal from 'modules/layout/Header/AuthModal/AuthModal'
import SectionsNavigation from 'modules/layout/Header/SectionsNavigation'
import NotificationPopover from 'modules/layout/Header/Popovers/NotificationPopover'
import DialogsPopover from 'modules/layout/Header/Popovers/DialogsPopover'
import useCurrentUserAvatarUrl from 'hooks/useCurrentUserAvatarUrl'
import useMediaQuery from 'hooks/useMediaQuery'
import { maxWidthLg, maxWidthMd } from 'constants/mediaQueries'
import IconButton from 'components/ui/IconButton/IconButton'
import BasicAvatar from 'components/ui/BasicAvatar'
import { logoutAction } from 'modules/auth/store/actions'
import ConfirmModal from 'components/modals/ConfirmModal'
import errorToastr from 'libs/toastr/errorToastr'
import ForgotPasswordContainer from 'modules/auth/forgotPassword/ForgotPasswordContainer'
import { notificationsService } from 'modules/layout/Header/notificationsService'
import useConnectToEchoServer from 'libs/echo/useConnectToEchoServer'
import channelsByName from 'libs/echo/channels'
import { NOTIFICATION_RECEIVED } from 'libs/echo/events'
import clsx from 'clsx'
import useQuery from 'hooks/useQuery'
import classes from './Header.module.scss'
import HeaderTooltip from './HeaderTooltip'
import DashboardMenu from './DashboardMenu/DashboardMenu'
import ProfileMenu from './ProfileMenu/ProfileMenu'
import { changeActiveTabAction, resetFilterValuesAction } from '../../getTheLook/store/actions'
import { toggleHowItWorksVideoModalAction } from '../store/actions'
import AmbassadorMenu from './AmbassadorMenu/AmbassadorMenu'

const Header = () => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const styleQuizResult = useSelector((state) => state.layout.styleQuizResult)
  const unreadConversations = useSelector((state) => state.conversation.unreadConversations)

  const unreadConversationsCount = useMemo(() => unreadConversations.length, [unreadConversations])
  const authenticated = useMemo(() => Boolean(user), [user])

  const matchesMediaQuery = useMediaQuery(maxWidthMd)
  const matchesMediaLg = useMediaQuery(maxWidthLg)

  const { pathname } = useLocation()
  const queryParams = useQuery()
  const history = useHistory()

  const [authModal, setAuthModal] = useState(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [dashboardMenuAnchorEl, setDashboardMenuAnchorEl] = useState(null)
  const [ambassadorMenuAnchorEl, setAmbassadorMenuAnchorEl] = useState(null)
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null)
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)
  const [dialogsAnchorEl, setDialogsAnchorEl] = useState(null)
  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const [notifications, setNotifications] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)

  const [headerMenuActive, setHeaderMenuActive] = useState(false)
  const [dashboardMenuActive, setDashboardMenuActive] = useState(false)
  const [ambassadorMenuActive, setAmbassadorMenuActive] = useState(false)
  const [profileMenuActive, setProfileMenuActive] = useState(false)

  const limit = 5
  let offset = 5

  const loadMore = useCallback(async () => {
    setLoading(true)
    try {
      const { result: updatedList } = await notificationsService.getNotifications(offset)
      setNotifications((prevState) => ({
        unread: prevState.unread,
        data: [...prevState.data, ...updatedList.data],
      }))

      // eslint-disable-next-line react-hooks/exhaustive-deps
      offset += updatedList.data.length
      setHasMore(updatedList.data.length === limit)
      setLoading(false)
    } catch (e) {
      errorToastr('Error loading notifications', e.message)
    }
  }, [])

  const handleLogoutModalOpen = useCallback(() => {
    setLogoutModalOpen(true)
  }, [])

  const currentAvatarUrl = useCurrentUserAvatarUrl()

  const requestNotifications = useCallback(async () => {
    try {
      const { result: notificationsList } = await notificationsService.getNotifications(0)
      setNotifications(notificationsList)
    } catch (e) {
      errorToastr('Error loading notifications', e.message)
    }
  }, [])

  useEffect(() => {
    const authParam = queryParams.get(routesByName.auth.key)
    setAuthModal(authParam)
  }, [queryParams])

  useEffect(() => {
    if (authenticated) {
      requestNotifications()
    }
  }, [authenticated, requestNotifications])

  useEffect(() => {
    setUnreadNotificationsCount(notifications?.unread)
  }, [notifications])

  const setMessagesRead = useCallback(() => {
    notificationsService.setMessagesRead()
    setUnreadNotificationsCount(0)
  }, [])

  const goToHomePage = useCallback(() => {
    history.push(routesByName.home)
  }, [history])

  const handleAuthModalClose = useCallback(() => {
    history.push(pathname)
  }, [history, pathname])

  const handleOpenNotificationModal = useCallback((event) => {
    setNotificationAnchorEl(event.currentTarget)
  }, [])

  const handleCloseNotificationModal = useCallback(() => {
    setNotificationAnchorEl(null)
  }, [])

  const handleOpenDialogsModal = useCallback((event) => {
    setDialogsAnchorEl(event.currentTarget)
  }, [])

  const handleCloseDialogsModal = useCallback(() => {
    setDialogsAnchorEl(null)
  }, [])

  const handleOpenMenu = useCallback((event) => {
    setMenuAnchorEl(event.currentTarget)
    setHeaderMenuActive(true)
  }, [])

  const handleCloseMenu = useCallback((e) => {
    if (e.currentTarget.localName !== 'li') {
      const menu = document.getElementById('headerMenu')?.children[2]
      if (menu) {
        const menuBoundary = {
          left: menu.offsetLeft,
          top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
          right: menu.offsetLeft + menu.offsetHeight,
          bottom: menu.offsetTop + menu.offsetHeight,
        }
        if (
          e.clientX >= menuBoundary.left &&
          e.clientX <= menuBoundary.right &&
          e.clientY <= menuBoundary.bottom &&
          e.clientY >= menuBoundary.top
        ) {
          return
        }
      }
    }
    setHeaderMenuActive(false)
  }, [])

  const handleOpenDashboardMenu = useCallback((event) => {
    if (matchesMediaQuery) {
      event.preventDefault()
    }
    setDashboardMenuAnchorEl(event.currentTarget)
    setDashboardMenuActive(true)
  }, [])

  const handleCloseDashboardMenu = useCallback(() => {
    setDashboardMenuActive(false)
  }, [])

  const handleShowDashboardMenu = useCallback(() => {
    setDashboardMenuActive(true)
  }, [])

  const handleOpenAmbassadorMenu = useCallback((event) => {
    setAmbassadorMenuAnchorEl(event.currentTarget)
    setAmbassadorMenuActive(true)
  }, [])

  const handleCloseAmbassadorMenu = useCallback(() => {
    setAmbassadorMenuActive(false)
  }, [])

  const handleShowAmbassadorMenu = useCallback(() => {
    setAmbassadorMenuActive(true)
  }, [])

  const handleOpenProfileMenu = useCallback((event) => {
    setProfileMenuAnchorEl(event.currentTarget)
    setProfileMenuActive(true)
  }, [])

  const handleLogout = useCallback(
    async (confirm) => {
      if (confirm) {
        try {
          await dispatch(logoutAction())
          setLogoutModalOpen(false)
        } catch (e) {
          errorToastr('Error', e.generalError)
        }
      } else {
        setLogoutModalOpen(false)
      }
    },
    [dispatch],
  )

  const handleGoToDialogs = useCallback(() => {
    history.push(routesByName.dashboard.messages)
  }, [history])

  const handleCloseProfileMenu = useCallback(() => setProfileMenuActive(false), [])
  const handleShowProfileMenu = useCallback(() => setProfileMenuActive(true), [])

  useConnectToEchoServer({
    channelName: channelsByName.notificationsById(user?.id),
    events: {
      [NOTIFICATION_RECEIVED]: requestNotifications,
    },
  })

  return (
    <>
      <div className={classes.root}>
        <div
          className={clsx('d-flex justify-content-between align-items-center', classes.logoSection)}
        >
          <Logo
            width={matchesMediaQuery ? 90 : 150}
            onClick={goToHomePage}
            className={classes.logo}
          />
          <div className="d-flex align-items-center">
            {!matchesMediaQuery && (
              <Link
                to={routesByName.getTheLook.ambassadors.index}
                className={clsx('mr-4', classes.laptopMenu)}
              >
                <Button variant="outlined" size="sm">
                  Showcases
                </Button>
              </Link>
            )}

            <Link to={routesByName.styleQuiz} className={clsx('mr-4', classes.laptopMenu)}>
              <Button size="sm" variant={matchesMediaQuery ? 'outlined' : 'contained'}>
                Style Quiz
              </Button>
            </Link>
            {!matchesMediaQuery && (
              <Link to={routesByName.getThePin} className={clsx('mr-4', classes.laptopMenu)}>
                <Button
                  size="sm"
                  variant="outlined"
                  className={clsx('d-flex align-items-center px-3', classes.pinBtn)}
                >
                  <div className={clsx('mr-1', classes.pinBtnIcon)}>
                    <SpriteIcon name="pin" size="sm" />
                  </div>
                  <span className={classes.pinBtnText}>Get the Pin</span>
                </Button>
              </Link>
            )}

            {authenticated && !matchesMediaQuery && (
              <Link
                to={routesByName.dashboard.index}
                className={`mr-${matchesMediaQuery ? 1 : 4} position-relative ${
                  classes.laptopMenu
                }`}
                onClick={handleOpenDashboardMenu}
                style={{ zIndex: dashboardMenuActive ? '1301' : '1' }}
                onMouseEnter={handleOpenDashboardMenu}
                onMouseLeave={handleCloseDashboardMenu}
              >
                <div className={classes.profileAfter} />
                {matchesMediaQuery ? (
                  <IconButton
                    variant="inverted-white"
                    style={{ color: '#E0E0E0', background: 'transparent' }}
                  >
                    <SpriteIcon name="dashboard" size="sm" />
                  </IconButton>
                ) : (
                  <Button size="sm">
                    <SpriteIcon name="dashboard" size="sm" className="mr-1" />
                    Dashboard
                  </Button>
                )}
              </Link>
            )}
            {!matchesMediaQuery && (
              <Link
                to={routesByName.ambassador.index}
                className={`mr-${matchesMediaQuery ? 1 : 4} position-relative ${
                  classes.laptopMenu
                }`}
                style={{ zIndex: ambassadorMenuActive ? '1301' : '1' }}
                onMouseEnter={handleOpenAmbassadorMenu}
                onMouseLeave={handleCloseAmbassadorMenu}
              >
                <div className={classes.profileAfter} />
                <Button size="sm">Ambassadors</Button>
              </Link>
            )}
            {authenticated && (
              <>
                <div
                  onMouseEnter={handleOpenProfileMenu}
                  className="ml-1 cursor-pointer position-relative"
                  style={{ zIndex: profileMenuActive ? 1301 : 1 }}
                  onMouseLeave={handleCloseProfileMenu}
                >
                  <div className={classes.profileAfter} />
                  <div
                    className={clsx('align-items-center w-100', {
                      'd-none': matchesMediaQuery,
                      'd-flex': !matchesMediaQuery,
                    })}
                    style={{ width: matchesMediaQuery ? 'auto' : 180 }}
                  >
                    <BasicAvatar
                      firstName={user.firstName}
                      displayName={user.displayName}
                      lastName={user.lastName}
                      src={currentAvatarUrl}
                    />
                    {!matchesMediaQuery && (
                      <p className={`${classes.fullName} ml-1 text-ellipsis`}>
                        {user.displayName ?? `${user.firstName ?? ''} ${user.lastName ?? ''}`}
                      </p>
                    )}
                  </div>
                </div>
                {!matchesMediaQuery && (
                  <div
                    className={clsx(
                      'secondary-color d-flex align-items-center',
                      matchesMediaLg ? 'mx-1' : 'mx-4',
                    )}
                  >
                    <Badge
                      className="cursor-pointer"
                      color="error"
                      badgeContent={unreadNotificationsCount || null}
                      onClick={handleOpenNotificationModal}
                    >
                      <HeaderTooltip text="Notifications">
                        <SpriteIcon name="notification" size="md" />
                      </HeaderTooltip>
                    </Badge>
                    <Badge
                      className={clsx('cursor-pointer', matchesMediaLg ? 'ml-1' : 'ml-4')}
                      color="error"
                      badgeContent={unreadConversationsCount || null}
                      showZero={false}
                      onClick={
                        unreadConversationsCount > 0 ? handleOpenDialogsModal : handleGoToDialogs
                      }
                    >
                      <HeaderTooltip text="Messages">
                        <SpriteIcon name="envelope" size="md" />
                      </HeaderTooltip>
                    </Badge>
                  </div>
                )}
              </>
            )}
            {!authenticated && !matchesMediaQuery && (
              <div className="d-flex align-items-center mr-4">
                <Link
                  to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`}
                  className={classes.authLink}
                >
                  Sign In
                </Link>
                <div className={classes.verticalDivider} />
                <Link
                  to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`}
                  className={classes.authLink}
                >
                  Register
                </Link>
              </div>
            )}

            <div
              onClick={handleOpenMenu}
              className="position-relative"
              style={{ zIndex: headerMenuActive ? '1301' : '1' }}
              onMouseEnter={handleOpenMenu}
              onMouseLeave={handleCloseMenu}
            >
              <IconButton
                className={classes.mobileMenuButton}
                size="sm"
                variant="inverted-grey"
                color="grey"
              >
                <MoreHorizIcon />
              </IconButton>
              <div className={clsx(classes.profileAfter, 'cursor-pointer')} />
            </div>
          </div>
        </div>
        <SectionsNavigation
          changeActiveTab={(e) => dispatch(changeActiveTabAction(e))}
          resetFilterValues={() => dispatch(resetFilterValuesAction())}
          styleQuizResult={styleQuizResult}
          user={user}
          toggleModal={(e) => dispatch(toggleHowItWorksVideoModalAction(e))}
        />
      </div>
      <AuthModal
        action={authModal}
        onClose={handleAuthModalClose}
        toggleHowItWorksModal={(e) => dispatch(toggleHowItWorksVideoModalAction(e))}
      />
      <ForgotPasswordContainer />
      <NotificationPopover
        notificationsList={notifications}
        unreadNotificationsCount={unreadNotificationsCount}
        anchorEl={notificationAnchorEl}
        open={Boolean(notificationAnchorEl)}
        onClose={handleCloseNotificationModal}
        setMessagesRead={setMessagesRead}
        hasMore={hasMore}
        loadMore={loadMore}
        loading={loading}
      />
      <DialogsPopover
        anchorEl={dialogsAnchorEl}
        open={Boolean(dialogsAnchorEl)}
        onClose={handleCloseDialogsModal}
      />
      <HeaderMenu anchorEl={menuAnchorEl} open={headerMenuActive} onClose={handleCloseMenu} />
      <DashboardMenu
        anchorEl={dashboardMenuAnchorEl}
        open={dashboardMenuActive}
        onClose={handleCloseDashboardMenu}
        onMouseEnter={handleShowDashboardMenu}
      />
      <AmbassadorMenu
        anchorEl={ambassadorMenuAnchorEl}
        open={ambassadorMenuActive}
        onClose={handleCloseAmbassadorMenu}
        onMouseEnter={handleShowAmbassadorMenu}
      />
      <ProfileMenu
        anchorEl={profileMenuAnchorEl}
        open={profileMenuActive}
        onClose={handleCloseProfileMenu}
        onMouseEnter={handleShowProfileMenu}
      />
      <ConfirmModal
        open={logoutModalOpen}
        onClose={handleLogout}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">logout</span> from account?
          </>
        }
      />
    </>
  )
}

export default Header
