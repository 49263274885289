export const signInFormFields = {
  email: 'email',
  password: 'password',
};

export const errorMessages = {
  required: 'This field is required',
  passwordInvalid: 'Password should be at least 8 characters',
  emailInvalid: 'Email must be a valid email address',
};

export default {
  signInFormFields,
  errorMessages,
};
