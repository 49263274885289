import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'

const urlPrefix = '/v1'

const ambassadorService = {
  requestAccess(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/invitation/request-ambassador-access`, data)
  },
  nominateAmbassador(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/invitation/nominate-ambassador`, data)
  },
}

export default ambassadorService
