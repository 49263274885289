import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import { togglePreferencesRemindModalAction } from 'modules/layout/store/actions';
import { updateCurrentUserDataAction } from 'modules/auth/store/actions';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import roomsImg from 'assets/img/about-us-page/rooms.png';
import authModalImg from 'assets/img/contact-page/banner.png';
import { routesByName } from 'constants/routes';
import { ADD_PREFERENCES } from 'constants/customEventNames';
import classes from './PreferencesRemindModal.module.scss';
import customEvent from '../../../utils/customEvent';

const PreferencesRemindModal = ({ open, toggleModal, colors }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const history = useHistory();

  const handleClose = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  const colorOption = [1, 2, 4, 15, 16, 19];

  const handleGoToPreferences = useCallback(() => {
    handleClose();
    history.push(routesByName.account.profile);
    setTimeout(() => {
      customEvent.trigger(ADD_PREFERENCES);
    }, 0);
  }, [handleClose, history]);

  const handleOpenStyleQuizPage = useCallback(() => {
    handleClose();
    history.push(routesByName.styleQuiz);
  }, [handleClose, history]);

  return (
    <BasicModal
      open={Boolean(open)}
      onClose={handleClose}
      classes={{
        root: 'p-0',
        closeBtn: classes.closeBtn,
      }}
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <div className="d-flex">
        <div className={classes.formContainer}>
          <p className={clsx(classes.title, 'mb-2')}>SET YOUR PREFERENCES</p>
          <p className={clsx(classes.subTitle, 'mb-3', 'primary-color')}>
            To View Looks You Love When You Log In
          </p>
          <div className={classes.listPreferences}>
            <h5 className={classes.preferencesTitle}>STYLES</h5>
            <img className="w-100" src={roomsImg} alt="Rooms" />
            <h5 className={classes.preferencesTitle}>ROOM TYPES</h5>
            <div className="d-flex justify-content-around">
              <SpriteIcon name="dining-room" size="xxl" />
              <SpriteIcon name="living-room" size="xxl" />
              <SpriteIcon name="bedroom" size="xxl" />
            </div>
            <h5 className={classes.preferencesTitle}>COLORS</h5>
            <div className="d-flex justify-content-between mt-3">
              {colorOption.map((id) => (
                <ColorPoint
                  key={id}
                  id={id}
                  size={matchesMediaQuery ? 'md' : 'xl'}
                  title={colors[id].name}
                  selected
                  className="mr-4 mb-3"
                  color={colors[id].code}
                />
              ))}
            </div>
          </div>
          <div className={classes.preferencesButton}>
            <Button size="sm" onClick={handleGoToPreferences}>
              SET PREFERENCES
            </Button>
          </div>
        </div>
        {!matchesMediaQuery && (
          <div className="w-50">
            <div className={classes.imgContainer}>
              <img src={authModalImg} alt="" />
              <div className={classes.banner}>
                <div className="position-relative text-center mb-1">
                  <span className={classes.bannerTitle}>Pin The Look</span>
                  <div className={classes.crossline} />
                </div>
                <p className={classes.bannerSubtitle}>
                  <span className="primary-color">DECORATING THE WORLD</span> TOGETHER
                </p>
              </div>
            </div>
            <div className={classes.quizBtnWrapper}>
              <p>Take the Style Quiz to Discover Your Style!</p>
              <button type="button" className={classes.quizBtn} onClick={handleOpenStyleQuizPage}>
                <div>
                  <div className={classes.subTitle}>
                    <div className={classes.line} />
                    <span className="px-1">Take the</span>
                    <div className={classes.line} />
                  </div>
                  <p className="text-sm">Style Quiz</p>
                </div>
                <SpriteIcon name="play-icon" size="lg" />
              </button>
            </div>
          </div>
        )}
      </div>
    </BasicModal>
  );
};

PreferencesRemindModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  colors: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({
  app: {
    enums: { colors },
  },
  layout: { preferencesRemindModalOpen },
}) => ({
  open: preferencesRemindModalOpen,
  colors,
});

const mapDispatchToProps = {
  toggleModal: togglePreferencesRemindModalAction,
  updateCurrentUserData: updateCurrentUserDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesRemindModal);
