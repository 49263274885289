import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import useQuery from 'hooks/useQuery';

const ResetPasswordToken = ({ onOpenResetModal }) => {
  const { token } = useParams();
  const history = useHistory();
  const queryParams = useQuery();

  useEffect(() => {
    const email = queryParams.get('email');
    onOpenResetModal(token, email);
    history.replace(routesByName.home);
    // eslint-disable-next-line
  }, []);

  return null;
};

ResetPasswordToken.propTypes = {
  onOpenResetModal: PropTypes.func.isRequired,
};

export default ResetPasswordToken;
