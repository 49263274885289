import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import BasicModal from '../../../../../components/modals/BasicModal/BasicModal';
import StylesFilter from '../../../../../components/modals/FiltersModal/components/StylesFilter';
import Button from '../../../../../components/ui/Button/Button';
import errorToastr from '../../../../../libs/toastr/errorToastr';
import currentUserService from '../../../../currentUser/currentUserService';
import { updateUserPreferencesAction } from '../../../../currentUser/actions';
import classes from '../../SignUp.module.scss';

const SetStylesModal = ({ open, onApplyStyles }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { styles: stylesEnum } = useSelector((state) => state.app.enums);

  const styleOptions = useMemo(() => Object.values(stylesEnum), [stylesEnum]);

  const handleChange = useCallback(
    (optionValue, checked) => {
      let valueCopy = [...inputValue];

      if (!checked) {
        valueCopy = valueCopy.filter((item) => item !== optionValue);
      } else if (valueCopy.length < 10) {
        valueCopy.push(optionValue);
      }
      setInputValue(valueCopy);
    },
    [inputValue]
  );

  const handleSaveStyles = useCallback(async () => {
    if (!inputValue.length) {
      errorToastr('Error', 'This field cant be empty');
      return;
    }
    setLoading(true);
    try {
      const {
        result: { preferences },
      } = await currentUserService.updateStyles(inputValue);
      dispatch(updateUserPreferencesAction(preferences));
      onApplyStyles();
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
    setLoading(false);
  }, [dispatch, inputValue, onApplyStyles]);

  return (
    <BasicModal
      open={open}
      closeBtn={false}
      maxWidth="md"
      fullWidth
      onClose={() => {}}
      classes={{
        root: classes.stylesModal,
        closeBtn: '',
        container: classes.stylesModalContainer,
      }}
    >
      <Logo className={classes.logo} />
      <h1 className={classes.stylesTitle}>
        What Interior Styles do you Prefer?
      </h1>
      <p className={classes.stylesSubTitle}>select all that apply</p>
      <StylesFilter
        options={styleOptions}
        currentValue={inputValue}
        onChange={handleChange}
      />
      <div className="d-flex justify-content-center pt-3">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveStyles}
          disabled={isLoading}
        >
          Apply
        </Button>
      </div>
    </BasicModal>
  );
};

SetStylesModal.propTypes = {
  open: PropTypes.bool,
  onApplyStyles: PropTypes.func,
};
SetStylesModal.defaultProps = {
  open: false,
  onApplyStyles: () => {},
};
export default SetStylesModal;
