export const professionsMap = {
  decorLover: {
    title: 'Decor Enthusiasts',
    value: 'decorLover',
  },
  designProfessional: {
    title: 'Design Professional',
    value: 'designProfessional',
  },
  onlineShopper: {
    title: 'Influencer | Blogger',
    value: 'onlineShopper',
  },
};
