import React, { useCallback, useState } from 'react';
import { Route } from 'react-router-dom';
import PasswordRecoveryModal from 'modules/auth/forgotPassword/PasswordRecoveryModal/PasswordRecoveryModal';
import useCustomEventListener from 'hooks/useCustomEventListener';
import { OPEN_FORGOT_PASSWORD_MODAL } from 'constants/customEventNames';
import { routesByName } from 'constants/routes';
import ResetPasswordToken from 'modules/auth/forgotPassword/resetPassword/ResetPasswordToken';
import ResetPasswordModal from 'modules/auth/forgotPassword/resetPassword/ResetPasswordModal';

const ForgotPasswordContainer = () => {
  const [token, setToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [passwordRecoveryModalOpen, setPasswordRecoveryModalOpen] = useState(
    false
  );
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  useCustomEventListener(OPEN_FORGOT_PASSWORD_MODAL, () => {
    setPasswordRecoveryModalOpen(true);
  });

  const handleClosePasswordRecoveryModal = useCallback(() => {
    setPasswordRecoveryModalOpen(false);
  }, []);

  const handleOpenResetPasswordModal = useCallback((resetToken, email) => {
    setToken(resetToken);
    setUserEmail(email);
    setResetPasswordModalOpen(true);
  }, []);

  const handleCloseResetPasswordModal = useCallback(() => {
    setResetPasswordModalOpen(false);
  }, []);

  return (
    <>
      <Route exact path={routesByName.auth.resetPassword}>
        <ResetPasswordToken onOpenResetModal={handleOpenResetPasswordModal} />
      </Route>
      <PasswordRecoveryModal
        open={passwordRecoveryModalOpen}
        onClose={handleClosePasswordRecoveryModal}
      />
      <ResetPasswordModal
        open={resetPasswordModalOpen}
        onClose={handleCloseResetPasswordModal}
        token={token}
        email={userEmail}
      />
    </>
  );
};

export default ForgotPasswordContainer;
