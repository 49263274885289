import palette from 'materialUi/palette';

const MuiFormLabel = {
  root: {
    color: `${palette.grey[4]} !important`,
    fontSize: 14,
  },
};

export default MuiFormLabel;
