import { resetPasswordFormFields } from 'modules/auth/forgotPassword/resetPassword/constants';
import { errorMessages } from 'modules/auth/signUp/constants';

const resetPasswordValidator = ({
  [resetPasswordFormFields.password]: password,
  [resetPasswordFormFields.confirmPassword]: confirmPassword,
}) => {
  const errors = {};
  function hasNumber(usersPassword) {
    return /\d/.test(usersPassword);
  }

  if (!password) {
    errors.password = errorMessages.required;
  } else if (password.length < 8) {
    errors.password = errorMessages.passwordInvalid;
  } else if (!hasNumber(password)) {
    errors.password = errorMessages.passwordWithoutNumber;
  }

  if (password && !confirmPassword) {
    errors.confirmPassword = errorMessages.required;
  } else if (password !== confirmPassword) {
    errors.confirmPassword = errorMessages.passwordsShouldMatch;
  }

  return errors;
};

export default resetPasswordValidator;
