import {
  II_UPDATE_LIBRARY,
  II_UPDATE_IMAGE_LIKE_STATUS,
  II_UPDATE_CREATION_IMAGE_STATUS,
  II_SELECT_OWN_IMAGE,
} from 'modules/inspirationImage/store/constants';
import { toggleAddImageModalAction } from 'modules/layout/store/actions';
import featureNameKeys from 'constants/featureNames';
import { selectImageAction as selectImageCTL } from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectImageRTL } from 'modules/requestTheLook/store/actions';

export const updateIILibraryAction = (list) => (dispatch) => {
  dispatch({ type: II_UPDATE_LIBRARY, payload: list });
};

export const updateIIStatusLikeAction = (id, isLiked) => (dispatch) => {
  dispatch({ type: II_UPDATE_IMAGE_LIKE_STATUS, payload: { id, isLiked } });
};

export const updateCreationImageStatusAction = (feature) => (dispatch) => {
  dispatch({ type: II_UPDATE_CREATION_IMAGE_STATUS, payload: feature });
};

export const createImageInstantlyForAction = (feature) => (dispatch) => {
  dispatch(toggleAddImageModalAction(true));
  dispatch(updateCreationImageStatusAction(feature));
};

export const selectImageForFeatureAction = (image) => (dispatch, getState) => {
  const {
    inspirationImage: { createInstantlyFor: feature },
  } = getState();

  dispatch(updateIILibraryAction({ [image.id]: image }));
  if (feature === featureNameKeys.request) {
    dispatch(selectImageRTL(image.id));
  } else if (feature === featureNameKeys.curate) {
    dispatch(selectImageCTL(image.id));
  }
  dispatch(updateCreationImageStatusAction(null));
};

export const changeIsOwnImageAction = (isOwn) => (dispatch) => {
  dispatch({ type: II_SELECT_OWN_IMAGE, payload: isOwn });
};
