import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Sentry from 'libs/sentry/sentry';
import { connect } from 'react-redux';
import { userShape } from 'modules/currentUser/propTypes';

const initialState = {
  error: null,
  eventId: null,
};

class ErrorBoundary extends PureComponent {
  state = { ...initialState };

  static getDerivedStateFromError(error) {
    return { error };
  }

  // eslint-disable-next-line
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(() => {
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  showSentryDialog = () => {
    const { eventId } = this.state;
    const { user } = this.props;

    const sentryUser = {};

    if (user) {
      const { email, firstName, lastName } = user;
      sentryUser.name = `${firstName} ${lastName}`;
      sentryUser.email = email;
    }

    Sentry.showReportDialog({ eventId, user: sentryUser });
  };

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <>
          <h1>Something went wrong :(</h1>
          <button type="button" onClick={this.showSentryDialog}>
            Report feedback
          </button>
        </>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  user: userShape,
  children: PropTypes.node.isRequired,
};

ErrorBoundary.defaultProps = {
  user: null,
};

const mapSateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapSateToProps)(ErrorBoundary);
