import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import {
  selectImagesModalConfig,
  receiveImagesModalConfig,
} from 'components/modals/HowItWorksModal/constants';
import { toggleHowItWorksModalAction } from 'modules/layout/store/actions';
import clsx from 'clsx';
import requestDetailsImg from 'assets/img/about-us-page/requestTheLookDetails.jpg';
import classes from './HowItWorksModal.module.scss';

const HowItWorksModal = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.layout.howItWorksModalOpen);

  const handleCloseModal = useCallback(() => {
    dispatch(toggleHowItWorksModalAction(false));
  }, [dispatch]);

  return (
    <BasicModal
      open={open}
      onClose={handleCloseModal}
      fullWidth
      classes={{ root: classes.root }}
      scroll="body"
    >
      <div className={classes.grayBlock}>
        <div className={classes.sectionBlock}>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <h2 className={`${classes.title} ${classes.titleSm} px-3 mb-2`}>
              Select an Image you Love
            </h2>
            <p className={classes.description}>
              Pin an Image or Select one from our Inspiration Image Database
            </p>
          </div>
          <div className="d-flex justify-content-between">
            {selectImagesModalConfig.map(({ id, imgUrl }) => (
              <img
                className={`${classes.selectImagesItem} px-1`}
                key={id}
                src={imgUrl}
                alt=""
              />
            ))}
          </div>
        </div>
        <div className={classes.sectionBlock}>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <h2 className={`${classes.title} ${classes.titleSm} px-3 mb-2`}>
              Request the Look
            </h2>
            <p className={classes.description}>
              Request the Look of an Image you Love and it will be Matched with
              Decor Lovers Specializing in your Style.
            </p>
            <div>
              <img src={requestDetailsImg} alt="" />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center text-center">
          <h2 className={`${classes.title} ${classes.titleSm} px-3 mb-5`}>
            Get Recommendations
          </h2>
        </div>
        <div className="d-flex">
          {receiveImagesModalConfig.map(
            ({ id, title, imgUrl, description }) => (
              <div className={`${classes.receiveItem} flex-fill`} key={id}>
                <h3
                  className="font-semi-bold mb-2 text-center text-uppercase"
                  style={{ fontSize: 22 }}
                >
                  {title}
                </h3>
                <p
                  className={clsx(
                    classes.recieveImagesDesc,
                    'text-lg text-center font-italic'
                  )}
                >
                  {description}
                </p>
                <div className={`${classes.receiveImageContainer} d-flex mt-3`}>
                  <img
                    className={classes.receiveImage}
                    src={imgUrl}
                    alt={title}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </BasicModal>
  );
};
export default HowItWorksModal;
