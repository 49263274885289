export const getTheLookInfoConfig = {
  steps: [
    {
      id: 1,
      title: 'Get Inspired',
      icon: 'picture',
      description: {
        text: 'Discover beautiful inspiration images in the styles you love',
        bold: true,
      },
    },
    {
      id: 2,
      title: 'Curated Looks',
      icon: 'dashboard',
      description: {
        text: 'View curated Look Boards to see different ways to Get the Look',
        bold: true,
      },
    },
    {
      id: 3,
      title: 'Mix & Match Looks',
      icon: 'shuffle-icon',
      description: {
        text: 'Scroll through similar items to curate your perfect Look Board',
        bold: true,
      },
    },
    {
      id: 4,
      title: 'Showcase Looks',
      icon: 'followers',
      description: {
        text: 'View our Ambassador Showcases featuring truly unique looks',
        bold: true,
      },
    },
  ],
};

export const curateTheLookInfoConfig = {
  fontSize: 'sm',
  steps: [
    {
      id: 1,
      title: 'Select an Image',
      icon: 'picture',
      description: {
        text:
          'Select an Inspiration Image from our Database or Pin an Image Online',
        bold: true,
      },
    },
    {
      id: 2,
      title: 'Curate Look Board',
      icon: 'living-room',
      description: {
        text: 'Drag & Drop Products into the Look Board Template',
        bold: true,
      },
    },
    {
      id: 3,
      title: 'Customize Template',
      icon: 'edit',
      description: {
        text: 'Customize the Banner Color and the Look Board Title',
        bold: true,
      },
    },
    {
      id: 4,
      title: 'Input Design Details',
      icon: 'shopping-list',
      description: {
        text: 'Input information about your Look Board, then Review & Submit',
        bold: true,
      },
    },
  ],
};

export const requestTheLookInfoConfig = {
  steps: [
    {
      id: 1,
      title: 'Select or Add Image',
      icon: 'picture',
      description: {
        text:
          'Select an Inspiration Image from our Database or Pin an Image Online',
        bold: true,
      },
    },
    {
      id: 2,
      title: 'Enter Your Request Details',
      icon: 'shopping-list',
      description: {
        text:
          'Select Preferred Items Types\n' +
          '\n' +
          'and Add Details. Your Request will be Matched with Ambassadors',
        bold: true,
      },
      withoutDots: true,
    },
  ],
};
