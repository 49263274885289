import {
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from 'modules/auth/store/constants';

const initialState = {
  inited: false,
  user: null,
};

const authReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case AUTH_INIT: {
      return { ...state, inited: true };
    }
    case AUTH_LOGIN: {
      return {
        ...state,
        user: {
          ...payload,
          displayName: payload.displayName ?? payload.email,
        },
      };
    }
    case AUTH_LOGOUT: {
      return { ...state, user: initialState.user };
    }
    default:
      return state;
  }
};

export default authReducer;
